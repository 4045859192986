import AuthButton from '@components/auth/methods/AuthButton';
import Button from '@components/shared/button/Button';
import Input from '@components/shared/input/Input';
import { ReactComponent as PaperboxLogo } from '@shared/assets/svg/paperbox-logo.svg';
import { auth } from '@shared/store/setup/firebase-setup';
import s from '@shared/styles/component/auth/auth.module.scss';
import { checkActionCode, confirmPasswordReset } from 'firebase/auth';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  oobCode: string;
  tenantId: string;
}

type AuthError =
  | 'auth/expired-action-code'
  | 'auth/invalid-action-code'
  | 'auth/user-disabled'
  | 'auth/user-not-found'
  | 'auth/operation-not-allowed'
  | 'auth/weak-password';

interface ResetData {
  email?: string;
  fromEmail?: string;
  multiFactorInfo?: any;
  previousEmail: string;
}

export const errorTextMap = {
  'auth/expired-action-code': 'Your link has expired.',
  'auth/invalid-action-code': 'Your link has expired, please try again.',
  'auth/user-disabled': 'Invalid user',
  'auth/user-not-found': 'Invalid user',
  'auth/weak-password': 'Password too weak, please try again.',
  'auth/not-allowed': 'Domain not allowed, returning to Login',
};

const PasswordReset: React.FC<Props> = ({ oobCode, tenantId }) => {
  const navigate = useNavigate();

  const [password, setPassword] = useState('');
  const [email, setEmail] = useState(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorCode, setErrorCode] = useState<AuthError>(null);
  const [isLoading, setIsLoading] = useState(false);
  const getUser = useCallback(() => {
    auth.tenantId = tenantId;
    checkActionCode(auth, oobCode)
      .then((res) => {
        const data = res.data as ResetData;
        setEmail(data.email);
      })
      .catch((err) => {
        const code = err.code as AuthError;
        if (code !== 'auth/operation-not-allowed') setErrorCode(code);
      });
  }, [oobCode, tenantId]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  const handlePasswordReset = (e) => {
    setIsLoading(true);
    e.preventDefault();
    confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        setIsSuccess(true);
      })
      .catch((err) => {
        const code = err.code as AuthError;
        setErrorCode(code);
        setIsLoading(false);
      });
  };

  return (
    <div className={s.container}>
      {!isSuccess ? (
        <form onSubmit={handlePasswordReset} className={s.card}>
          <PaperboxLogo className={s.logo} />

          <h2 className={s.title}>Please enter your new password {email && `for ${email}`} below.</h2>

          <div style={{ marginTop: -30, marginBottom: 20 }}>
            <Input
              hasError={!!errorCode}
              errorText={errorTextMap[errorCode]}
              type="password"
              setValue={setPassword}
              value={password}
              id={password}
            />
          </div>
          <AuthButton type={'submit'} id="mfa__button" isLoading={isLoading} isActive={!!password}>
            Confirm
          </AuthButton>
        </form>
      ) : (
        <div className={s.card}>
          <PaperboxLogo className={s.logo} />

          <h2 className={s.title}>
            Your password has been reset successfully, you can now log in with your new password.
          </h2>

          <Button
            text="Continue"
            onClick={() => navigate('/login', { replace: true })}
            className={s.button}
            id="continue__button"
          />
        </div>
      )}
    </div>
  );
};

export default PasswordReset;
