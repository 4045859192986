import {DocTypeExtended} from '@shared/models/inbox';
import s from '@shared/styles/component/document/document.module.scss';
import {ReactComponent as CheckmarkIcon} from '@svg/checkmark-icon.svg';
import {ReactComponent as EditIcon} from '@svg/edit-icon.svg';
import {ReactComponent as PlusIcon} from '@svg/plus-icon.svg';
import {ReactComponent as TrashIcon} from '@svg/trash-icon.svg';
import {Ring} from '@uiball/loaders';
import clsx from 'clsx';
import React from 'react';

interface Props {
  isActive?: boolean;
  isCreating?: boolean;
  isApproved?: boolean;
  isPending?: boolean;
  isDeleting?: boolean;
  isLowConfidence?: boolean;
  name: string;
  docType: DocTypeExtended;
  handleDeletion: VoidFunction;
  handleClick: VoidFunction;
  handleEdit?: VoidFunction;
}

const DocumentHeaderBadge: React.FC<Props> = ({
  isActive,
  isCreating = false,
  isApproved,
  isPending,
  isDeleting,
  isLowConfidence,
  name,
  docType,
  handleDeletion,
  handleClick,
  handleEdit,
}) => {
  return (
    <div
      className={clsx(s.copy_selector, {
        [s.copy_selector__active]: isActive,
        [s.copy_selector__approved]: isApproved,
        [s.copy_selector__low_confidence]: isLowConfidence,
      })}
      data-tour={'type-select'}
      onClick={!isActive && !isPending ? handleClick : null}
    >
      {isCreating ? (
        <div data-testid={'header-badge-add'} className={s.copy_selector_plus}>
          {isPending ? (
            <Ring color={'white'} size={20} speed={1.5} lineWeight={10} />
          ) : (
            <PlusIcon data-testid={'header-badge-add-plus'} />
          )}
        </div>
      ) : (
        <>
          <div className={s.copy_selector_main}>
            {isApproved && <CheckmarkIcon className={s.copy_selector_check} />}
            <span className={s.copy_selector_name}>{name}</span>
          </div>

          <div
            className={clsx(s.copy_selector_actions, {
              [s.copy_selector_actions__active]: isActive,
            })}
          >
            <div data-testid={'header-badge-edit'} onClick={handleEdit} className={s.copy_selector_edit}>
              <span className={s.copy_selector_type}>{`${docType.docType.name}  ${
                docType.subType && docType.subType.id !== 'NOSUBTYPE' ? ` / ${docType.subType.name}` : ``
              }`}</span>
              {!isApproved && <EditIcon className={s.copy_selector_icon} />}
            </div>
            {!isApproved && (
              <div
                data-testid={'header-badge-delete'}
                onClick={!isDeleting ? handleDeletion : undefined}
                className={clsx(s.copy_selector_delete)}
              >
                {isDeleting ? <Ring color={'white'} size={20} speed={1.5} lineWeight={10} /> : <TrashIcon />}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DocumentHeaderBadge;
