import Checkbox from '@components/shared/checkbox/Checkbox.tsx';
import StyledSelect from '@components/shared/dropdown/StyledSelect.tsx';
import {IClientDocType} from '@shared/helpers/converters/doctype.ts';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import {ReactComponent as TrashIcon} from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  data: { docType: string; data: { preferred_hierarchy: string; create_activity: boolean } };
  onChange: (data: { preferred_hierarchy: string; create_activity: boolean }) => void;
  handleDelete: () => void;
  inboxDocTypes: IClientDocType[];
}

const AdminBrioDoctypeRow: React.FC<Props> = ({
  data: { docType, data },
  onChange,
  handleDelete,
  inboxDocTypes,
}) => {
  const { t } = useTranslation();
  const docTypeHierarchyOptions = [
    { label: t('admin:webhooks.brio.docTypeConfig.options.parties'), value: 'parties' },
    { label: t('admin:webhooks.brio.docTypeConfig.options.contracts'), value: 'contracts' },
    { label: t('admin:webhooks.brio.docTypeConfig.options.claims'), value: 'claims' },
    { label: t('admin:webhooks.brio.docTypeConfig.options.documents'), value: 'documents' },
  ];
  const currentDoctype = inboxDocTypes?.find((d) => d.id === docType);
  return (
    <div className={clsx(sr.wrapper, sr.wrapper__no_anim)}>
      <div data-testid={'sortable-field'} className={clsx(sr.container, sr.container__no_hover)}>
        <span className={sr.title}> {currentDoctype?.name}</span>
      </div>

      <div className={sr.actions}>
        <div style={{ minWidth: 200 }}>
          <StyledSelect
            onChange={(v: any) => {
              console.log({ ...data, preferred_hierarchy: v.value });
              onChange({ ...data, preferred_hierarchy: v.value });
            }}
            options={docTypeHierarchyOptions}
            value={docTypeHierarchyOptions.find((o) => o.value === data.preferred_hierarchy)}
            style={{ minHeight: 43, border: '1px solid #EEEEEE', marginTop: 3 }}
          />
        </div>
        <div
          className={clsx(sr.action, sr.action__long)}
          style={{ minWidth: 50, paddingInline: 10, color: 'red' }}
        >
          <Checkbox
            checked={data.create_activity}
            onClick={() => onChange({ ...data, create_activity: !data.create_activity })}
          />
        </div>
        <button
          data-testid={'admin-item-row-delete'}
          className={clsx(sr.action, sr.trash)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete();
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};

export default AdminBrioDoctypeRow;
