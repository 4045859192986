import { useModal } from '@shared/hooks/useModal';
import { UrlParams } from '@shared/models/generic';
import { inboxSlice } from '@shared/store/inboxSlice';
import { settingsSlice } from '@shared/store/settingsSlice';
import { auth } from '@shared/store/setup/firebase-setup';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/inbox/inbox.module.scss';
import { EmailAuthProvider, fetchSignInMethodsForEmail } from 'firebase/auth';
import queryString from 'query-string';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import AuthPasswordModal from '../auth/steps/AuthPasswordModal';
import InboxSidebar from './sidebar/InboxSidebar';

const Inbox: React.FC = () => {
  const location = useLocation();
  const { inboxId }: UrlParams = useParams();
  const dispatch = useDispatch();
  const { showModal } = useModal();

  const activeDocTypeId = (queryString.parse(location.search).docType as string) || '';
  const activeSubTypeId = queryString.parse(location.search).subType as string;

  const sidebarData = useSelector((state) => state.inbox.sideBarData);
  const typeMap = useSelector((state) => state.inbox.typeMap);
  const storedSidebarWidth = useSelector((state) => state.settings.sidebarWidth);
  const { inboxId: filterInboxId } = useSelector((state) => state.inbox.documentListOptions);
  const emailPreferenceSet = useSelector((state) => state.user.emailPreferenceSet);
  const { email } = useSelector((state) => state.user.userAccount);

  const [sidebarWidth, setSidebarWidth] = useState(storedSidebarWidth);
  const sliderRef = useRef();

  useEffect(() => {
    if (typeMap) {
      dispatch(
        inboxSlice.actions.setActiveDocumentType({
          docTypeId: activeDocTypeId,
          ...(activeSubTypeId !== undefined && {
            subTypeId: activeSubTypeId,
          }),
        })
      );
    }
  }, [activeDocTypeId, activeSubTypeId, dispatch, typeMap]);

  useEffect(() => {
    if (inboxId !== filterInboxId) {
      dispatch(inboxSlice.actions.patchDocumentListOptions({ inboxId: inboxId, activeTagId: null }));
      dispatch(inboxSlice.actions.setCurrentPageIndex(0));
    }
  }, [dispatch, inboxId, filterInboxId]);

  const navigate = useNavigate();
  useAsyncEffect(async () => {
    if (!emailPreferenceSet) {
      const signInMethods = await fetchSignInMethodsForEmail(auth, email);

      if (signInMethods.includes(EmailAuthProvider.EMAIL_LINK_SIGN_IN_METHOD)) {
        showModal(<AuthPasswordModal navigate={navigate} email={email} />);
      }
    }
  }, [emailPreferenceSet, email, showModal]);

  const handleSliderMouseDown = (e: React.PointerEvent<HTMLDivElement>) => {
    const slider = sliderRef.current as HTMLDivElement;
    slider.onpointermove = handleSliderMouseMove;
    slider.setPointerCapture(e.pointerId);
  };
  const handleSliderMouseUp = (e: React.PointerEvent<HTMLDivElement>) => {
    const slider = sliderRef.current as HTMLDivElement;
    slider.onpointermove = null;
    slider.releasePointerCapture(e.pointerId);
  };
  const handleSliderMouseMove = (e) => {
    const size = e.pageX + 10;
    const width = size < 271 ? 271 : size > 400 ? 400 : size;
    dispatch(settingsSlice.actions.setSidebarWidth(width));
    setSidebarWidth(width);
  };

  return (
    <div data-testid={'home-grid'} className={s.grid}>
      <div
        style={{
          willChange: 'width, minWidth',
          display: 'flex',
          width: sidebarWidth,
          minWidth: sidebarWidth,
        }}
      >
        <InboxSidebar
          activeType={{
            docTypeId: activeDocTypeId,
            ...(activeSubTypeId && { subTypeId: activeSubTypeId }),
          }}
          sidebarData={sidebarData}
        />
        <div
          ref={sliderRef}
          onPointerDown={handleSliderMouseDown}
          onPointerUp={handleSliderMouseUp}
          style={{ background: 'transparent', minWidth: 15, cursor: 'col-resize' }}
        />
      </div>
      <div style={{ display: 'flex', flex: 1, minWidth: 0, flexDirection: 'column', height: '100vh' }}>
        <Outlet />
      </div>
    </div>
  );
};

export default Inbox;
