import s from '@shared/styles/component/auth/auth.module.scss';
import {DotPulse} from '@uiball/loaders';
import clsx from 'clsx';
import React, {ButtonHTMLAttributes} from 'react';

interface Props {
  isActive?: boolean;
  isLoading?: boolean;
  children?: any;
}

const AuthButton: React.FC<Props & ButtonHTMLAttributes<HTMLButtonElement>> = ({
  isActive,
  isLoading,
  className,
  children,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={clsx(className, s.button, s.button_main, s.button_continue, {
        [s.button_continue_active]: isActive,
      })}
    >
      {isLoading && <DotPulse color={!isActive ? '#0085FF' : 'white'} size={35} />}
      {!isLoading && children}
    </button>
  );
};

export default AuthButton;
