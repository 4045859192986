import { usePrevious } from '@shared/helpers/helpers';
import { Bounds, DocumentEntity, EntityTable, TaggedBounds, Token } from '@shared/models/document';
import { ReactComponent as MinusIcon } from '@svg/minus-icon.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import clsx from 'clsx';
import { cloneDeep, isEqual } from 'lodash';
import React, { CSSProperties, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { convertTableEntityToRowCols, convertTablePointsToTable } from './helpers/helpers';
import s from './labeler-view.module.scss';

type TableElementType =
  | 'row'
  | 'col'
  | 'ver-left'
  | 'ver-right'
  | 'hor-top'
  | 'hor-bottom'
  | 'corner-top-left'
  | 'corner-top-right'
  | 'corner-bottom-left'
  | 'corner-bottom-right';

let activeDragId: string;
let activeDragType: TableElementType;
let activeResizeType: TableElementType;

interface TableProps {
  tableEntity?: DocumentEntity;
  pageRef: any;
  tokens: Token[];
  isActive: boolean;
  isTempEntity?: boolean;
  isTableEditActive: boolean;
  setIsActive?: VoidFunction;
  setTableEntity: (entity: DocumentEntity) => void;
  activeCell?: { rowIndex: number; colIndex: number };
  pageScale: number;
}

export const LabelerTable: React.FC<TableProps> = ({
  isTempEntity,
  pageRef,
  pageScale,
  tableEntity,
  isTableEditActive,
  isActive,
  setIsActive,
  setTableEntity,
  activeCell,
  tokens,
}) => {
  const [colPoints, setColPoints] = useState<TaggedBounds[]>([]);
  const [rowPoints, setRowPoints] = useState<TaggedBounds[]>([]);
  const [tableBounds, setTableBounds] = useState<Bounds>();
  const [activeCellBounds, setActiveCellBounds] = useState<Bounds>();
  const [sidePosition, setSidePosition] = useState<{ x: number; y: number }>();
  const rulerTopRef = useRef();
  const rulerLeftRef = useRef();
  const [hoveredRowIndex, setHoveredRowIndex] = useState<number>(null);
  const [hoveredColIndex, setHoveredColIndex] = useState<number>(null);
  const [hiddenIndexes, setHiddenIndexes] = useState<any>(
    tableEntity?.value['hiddenIndexes'] ? cloneDeep(tableEntity.value['hiddenIndexes']) : { col: {}, row: {} }
  );

  useEffect(() => {
    if (tableEntity) {
      const { colPoints, rowPoints, tableBounds } = convertTableEntityToRowCols(tableEntity);
      setRowPoints(rowPoints);
      setColPoints(colPoints);
      setTableBounds(tableBounds);
    }
  }, [tableEntity]);

  const handleLineDelete = (evt: any, type: 'row' | 'col', index: number) => {
    evt.preventDefault();
    if (isTableEditActive) {
      if (type === 'col') {
        const sorted = colPoints.sort((a, b) => a.x1 - b.x1);
        sorted.splice(index, 1);
        setColPoints([...sorted]);
        setHoveredColIndex(null);
      } else {
        const sorted = rowPoints.sort((a, b) => a.y1 - b.y1);
        sorted.splice(index, 1);
        setRowPoints(sorted);
        setHoveredRowIndex(null);
      }
      setHiddenIndexes({ row: {}, col: {} });
    }
  };

  useEffect(() => {
    if (activeCell && tableEntity) {
      const table = tableEntity.value as EntityTable;
      const cell = table.columns[activeCell.colIndex].cells[activeCell.rowIndex];
      setActiveCellBounds(cell?.valueLocations[0]);
    } else {
      setActiveCellBounds(null);
    }
  }, [activeCell, tableEntity]);

  const handleRulerMouseLeave = () => {
    setSidePosition(null);
  };
  const handleRulerLineMouseLeave = () => {
    setSidePosition(null);
    setHoveredRowIndex(null);
    setHoveredColIndex(null);
  };

  const handleRulerMouseMove = (
    e: React.MouseEvent<HTMLDivElement>,
    type: 'row-border' | 'col-border' | 'row-line' | 'col-line',
    hoveredIndex?: number
  ) => {
    const pageElement = pageRef.current as HTMLDivElement;
    const pageBounds = pageElement.getBoundingClientRect();
    if (type.includes('col')) {
      if (type === 'col-line') {
        setHoveredColIndex(hoveredIndex);
      } else {
        setHoveredColIndex(null);
      }
      setSidePosition({ x: (e.clientX - pageBounds.x) / pageScale, y: 0 });
    } else {
      if (type === 'row-line') {
        setHoveredRowIndex(hoveredIndex);
      } else {
        setHoveredRowIndex(null);
      }
      setSidePosition({ x: 0, y: (e.clientY - pageBounds.y) / pageScale });
    }
  };

  const handleLineCreate = (e: React.MouseEvent<HTMLDivElement>, type: 'row' | 'col') => {
    e.preventDefault();
    if (isTableEditActive) {
      const pageElement = pageRef.current as HTMLDivElement;
      const pageBounds = pageElement.getBoundingClientRect();

      if (type === 'col') {
        const ncp: Bounds = {
          x1: (e.clientX - pageBounds.x) / pageScale,
          y1: tableBounds.y1,
          x2: (e.clientX - pageBounds.x) / pageScale,
          y2: tableBounds.y2,
        };
        const taggedPoint: TaggedBounds = {
          ...ncp,
          id: `x1${ncp.x1}y1${ncp.y1}x2${ncp.x2}y2${ncp.y2}`,
        };
        const sorted = [...colPoints, taggedPoint].sort((a, b) => a.x1 - b.x1);
        setColPoints(sorted);
      } else {
        const nrp: Bounds = {
          x1: tableBounds.x1,
          y1: (e.clientY - pageBounds.y) / pageScale,
          x2: tableBounds.x2,
          y2: (e.clientY - pageBounds.y) / pageScale,
        };
        const taggedPoint: TaggedBounds = {
          ...nrp,
          id: `x1${nrp.x1}y1${nrp.y1}x2${nrp.x2}y2${nrp.y2}`,
        };
        const sorted = [...rowPoints, taggedPoint].sort((a, b) => a.y1 - b.y1);
        setRowPoints(sorted);
      }
      setSidePosition(undefined);
    }
  };

  const strokeColor = useMemo(() => {
    if (isTempEntity) {
      return 'rgb(250,203,32)';
    } else if (isTableEditActive) {
      return 'rgba(0,133,255,1)';
    } else if (isActive) {
      return 'rgba(255,14,252,1)';
    } else {
      return 'rgba(112,221,186,1)';
    }
  }, [isActive, isTableEditActive, isTempEntity]);

  const fillColor = useMemo(() => {
    if (isTempEntity) {
      return 'rgb(250,203,32,0.05)';
    } else if (isTableEditActive) {
      return 'rgba(0,133,255,0.05)';
    } else if (isActive) {
      return 'rgba(255,14,252,0.05)';
    } else return null;
  }, [isActive, isTableEditActive, isTempEntity]);

  useEffect(() => {
    if (tableBounds) {
      setRowPoints((prevState) => {
        const workableRow = cloneDeep(prevState);
        workableRow.forEach((e) => {
          e.x1 = tableBounds.x1;
          e.x2 = tableBounds.x2;
        });
        return workableRow;
      });
      setColPoints((prevState) => {
        const workableCol = cloneDeep(prevState);
        workableCol.forEach((e) => {
          e.y1 = tableBounds.y1;
          e.y2 = tableBounds.y2;
        });
        return workableCol;
      });
    }
  }, [tableBounds]);

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
    activeDragId = null;
  };

  const handleMouseDown = (col: TaggedBounds, type: TableElementType) => {
    if (!isTableEditActive) return;
    activeDragId = col.id;
    activeDragType = type;
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const pageElement = pageRef.current as HTMLDivElement;
    const pageBounds = pageElement.getBoundingClientRect();
    const colPointsClone = cloneDeep(colPoints);
    const rowPointsClone = cloneDeep(rowPoints);
    if (activeDragType === 'col') {
      const pointIndex = colPoints.findIndex((colBounds) => colBounds.id === activeDragId);
      colPointsClone[pointIndex].x1 = (e.clientX - pageBounds.x) / pageScale;
      colPointsClone[pointIndex].x2 = (e.clientX - pageBounds.x) / pageScale;
      setColPoints(colPointsClone);
    } else if (activeDragType === 'row') {
      const pointIndex = rowPoints.findIndex((rowBounds) => rowBounds.id === activeDragId);
      rowPointsClone[pointIndex].y1 = (e.clientY - pageBounds.y) / pageScale;
      rowPointsClone[pointIndex].y2 = (e.clientY - pageBounds.y) / pageScale;
      setRowPoints(rowPointsClone);
    }
  };

  const handleResizeDown = (type: TableElementType) => {
    activeResizeType = type;
    //Was planning on using pointerCapture for these, but the CSS pointer style gets lost when dragging :(
    document.addEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', handleResizeUp);
  };

  const handleResize = useCallback(
    (e) => {
      const pageElement = pageRef.current as HTMLDivElement;
      const pageBounds = pageElement.getBoundingClientRect();
      const boundsClone = cloneDeep(tableBounds);

      if (activeResizeType.includes('top')) {
        boundsClone.y1 = (e.clientY - pageBounds.y) / pageScale;
      }
      if (activeResizeType.includes('bottom')) {
        boundsClone.y2 = (e.clientY - pageBounds.y) / pageScale;
      }
      if (activeResizeType.includes('right')) {
        boundsClone.x2 = (e.clientX - pageBounds.x) / pageScale;
      }
      if (activeResizeType.includes('left')) {
        boundsClone.x1 = (e.clientX - pageBounds.x) / pageScale;
      }

      setTableBounds(boundsClone);
    },
    [pageRef, pageScale, tableBounds]
  );
  const handleResizeUp = () => {
    activeResizeType = null;
    document.removeEventListener('mousemove', handleResize);
    document.addEventListener('mouseup', handleResizeUp);
  };

  const prevTableEditActive = usePrevious(isTableEditActive);

  useEffect(() => {
    if (tableEntity && isTableEditActive === false && prevTableEditActive === true) {
      const ent = convertTablePointsToTable(
        colPoints,
        rowPoints,
        tableBounds,
        tableEntity,
        tokens,
        hiddenIndexes
      );
      if (!isEqual(ent.value, tableEntity.value)) {
        if (ent.value['hasHeaders'] !== undefined) setTableEntity(ent);
      }
    }
  }, [
    colPoints,
    isTableEditActive,
    prevTableEditActive,
    rowPoints,
    setTableEntity,
    tableBounds,
    tableEntity,
    tokens,
    hiddenIndexes,
  ]);
  const [workingTable, setWorkingTable] = useState<DocumentEntity>();

  useEffect(() => {
    if (colPoints && rowPoints && tableBounds) {
      setWorkingTable(
        convertTablePointsToTable(colPoints, rowPoints, tableBounds, tableEntity, tokens, hiddenIndexes)
      );
    }
  }, [hiddenIndexes, colPoints, rowPoints, tableBounds, tableEntity, tokens]);

  const labelerButtonStyle = useMemo(() => {
    let style: CSSProperties = {
      background: '#0085FF',
    };
    if (isTempEntity) {
      style.background = '#FCBF19';
    }
    if (hoveredColIndex !== null || hoveredRowIndex !== null) {
      style.background = '#FF5555';
    }
    if (!tableBounds) return style;
    if (sidePosition) {
      if (sidePosition.x) {
        let left = sidePosition.x * pageScale;
        if (hoveredColIndex !== null && colPoints) {
          left = colPoints[hoveredColIndex].x1 * pageScale;
        }
        style = {
          ...style,
          marginTop: -21,
          marginLeft: -10,
          left: left,
          top: tableBounds.y1 * pageScale,
        };
      } else if (sidePosition.y) {
        let top = sidePosition.y * pageScale ?? 1;
        if (hoveredRowIndex !== null && rowPoints) {
          top = rowPoints[hoveredRowIndex].y1 * pageScale;
        }
        style = {
          ...style,
          marginLeft: -21,
          marginTop: -10,
          top: top,
          left: tableBounds.x1 * pageScale,
        };
      }
    }
    return style;
  }, [
    colPoints,
    hoveredColIndex,
    hoveredRowIndex,
    isTempEntity,
    pageScale,
    rowPoints,
    sidePosition,
    tableBounds,
  ]);

  return (
    <div>
      {tableBounds && (
        <div
          className={clsx(s.annotation, s.annotation__table)}
          onClick={() => {
            if (!isActive) setIsActive();
          }}
          style={{
            width: (tableBounds.x2 - tableBounds.x1) * pageScale,
            height: (tableBounds.y2 - tableBounds.y1) * pageScale,
            top: tableBounds.y1 * pageScale,
            left: tableBounds.x1 * pageScale,
            border: `2px solid ${strokeColor}`,
            borderRadius: 4,
            background: fillColor,
            borderColor: strokeColor,
          }}
        />
      )}
      {activeCellBounds && (
        <div
          className={s.table_cell}
          style={{
            width: (activeCellBounds.x2 - activeCellBounds.x1) * pageScale,
            height: (activeCellBounds.y2 - activeCellBounds.y1) * pageScale,
            top: activeCellBounds.y1 * pageScale,
            left: activeCellBounds.x1 * pageScale,
          }}
        />
      )}
      {workingTable?.value && (
        <LabelerTableHideElement
          isTableEditActive={isTableEditActive}
          hiddenIndexes={hiddenIndexes}
          pageScale={pageScale}
          tableEntity={workingTable}
          handleEdit={(index, type, newValue) => {
            setHiddenIndexes((hi) => {
              const clone = cloneDeep(hi);
              clone[type][index] = newValue;
              return clone;
            });
          }}
        />
      )}
      {rowPoints &&
        rowPoints.map((row, i) => {
          if (row.y1 < tableBounds.y1) return null;
          if (row.y2 > tableBounds.y2) return null;
          return (
            <React.Fragment key={row.id}>
              <div
                draggable={false}
                onMouseDown={() => handleMouseDown(row, 'row')}
                className={clsx(s.table_line_wrapper, s.table_line_wrapper__row)}
                style={{
                  pointerEvents: isTableEditActive ? 'all' : 'none',
                  cursor: isTableEditActive ? 'row-resize' : 'pointer',
                  top: row.y1 * pageScale - 7.5,
                  left: row.x1 * pageScale,
                  height: 15,
                  width: (row.x2 - row.x1) * pageScale,
                }}
              >
                <div
                  style={{ borderColor: strokeColor }}
                  className={clsx(s.table_line, s.table_line__row, {
                    [s.table_line__delete]: hoveredRowIndex === i,
                  })}
                />
              </div>
              {isTableEditActive && (
                <div
                  className={s.table_line_wrapper}
                  onMouseMove={(e) => handleRulerMouseMove(e, 'row-line', i)}
                  onMouseLeave={handleRulerLineMouseLeave}
                  onClick={(e) => handleLineDelete(e, 'row', i)}
                  style={{
                    top: row.y1 * pageScale - 7.5,
                    left: tableBounds.x1 * pageScale - 16,
                    width: 15,
                    height: 15,
                  }}
                >
                  <div className={clsx(s.table_line, s.table_line__row, s.table_line__small)} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      {colPoints &&
        colPoints.map((col, i) => {
          if (col.x1 < tableBounds.x1) return null;
          if (col.x2 > tableBounds.x2) return null;
          return (
            <React.Fragment key={col.id}>
              <div
                draggable={false}
                onMouseDown={() => handleMouseDown(col, 'col')}
                className={s.table_line_wrapper}
                style={{
                  cursor: isTableEditActive ? 'col-resize' : 'pointer',
                  pointerEvents: isTableEditActive ? 'all' : 'none',
                  top: col.y1 * pageScale,
                  left: col.x1 * pageScale - 7.5,
                  width: 15,
                  height: (col.y2 - col.y1) * pageScale,
                }}
              >
                <div
                  style={{ borderColor: strokeColor }}
                  className={clsx(s.table_line, s.table_line__col, {
                    [s.table_line__delete]: hoveredColIndex === i,
                  })}
                />
              </div>
              {isTableEditActive && (
                <div
                  className={s.table_line_wrapper}
                  style={{
                    top: tableBounds.y1 * pageScale - 16,
                    left: col.x1 * pageScale - 7.5,
                    height: 15,
                    width: 15,
                  }}
                  onMouseMove={(e) => handleRulerMouseMove(e, 'col-line', i)}
                  onMouseLeave={handleRulerLineMouseLeave}
                  onClick={(e) => handleLineDelete(e, 'col', i)}
                >
                  <div className={clsx(s.table_line, s.table_line__col, s.table_line__small)} />
                </div>
              )}
            </React.Fragment>
          );
        })}
      {isTableEditActive && tableBounds && (
        <>
          {sidePosition && (
            <>
              <div
                className={clsx(s.table_button, {
                  [s.table_button__delete]: hoveredColIndex !== null || hoveredRowIndex !== null,
                })}
                style={labelerButtonStyle}
              >
                <PlusIcon />
              </div>

              {sidePosition.x !== 0 && hoveredColIndex === null && hoveredRowIndex === null && (
                <div
                  className={s.table_line_wrapper}
                  style={{
                    top: tableBounds.y1 * pageScale,
                    left: sidePosition.x * pageScale - 7.5,
                    height: (tableBounds.y2 - tableBounds.y1) * pageScale,
                    width: 15,
                  }}
                >
                  <div
                    style={{ borderColor: strokeColor }}
                    className={clsx(s.table_line, s.table_line__col, s.table_line__temp)}
                  />
                </div>
              )}
              {sidePosition.y !== 0 && hoveredColIndex === null && hoveredRowIndex === null && (
                <div
                  className={s.table_line_wrapper}
                  style={{
                    top: sidePosition.y * pageScale - 7.5,
                    left: tableBounds.x1 * pageScale,
                    width: (tableBounds.x2 - tableBounds.x1) * pageScale,
                    height: 15,
                  }}
                >
                  <div
                    style={{ borderColor: strokeColor }}
                    className={clsx(s.table_line, s.table_line__row, s.table_line__temp)}
                  />
                </div>
              )}
            </>
          )}
          <div
            onPointerDown={() => handleResizeDown('corner-top-left')}
            style={{
              cursor: 'nwse-resize',
              top: tableBounds.y1 * pageScale - 10,
              left: tableBounds.x1 * pageScale - 10,
            }}
            className={clsx(s.table_resize_wrapper, s.table_resize_wrapper__corner)}
          />
          <div
            onPointerDown={() => handleResizeDown('corner-top-right')}
            style={{
              cursor: 'nesw-resize',
              top: tableBounds.y1 * pageScale - 10,
              left: tableBounds.x2 * pageScale - 10,
            }}
            className={clsx(s.table_resize_wrapper, s.table_resize_wrapper__corner)}
          />
          <div
            onPointerDown={() => handleResizeDown('corner-bottom-left')}
            style={{
              cursor: 'nesw-resize',
              top: tableBounds.y2 * pageScale - 10,
              left: tableBounds.x1 * pageScale - 10,
            }}
            className={clsx(s.table_resize_wrapper, s.table_resize_wrapper__corner)}
          />
          <div
            onPointerDown={() => handleResizeDown('corner-bottom-right')}
            style={{
              cursor: 'nwse-resize',
              top: tableBounds.y2 * pageScale - 10,
              left: tableBounds.x2 * pageScale - 10,
            }}
            className={clsx(s.table_resize_wrapper, s.table_resize_wrapper__corner)}
          />
          <div
            onPointerDown={() => handleResizeDown('ver-left')}
            style={{
              cursor: 'col-resize',
              top: tableBounds.y1 * pageScale - 5,
              left: tableBounds.x1 * pageScale - 5,
              height: (tableBounds.y2 - tableBounds.y1) * pageScale,
              width: 10,
            }}
            className={clsx(s.table_resize_wrapper)}
          />
          <div
            onPointerDown={() => handleResizeDown('ver-right')}
            style={{
              cursor: 'col-resize',
              top: tableBounds.y1 * pageScale - 5,
              left: tableBounds.x2 * pageScale - 5,
              height: (tableBounds.y2 - tableBounds.y1) * pageScale,
              width: 10,
            }}
            className={clsx(s.table_resize_wrapper)}
          />
          <div
            onPointerDown={() => handleResizeDown('hor-bottom')}
            style={{
              cursor: 'row-resize',
              top: tableBounds.y2 * pageScale - 5,
              left: tableBounds.x1 * pageScale - 5,
              width: (tableBounds.x2 - tableBounds.x1) * pageScale,
              height: 10,
            }}
            className={clsx(s.table_resize_wrapper)}
          />
          <div
            onPointerDown={() => handleResizeDown('hor-top')}
            style={{
              cursor: 'row-resize',
              top: tableBounds.y1 * pageScale - 5,
              left: tableBounds.x1 * pageScale - 5,
              width: (tableBounds.x2 - tableBounds.x1) * pageScale,
              height: 10,
            }}
            className={clsx(s.table_resize_wrapper)}
          />

          <div
            className={s.table_ruler_wrapper}
            ref={rulerTopRef}
            onClick={(e) => handleLineCreate(e, 'col')}
            onMouseMove={(e) => handleRulerMouseMove(e, 'col-border')}
            onMouseLeave={handleRulerMouseLeave}
            style={{
              top: tableBounds.y1 * pageScale - 18,
              left: tableBounds.x1 * pageScale,
              height: 15,
              width: (tableBounds.x2 - tableBounds.x1) * pageScale,
            }}
          >
            <div
              style={{ background: strokeColor, height: 8, width: '100%' }}
              className={clsx(s.table_ruler, {
                [s.table_ruler__active]: sidePosition?.x,
              })}
            />
          </div>
          <div
            className={s.table_ruler_wrapper}
            ref={rulerLeftRef}
            onClick={(e) => handleLineCreate(e, 'row')}
            onMouseMove={(e) => handleRulerMouseMove(e, 'row-border')}
            onMouseLeave={handleRulerMouseLeave}
            style={{
              top: tableBounds.y1 * pageScale,
              left: tableBounds.x1 * pageScale - 18,
              width: 15,
              height: (tableBounds.y2 - tableBounds.y1) * pageScale,
            }}
          >
            <div
              style={{
                background: strokeColor,
                width: 8,
                height: '100%',
              }}
              className={clsx(s.table_ruler, {
                [s.table_ruler__active]: sidePosition?.y,
              })}
            />
          </div>
        </>
      )}
    </div>
  );
};
type HideElementProps = {
  isTableEditActive: boolean;
  hiddenIndexes: Record<'col' | 'row', Record<string, boolean>>;
  pageScale: number;
  tableEntity: DocumentEntity;
  handleEdit: (index: number, type: 'col' | 'row', newValue: boolean) => void;
};
const LabelerTableHideElement: React.FC<HideElementProps> = ({
  isTableEditActive,
  hiddenIndexes,
  tableEntity,
  pageScale,
  handleEdit,
}) => {
  const table = tableEntity?.value as EntityTable;
  const [hoveredCellIndexes, setHoveredCellIndexes] = useState<{ ri: number; ci: number }>();
  const [cells, setCells] = useState<{ colIndex: number; rowIndex: number; loc: Bounds }[]>([]);
  const { allRowPoints, allColPoints } = convertTableEntityToRowCols(tableEntity);

  useEffect(() => {
    const cellMap = {};
    Object.values(table.columns).forEach((col, ci) => {
      Object.values(col.cells).forEach((cell, ri) => {
        cellMap['c' + ci + 'r' + ri] = {
          colIndex: ci,
          rowIndex: ri,
          loc: cell.valueLocations[0],
        };
      });
    });

    setCells(Object.values(cellMap));
  }, [table]);

  return (
    <React.Fragment key={'t'}>
      {cells.map((cell) => {
        const bounds = cell.loc;
        let active = false;
        if (hoveredCellIndexes?.ci === cell.colIndex || hoveredCellIndexes?.ri === cell.rowIndex) {
          active = true;
        }
        const isHidden = hiddenIndexes?.col[cell.colIndex] || hiddenIndexes?.row[cell.rowIndex];
        return (
          <div
            key={`r${cell.rowIndex}c${cell.colIndex}`}
            className={s.table_hover}
            onMouseOver={() => {
              if (!isTableEditActive) return;
              setHoveredCellIndexes({ ci: cell.colIndex, ri: cell.rowIndex });
            }}
            onMouseOut={() => setHoveredCellIndexes(null)}
            style={{
              pointerEvents: isTableEditActive ? 'all' : 'none',
              position: 'absolute',
              top: bounds.y1 * pageScale,
              left: bounds.x1 * pageScale,
              height: (bounds.y2 - bounds.y1) * pageScale + 2,
              width: (bounds.x2 - bounds.x1) * pageScale + 2,
              zIndex: 10,
              background: isHidden ? '#000000' : '#0085FF',
              border: '1px solid red',
              opacity: active || isHidden ? 0.1 : 0,
            }}
          ></div>
        );
      })}

      {isTableEditActive &&
        allRowPoints.map((rp, i) => {
          const isRowHidden = hiddenIndexes?.row?.[i] === true;
          return (
            <button
              key={i}
              className={clsx(s.table_hover_button, {
                [s.table_hover_button__hovered]: hoveredCellIndexes?.ri === i,
              })}
              onClick={() => handleEdit(i, 'row', !isRowHidden)}
              onMouseOver={() => {
                if (!isTableEditActive) return;
                setHoveredCellIndexes({ ri: i, ci: -1 });
              }}
              onMouseOut={() => setHoveredCellIndexes(null)}
              style={{
                position: 'absolute',
                top: (rp.y1 + (rp.y2 - rp.y1) / 2) * pageScale - 12,
                left: rp.x2 * pageScale + 8,
                height: 24,
                width: 24,
                zIndex: 11,
                borderRadius: 3,
                background: isRowHidden ? '#0085FF' : '#54585f',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              {isRowHidden ? <PlusIcon /> : <MinusIcon />}
            </button>
          );
        })}
      {isTableEditActive &&
        allColPoints.map((cp, i) => {
          const isColHidden = hiddenIndexes?.col?.[i] === true;

          return (
            <button
              key={i}
              className={clsx(s.table_hover_button, {
                [s.table_hover_button__hovered]: hoveredCellIndexes?.ci === i,
              })}
              onClick={() => handleEdit(i, 'col', !isColHidden)}
              onMouseOver={() => setHoveredCellIndexes({ ri: -1, ci: i })}
              onMouseOut={() => setHoveredCellIndexes(null)}
              style={{
                position: 'absolute',
                top: cp.y2 * pageScale + 8,
                left: (cp.x2 - (cp.x2 - cp.x1) / 2) * pageScale - 12,
                height: 24,
                width: 24,
                zIndex: 11,
                background: isColHidden ? '#0085FF' : '#54585f',
                color: 'white',
                cursor: 'pointer',
              }}
            >
              {isColHidden ? <PlusIcon /> : <MinusIcon />}
            </button>
          );
        })}
    </React.Fragment>
  );
};
