import { ReactComponent as InfinityIcon } from '@svg/infinity.svg';
import clsx from 'clsx';
import React from 'react';
import ReactSlider from 'react-slider';
import s from './slider-multi.module.scss';
import si from './slider.module.scss';

import './slider.css';

const MultiSlider: React.FC<any> = ({ minValue, maxValue, onChange, smallInput }) => {
  return (
    <div className={si.container}>
      <input
        value={minValue}
        onChange={(e) => onChange([e.target.value, maxValue])}
        type="number"
        className={clsx(si.number, { [si.number__small]: smallInput })}
      />
      <ReactSlider
        onChange={onChange}
        min={0}
        max={20}
        value={[minValue ?? 0, maxValue ?? 20]}
        className={s.slider}
        thumbClassName={s.thumb}
        trackClassName={'track'}
        ariaLabel={['min', 'max']}
        pearling
      />
      <input
        value={maxValue}
        onChange={(e) => onChange([minValue, parseInt(e.target.value)])}
        type="number"
        className={clsx(
          si.number,
          { [si.number__small]: smallInput },
          { [si.number__hidden]: maxValue === 20 }
        )}
      />
      {maxValue === 20 && <InfinityIcon className={s.number_icon} />}
    </div>
  );
};

export default MultiSlider;
