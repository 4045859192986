import Button from '@components/shared/button/Button';
import Input from '@components/shared/input/Input';
import Modal from '@components/shared/modal/Modal';
import {useModal} from '@shared/hooks/useModal';
import s from '@shared/styles/component/admin/admin.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  currentList: string[];
  handleAddItem: (newItem: any) => void;
  errorMessage?: string;
  title?: string;
  description?: string;
}

const AdminInputDialog: React.FC<Props> = ({
  currentList,
  handleAddItem,
  errorMessage,
  title,
  description,
}) => {
  const { closeDialog } = useModal();
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [error, setError] = useState<string>();

  const handleSave = (e) => {
    e.preventDefault();
    if (currentList.includes(inputValue)) {
      setError(errorMessage ?? 'Domain already whitelisted');
    } else {
      handleAddItem(inputValue);
      closeDialog();
    }
  };
  useEffect(() => {
    setError(null);
  }, []);

  return (
    <Modal isDialog>
      <form onSubmit={handleSave} className={s.dialog_container}>
        <div className={s.dialog_header}>
          <span className={s.dialog_title}>{title ? title : t('admin:tenant.domainsAddTitle')}</span>
          <CrossIcon className={s.dialog_cross} onClick={closeDialog} />
        </div>
        <div className={s.dialog_body}>
          <p className={s.dialog_text}>
            {description ? description : t('admin:tenant.domainsAddDescription')}
          </p>
          <Input
            errorText={error}
            hasError={error !== null}
            value={inputValue}
            onChange={(e) => {
              setError(null);
              setInputValue(e.target.value);
            }}
            id={'admin-input'}
          />
          <Button
            data-testid="select-confirm"
            className={s.popup_button}
            type={'submit'}
            text={t('admin:multiSelect.confirm')}
          />
        </div>
      </form>
    </Modal>
  );
};

export default AdminInputDialog;
