import FormBodyHeader from '@components/admin/components/form/FormBodyHeader.tsx';
import {FormHeaderNav} from '@components/admin/components/form/FormHeaderNav.tsx';
import FormInputField from '@components/admin/components/form/FormInputField.tsx';
import FormSection from '@components/admin/components/form/FormSection.tsx';
import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import {IClientDocTypeSubType} from '@shared/helpers/converters/doctype.ts';
import {convertToId} from '@shared/helpers/helpers.ts';
import useChangeTracker, {ChangeSaveCallback} from '@shared/hooks/useChangeTracker.tsx';
import {useModal} from '@shared/hooks/useModal.tsx';
import {adminSlice} from '@shared/store/adminSlice.ts';
import {useDispatch, useSelector} from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useNavigate, useParams} from 'react-router-dom';

const AdminInboxesSubtypeEdit: React.FC = () => {
  const inboxes = useSelector((state) => state.admin.inboxes);
  const inboxDocTypes = useSelector((state) => state.admin.inboxDocTypes);
  const newTempDocType = useSelector((state) => state.admin.newTempDocType);

  const { inboxId, doctypeId, subtypeId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { showDialog } = useModal();
  const dispatch = useDispatch();

  const activeInbox = useMemo(() => {
    if (inboxes) return inboxes.find((inbox) => inbox.id === inboxId);
  }, [inboxes, inboxId]);

  const activeSubTypes = useMemo(() => {
    if (newTempDocType) {
      return newTempDocType.subtypes ?? [];
    }
    if (doctypeId && inboxDocTypes) {
      const docType = inboxDocTypes.find((e) => e.id === doctypeId);
      if (docType) {
        return docType.subtypes ?? [];
      }
    }
    return [];
  }, [newTempDocType, inboxDocTypes, doctypeId]);

  const activeSubType = useMemo(() => {
    return activeSubTypes.find((e) => e.id === subtypeId);
  }, [activeSubTypes, subtypeId]);

  const handleSave: ChangeSaveCallback<IClientDocTypeSubType> = async () => {
    const existingIndex = activeSubTypes?.findIndex((st) => st.id === activeSubType?.id);
    const clone = [...activeSubTypes];

    const subType = {
      id: state.id !== '' ? state.id : convertToId(state.name),
      name: state.name,
      providerId: state.providerId,
    } as IClientDocTypeSubType;
    if (state.promptConfig) {
      subType.promptConfig = state.promptConfig;
    }
    if (existingIndex !== -1) {
      clone[existingIndex] = subType;
    } else {
      clone.push(subType);
    }
    dispatch(adminSlice.actions.setNewTempDocType({ ...newTempDocType, subtypes: clone }));
    navigate(`/admin/inboxes/${inboxId}/doctypes/${doctypeId}`, { state: { NO_RESET: true } });
  };

  const handleDeleteSubtype = () => {
    const subtypes = [...activeSubTypes].filter((st) => st.id !== activeSubType?.id);
    dispatch(adminSlice.actions.setNewTempDocType({ ...newTempDocType, subtypes: subtypes }));
    navigate(`/admin/inboxes/${inboxId}/doctypes/${doctypeId}`, { state: { NO_RESET: true } });
  };

  const initialState = useMemo(() => {
    if (activeSubType) {
      return activeSubType;
    }
    const obj: IClientDocTypeSubType = {
      name: activeSubType?.name ?? '',
      id: activeSubType?.id ?? '',
      isArchived: false,
      providerId: activeSubType?.providerId ?? '',
      promptConfig: activeSubType?.promptConfig ?? { description: '' },
    };

    return obj;
  }, [activeSubType]);

  const handleNavBack = () => {
    const navLoc = `/admin/inboxes/${inboxId}/doctypes/${doctypeId}`;
    if (hasChanges) {
      showDialog(
        <ConfirmationDialog
          confirmAction={() => {
            save(null);
            navigate(navLoc, { state: { NO_RESET: true } });
          }}
          cancelAction={() => navigate(navLoc, { state: { NO_RESET: true } })}
          confirmText={t('document:dialog.save')}
          cancelText={t('document:dialog.discard')}
          dialogType={'confirmation'}
          title={t('admin:unsavedChanges.title')}
          text={t('admin:unsavedChanges.description')}
        />
      );
    } else {
      navigate(navLoc, { state: { NO_RESET: true } });
    }
  };

  const { save, saving, state, handleInput, hasChanges, error } = useChangeTracker<IClientDocTypeSubType>(
    initialState,
    handleSave
  );

  return (
    <form onSubmit={save} className={s.form_body}>
      <FormHeaderNav onClick={handleNavBack} label={t('admin:page.backToOverview')} />
      <FormBodyHeader
        hasChanges={hasChanges}
        saving={saving}
        buttonText={t('admin:docType.add')}
        errorMessage={error ? t('admin:docType.alreadyExists') : ''}
        title={activeSubType?.name ?? t('admin:docType.newType')}
      />
      <div className={s.sections}>
        <FormSection title={t('admin:docType.config')}>
          <FormInputField
            testId={'subtype-name-input'}
            required
            type={'text'}
            description={t('admin:docType.nameDescription')}
            onChange={(e) => handleInput(e, 'name')}
            value={state?.name ?? ''}
            label={t('admin:docType.name')}
          />

          <FormInputField
            hidden={subtypeId === 'new'}
            testId={'subtype-id-input'}
            type={'text'}
            description={t('admin:docType.idDescription')}
            value={state?.id ?? ''}
            isCopyField
            label={t('admin:docType.id')}
          />
          <FormInputField
            testId={'subtype-provider-id-input'}
            type={'text'}
            description={t('admin:docType.providerIdDescription')}
            onChange={(e) => handleInput(e, 'providerId')}
            value={state?.providerId ?? ''}
            label={t('admin:docType.providerId')}
          />
        </FormSection>
        <FormSection hidden={!activeInbox?.config.llm} title={t('admin:docType.promptConfig.title')}>
          <FormInputField
            type={'text'}
            layout={'vertical-large'}
            label={t('admin:docType.promptConfig.description')}
            description={t('admin:docType.promptConfig.descriptionDescription')}
            value={state.promptConfig?.description ?? ''}
            onChange={(e) => handleInput(e, 'promptConfig.description')}
          />
        </FormSection>

        <FormSection title={t('admin:docType.dangerZone')} hidden={!activeSubType}>
          <FormInputField
            label={t('admin:docType.subType.delete')}
            description={t('admin:docType.subType.deleteDescription')}
            type={'button'}
            buttonOptions={{
              onClick: handleDeleteSubtype,
              text: t('admin:docType.subType.delete'),
              type: 'error',
            }}
          />
        </FormSection>
      </div>
    </form>
  );
};

export default AdminInboxesSubtypeEdit;
