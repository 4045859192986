import { ApprovalCheckStatus, DocumentDetails } from '@shared/models/document';
import { useSelector } from '@shared/store/store';
import { ReactComponent as WarningIcon } from '@svg/alert-triangle-filled.svg';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as HelpIcon } from '@svg/help-icon.svg';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Tooltip from '../tooltip/Tooltip';
import s from './approval-checks.module.scss';

interface Props {
  document: DocumentDetails;
  position?: 'bottom' | 'right' | 'left';
  isInitialChecks?: boolean;
}

const ApprovalChecks: React.FC<Props> = ({ document, position, isInitialChecks = false }) => {
  const { t } = useTranslation();
  const approvalCheckTypes = useSelector((state) => state.settings.approvalCheckTypes);
  const user = useSelector((state) => state.user.userAccount);
  const key = isInitialChecks ? 'initialApprovalChecks' : 'approvalChecks';

  const checkValues = useMemo(() => {
    if (document && document[key]?.length > 0) {
      const success = document[key].filter((x) => x.status === 'succeeded').length;
      const warning = document[key].filter((x) => x.status === 'warning').length;
      const failure = document[key].filter((x) => x.status === 'failed').length;
      const info = document[key].filter((x) => x.status === 'info').length;
      return { success: success, warning: warning, failure: failure, info: info };
    }

    return { success: 0, warning: 0, failure: 0, info: 0 };
  }, [key, document]);
  const approvalMapping = useCallback(
    (check: any) => {
      if (check) return t(`document:approvalChecks.${check?.id}` as any, { defaultValue: check?.name });
    },
    [t]
  );
  const labelElement = useCallback((status: ApprovalCheckStatus) => {
    switch (status) {
      case 'succeeded':
        return <CheckmarkIcon style={{ color: '#91C500', width: '20px', marginRight: -2, height: 'auto' }} />;
      case 'failed':
        return <WarningIcon style={{ color: '#FF5555', width: '18px', height: 'auto' }} />;
      case 'info':
        return <HelpIcon style={{ color: '#c5c5c5', marginRight: -2, width: '20px', height: 'auto' }} />;
      case 'warning':
        return <HelpIcon style={{ color: '#FCBF19', marginRight: -2, width: '20px', height: 'auto' }} />;
    }
  }, []);
  return (
    <Tooltip
      lightTheme
      position={position}
      alignment={'start'}
      delay={0}
      content={
        <div className={s.rows}>
          {document &&
            document[key] &&
            [...document[key]]
              .sort((a, b) => a.id.localeCompare(b.id))
              .map((x) => {
                const mapped = approvalCheckTypes.find((y) => y.id === x.id);
                if (!user.isAdmin && x.status === 'info') return null;
                if (!mapped) return null;
                return (
                  <div key={x.id} className={s.row}>
                    <span>{approvalMapping(mapped)}</span>
                    <div className={s.label} style={{ backgroundColor: 'unset', padding: 'unset' }}>
                      {labelElement(x.status)}
                    </div>
                  </div>
                );
              })}
        </div>
      }
    >
      <div className={s.donut_wrapper}>
        <Donut {...checkValues} isAdmin={user?.isAdmin} />
      </div>
    </Tooltip>
  );
};

export default ApprovalChecks;

interface DonutProps {
  success: number;
  warning: number;
  failure: number;
  info: number;
  isAdmin: boolean;
}

export const Donut: React.FC<DonutProps> = ({ success, warning, failure, info, isAdmin }) => {
  let total = success + warning + failure;
  if (isAdmin) total += info;
  const radius = 35;
  const strokeWidth = 20;
  const circumference = Math.PI * 2 * radius;

  const successLength = (success / total) * circumference;
  const warningLength = (warning / total) * circumference;
  const infoLength = (info / total) * circumference;
  const failureLength = (failure / total) * circumference;

  return (
    <svg width="20" height="20" viewBox="0 0 120 120">
      <circle
        stroke="#00cc99"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx="60"
        cy="60"
        strokeDasharray={`${successLength} ${circumference - successLength}`}
        strokeDashoffset={0}
      />
      <circle
        stroke="#f0ad4e"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx="60"
        cy="60"
        strokeDasharray={`${warningLength} ${circumference - warningLength}`}
        strokeDashoffset={circumference - successLength}
      />{' '}
      <circle
        stroke="#FF5555"
        strokeWidth={strokeWidth}
        fill="transparent"
        r={radius}
        cx="60"
        cy="60"
        strokeDasharray={`${failureLength} ${circumference - failureLength}`}
        strokeDashoffset={circumference - successLength - warningLength}
      />
      {isAdmin && (
        <circle
          stroke="#c5c5c5"
          strokeWidth={strokeWidth}
          fill="transparent"
          r={radius}
          cx="60"
          cy="60"
          strokeDasharray={`${infoLength} ${circumference - infoLength}`}
          strokeDashoffset={circumference - successLength - warningLength - failureLength}
        />
      )}
    </svg>
  );
};
