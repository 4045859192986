import s from '@shared/styles/component/document/document-labeler-tools.module.scss';
import React, {useState} from 'react';

interface Props {
  imageUrl: string;
  children?: any;
}

let timer;
const DocumentLabelerSidebarHover: React.FC<Props> = ({ imageUrl, children }) => {
  const [isHovering, setIsHovering] = useState(false);
  return (
    <>
      {isHovering && (
        <div className={s.image_hover_wrapper}>
          <img className={s.image_hover} src={imageUrl} alt="data" />
        </div>
      )}
      <div
        onMouseEnter={() => {
          timer = setTimeout(() => setIsHovering(true), 200);
        }}
        onMouseLeave={() => {
          clearTimeout(timer);
          setIsHovering(false);
        }}
      >
        {children}
      </div>
    </>
  );
};

export default DocumentLabelerSidebarHover;
