import AdminNewUserRow from '@components/admin/components/AdminNewUserRow.tsx';
import AdminUserRow from '@components/admin/components/AdminUserRow.tsx';
import {extendedSearch, globalFuseOptions} from '@shared/helpers/helpers.ts';
import {UserAccount} from '@shared/models/user.ts';
import {listenUsers} from '@shared/store/adminSlice.ts';
import {useDispatch, useSelector} from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import {DotPulse} from '@uiball/loaders';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['email'],
};

const AdminUsers: React.FC = () => {
  const users = useSelector((state) => state.admin.users);
  const inboxes = useSelector((state) => state.admin.inboxes);
  const isInboxesLoading = useSelector((state) => state.admin.isInboxesLoading);
  const activeUser = useSelector((state) => state.user.userAccount);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [scrollCounter, setScrollCounter] = useState(0);

  const [fuseData, setFuseData] = useState([]);
  const [searchResults, setSearchResults] = useState<UserAccount[]>([]);

  const fuse = new Fuse(fuseData, fuseOptions);

  const handleInput = (value) => {
    if (value === '') {
      setSearchResults(users);
    } else {
      setSearchResults(extendedSearch(value, fuse, 'email') ?? []);
    }
  };

  useEffect(() => {
    if (users) {
      setSearchResults(users ?? []);
      setFuseData(users ?? []);
    }
  }, [users]);

  useEffect(() => {
    if (activeUser && activeUser.id) {
      dispatch(listenUsers());
    }
  }, [activeUser, dispatch]);

  return (
    <div className={se.form_body}>
      <div className={s.body_header}>
        <h2>{t('admin:users.title')}</h2>
      </div>

      <div className={s.search_header}>
        <div className={se.input_wrapper} style={{ justifyContent: 'flex-start' }}>
          <input
            data-testid={'admin-user-search'}
            onChange={(e) => handleInput(e.target.value)}
            className={clsx(s.user_search, se.input, se.input__top)}
            placeholder={t('admin:users.filter')}
            type="text"
          />
        </div>
        <AdminNewUserRow />
      </div>

      <div
        onScroll={() => setScrollCounter((state) => state + 1)}
        className={clsx(s.user_wrapper, se.section, se.section__scroll)}
      >
        {isInboxesLoading ? (
          <div className={s.user_loader}>
            <DotPulse size={45} color={'#0085ff'} />
          </div>
        ) : (
          <>
            {[...searchResults]
              .sort((a, b) => a.email.localeCompare(b.email))
              .sort((a, b) => Number(a.isHidden) - Number(b.isHidden))
              .map((user) => (
                <AdminUserRow
                  user={user}
                  scrollCounter={scrollCounter}
                  isSelf={activeUser.email === user.email}
                  key={user.id}
                  inboxes={inboxes}
                />
              ))}
          </>
        )}
      </div>
    </div>
  );
};

export default AdminUsers;
