import Checkbox from '@components/shared/checkbox/Checkbox';
import StyledSelect from '@components/shared/dropdown/StyledSelect';
import Modal from '@components/shared/modal/Modal';
import {useModal} from '@shared/hooks/useModal';
import {useSelector} from '@shared/store/store';
import s from '@shared/styles/component/document/document-type-switch.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import clsx from 'clsx';
import {cloneDeep} from 'lodash';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  handleBounce: (value: Record<string, any>) => void;
}

const DocumentBounceModal: React.FC<Props> = ({ handleBounce }) => {
  const actionTypes = useSelector((state) => state.settings.actionTypes);

  const { t } = useTranslation();
  const { closeModal } = useModal();

  const [fieldState, setFieldState] = useState<Record<string, any>>({});

  const setState = (key: string, value: any) => {
    setFieldState((fieldState) => {
      const copy = cloneDeep(fieldState);
      copy[key] = value;
      return copy;
    });
  };

  const handleSubmit = () => {
    handleBounce(fieldState);
    closeModal();
  };

  useEffect(() => {
    const obj = {};
    actionTypes.forEach((e) => {
      if (e.type === 'choice' && e.options) {
        obj[e.id] = e.options[0].id;
      } else if (e.type === 'boolean') {
        obj[e.id] = false;
      } else if (e.type === 'multi-choice') {
        obj[e.id] = [];
      } else {
        obj[e.id] = null;
      }
    });
    setFieldState(obj);
  }, [actionTypes]);

  return (
    <Modal>
      <div data-testid="type-selector-modal" className={s.modal}>
        <div className={s.header}>
          <h2 className={s.title}>{t('document:bounceAction.title')}</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.content}>
          <div className={s.description}>{t('document:bounceAction.description')}</div>
          {actionTypes.map((actionType) => {
            let actionTypeOptions = [];
            let currentValue;
            if (actionType.options) {
              actionTypeOptions = actionType.options.map((e) => ({
                label: e.name,
                value: e.id,
              }));
              currentValue = actionTypeOptions.find((e) => e.value === fieldState[actionType.id]);
            }

            return (
              <div
                key={actionType.id}
                className={clsx(s.group, {
                  [s.group__horizontal]: actionType.type === 'boolean',
                })}
              >
                <span className={s.label}>{actionType.name}</span>
                {actionType.type === 'multi-choice' && (
                  <div>
                    <StyledSelect
                      onChange={(e: any) =>
                        setState(
                          actionType.id,
                          e.map((e) => e.value)
                        )
                      }
                      isMulti
                      isLoading={!actionType.options}
                      value={currentValue}
                      options={actionTypeOptions}
                    />
                  </div>
                )}
                {actionType.type === 'choice' && actionType.options && (
                  <div>
                    <StyledSelect
                      defaultValue={actionTypeOptions[0]}
                      value={currentValue}
                      onChange={(e: any) => setState(actionType.id, e.value)}
                      options={actionTypeOptions}
                    />
                  </div>
                )}
                {actionType.type === 'text' && (
                  <textarea
                    className={s.input}
                    onChange={(e) => {
                      setState(actionType.id, e.target.value);
                    }}
                  />
                )}
                {actionType.type === 'boolean' && (
                  <Checkbox
                    checked={fieldState[actionType.id] ?? false}
                    onClick={() => setState(actionType.id, !fieldState[actionType.id])}
                  />
                )}
              </div>
            );
          })}

          <div className={s.buttons}>
            <button autoFocus tabIndex={1} onClick={handleSubmit} className={clsx(s.button, s.button__alt)}>
              {t('document:bounceAction.button')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentBounceModal;
