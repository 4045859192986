import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ApprovalCheckType} from '@shared/models/inbox.ts';
import camelcaseKeys from 'camelcase-keys';
import {DatabaseReference, onValue, ref} from 'firebase/database';
import {doc, getDoc} from 'firebase/firestore';
import {db, realtime} from './setup/firebase-setup';
import {AppThunk} from './store';
import {subsSlice} from './subsSlice';

interface DashboardState {
  listeners?: DatabaseReference[];
  mappingData?: Record<string, any>;
  userActivity?: Record<string, Date>;
  aapData?: any;
  demoMode?: boolean;
  tenantApprovalChecks?: ApprovalCheckType[];
}

const initialState: DashboardState = {
  mappingData: {},

  tenantApprovalChecks: [],
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    clearStore: (state) => Object.assign(state, initialState),

    setMappingData: (state, action: PayloadAction<any>) => {
      state.mappingData = action.payload;
    },
    setTenantApprovalChecks: (state, action: PayloadAction<any>) => {
      state.tenantApprovalChecks = action.payload;
    },

    setUserActivity: (state, action: PayloadAction<Record<string, Date>>) => {
      state.userActivity = action.payload;
    },
    setAapData: (state, action: PayloadAction<any>) => {
      state.aapData = action.payload;
    },
    setDemoMode: (state, action: PayloadAction<boolean>) => {
      state.demoMode = action.payload;
    },
  },
});

export const getUserActivity = (): AppThunk => async (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const userActivityQuery: DatabaseReference = ref(realtime, `tenants/${tenantId}/inboxes`);

  const listener = onValue(userActivityQuery, (data) => {
    const inboxMap = data.val();
    const uniqueUsers = {};
    Object.entries(inboxMap).forEach(([, iv]) => {
      Object.entries(iv['user_activity']).forEach(([uk, uv]) => {
        if (uniqueUsers[uk]) {
          if (new Date(uv['last_active']).getTime() > uniqueUsers[uk].getTime()) {
            uniqueUsers[uk] = new Date(uv['last_active']);
          }
        } else {
          uniqueUsers[uk] = new Date(uv['last_active']);
        }
      });
    });
    dispatch(dashboardSlice.actions.setUserActivity(uniqueUsers));
  });
  dispatch(subsSlice.actions.setUserActivitySub(listener));
};

export const getAllApprovalChecks = (): AppThunk => async (dispatch, getState) => {
  const tenantId = getState().tenant.tenantId;
  const inboxIds = getState().user.inboxes.map((ib) => ib.id);

  const checks = [];
  for (const inboxId of inboxIds) {
    const ref = doc(db, `tenants/${tenantId}/inboxes/${inboxId}/realtime/approval_checks`);
    const docSnap = await getDoc(ref);
    if (docSnap.exists()) {
      const data = docSnap.data();

      Object.entries(data).forEach(([k, v]) => {
        const approvalCheckType = {
          id: k,
          ...(v as any),
        } as ApprovalCheckType;
        checks.push(camelcaseKeys(approvalCheckType));
      });
    }
  }
  //Only unique values
  const filteredChecks =
    checks.filter((_, index) => checks.findIndex((c) => c.id === checks[index].id) === index) || [];
  dispatch(dashboardSlice.actions.setTenantApprovalChecks(filteredChecks));
};

export const getAllTypes =
  (type: 'tag_types' | 'doc_types' | 'users' | 'action'): AppThunk =>
  async (dispatch, getState) => {
    const allowedInboxes = getState().user.inboxes;
    const tenantId = getState().tenant.tenantId;
    let mapped = {};

    if (type === 'users') {
      const users = getState().admin.users;
      users.forEach((user) => {
        const name = user.email.split('@')[0];
        const formattedName = name
          .split('.')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');

        mapped[user.id] = { name: formattedName };
      });
    } else if (type === 'action') {
      mapped = {
        approve: { name: 'Approve' },
        delete: { name: 'Delete' },
        bounce: { name: 'Bounce' },
      };
    } else {
      const paths = allowedInboxes.map((ib) => `tenants/${tenantId}/inboxes/${ib.id}/realtime/${type}`);

      const promises = paths.map((path) => {
        const docRef = doc(db, path);
        return getDoc(docRef);
      });

      const snapshots = await Promise.all(promises);

      // Extract data from document snapshots
      const tagTypes = snapshots.map((snapshot) => {
        if (snapshot.exists) {
          return snapshot.data();
        } else {
          return null;
        }
      });

      // Filter out null values and return the result
      const tst = tagTypes.filter((item) => item !== null);
      Object.entries(tst).forEach(([, cv]) => {
        Object.entries(cv).forEach(([k, v]) => {
          if (!mapped[k] || (mapped[k] && !v.is_archived)) mapped[k] = { name: v?.name };
        });
      });
    }

    dispatch(dashboardSlice.actions.setMappingData(mapped));
  };

export default dashboardSlice.reducer;
