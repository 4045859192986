import StyledSelect from '@components/shared/dropdown/StyledSelect.tsx';
import { useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin-item-row.module.scss';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, { useMemo } from 'react';

interface Props {
  item: { id: string; value: string };
  handleDelete?: () => void;
  handleChangeValue?: (value: string) => void;
}

const AdminDocTypeMetadataRow: React.FC<Props> = ({ item, handleDelete, handleChangeValue }) => {
  const inboxMetadataTypes = useSelector((state) => state.admin.inboxMetadataTypes);
  const details = inboxMetadataTypes.find((i) => i.id === item.id);
  const options = details?.options ?? null;

  const dropdownOptions = useMemo(() => {
    if (!options) return [];
    return options.map((o) => ({
      label: o.value,
      value: o.value,
    }));
  }, [options]);

  if (!details) return null;
  return (
    <div className={s.wrapper}>
      <div data-testid="admin-meta-row" className={clsx(s.container, s.container__no_hover)}>
        <div className={s.title}>
          <span>{details?.name}</span>
        </div>
      </div>
      <div className={s.actions}>
        {options && (
          <StyledSelect
            style={{
              minWidth: 200,
              borderRadius: 5,
              minHeight: 'unset',
              height: 42,
              maxHeight: '42px',
              border: '1px solid #EEEEEE',
            }}
            options={dropdownOptions}
            onChange={(val: any) => {
              handleChangeValue(val.value);
            }}
            value={dropdownOptions.find((o) => o.label === item.value)}
          />
        )}
        {handleDelete && (
          <>
            <button
              data-testid={'admin-item-row-delete'}
              className={clsx(s.action, s.trash)}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDelete();
              }}
            >
              <TrashIcon />
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default AdminDocTypeMetadataRow;
