import { getCurrentEnvCode } from '@shared/helpers/helpers';

export interface IRawInbox {
  id: string;
  config: {
    anonymization: boolean;
    llm: boolean;
    workflow_version: string;
  };
  settings: {
    auto_advance: boolean;
    auto_delete: {
      action_type: string;
      active: boolean;
      time_field: number;
      time_format: string;
    };
    bounce: boolean;
    document_copy: boolean;
    document_download: boolean;
    document_transform: boolean;
    document_retention_time: number;
    file_upload: boolean;
    labeling_mode: boolean;
    mailroom?: boolean;
    name: string;
    inbox_move_whitelist_active?: boolean;
    inbox_move_whitelist: string[];
    table_columns: {
      name: boolean;
      digitizedDate: boolean;
      docTypeId: boolean;
      tagTypeId: boolean;
      confidence: boolean;
      lastUserUpdate: boolean;
    };
  };
}

export interface IClientInbox {
  id?: string;
  postEmail?: string;
  config?: {
    anonymization: boolean;
    llm: boolean;
    workflowVersion?: string;
  };
  settings: {
    autoAdvance: boolean;
    autoDelete?: {
      actionType: string;
      active: boolean;
      timeField: number;
      timeFormat: string;
    };
    bounce?: boolean;
    documentCopy?: boolean;
    documentDownload?: boolean;
    documentTransform?: boolean;
    documentRetentionTime?: number; // in days
    fileUpload?: boolean;
    labelingMode?: boolean;
    mailroom?: boolean;
    name: string;
    inboxMoveWhitelistActive?: boolean;
    inboxMoveWhitelist: string[];
    tableColumns: {
      name: boolean;
      digitizedDate: boolean;
      docTypeId: boolean;
      tagTypeId: boolean;
      confidence: boolean;
      lastUserUpdate: boolean;
    };
  };
}
export const defaultClientInbox: IClientInbox = {
  id: null,
  config: {
    workflowVersion: '',
    llm: false,
    anonymization: false,
  },
  settings: {
    name: '',
    tableColumns: {
      name: true,
      confidence: false,
      digitizedDate: true,
      docTypeId: true,
      lastUserUpdate: true,
      tagTypeId: false,
    },
    autoDelete: {
      actionType: null,
      active: false,
      timeField: 7,
      timeFormat: 'day',
    },
    autoAdvance: true,
    documentRetentionTime: 2,
    inboxMoveWhitelist: [],
    inboxMoveWhitelistActive: false,
  },
};
export const inboxRawToClient = (raw: IRawInbox, tenantId?: string): IClientInbox => {
  const envCode = getCurrentEnvCode();

  const obj: IClientInbox = {
    id: raw.id,
    postEmail: `${tenantId}.inbox.${raw.id}@${envCode}-ingest.paperbox.ai`,
    config: {
      anonymization: raw.config.anonymization,
      llm: raw.config.llm,
      workflowVersion: raw.config.workflow_version,
    },
    settings: {
      autoAdvance: raw.settings.auto_advance,
      bounce: raw.settings.bounce,
      documentCopy: raw.settings.document_copy,
      documentDownload: raw.settings.document_download,
      documentTransform: raw.settings.document_transform,
      documentRetentionTime: raw.settings.document_retention_time ?? 2,
      fileUpload: raw.settings.file_upload,
      labelingMode: raw.settings.labeling_mode,
      mailroom: raw.settings.mailroom,
      name: raw.settings.name,
      inboxMoveWhitelistActive: raw.settings.inbox_move_whitelist_active,
      inboxMoveWhitelist: raw.settings.inbox_move_whitelist ?? [],
      tableColumns: {
        name: raw.settings.table_columns.name,
        digitizedDate: raw.settings.table_columns.digitizedDate,
        docTypeId: raw.settings.table_columns.docTypeId,
        tagTypeId: raw.settings.table_columns.tagTypeId,
        confidence: raw.settings.table_columns.confidence,
        lastUserUpdate: raw.settings.table_columns.lastUserUpdate,
      },
    },
  };
  if (raw.settings.auto_delete) {
    obj.settings.autoDelete = {
      actionType: raw.settings.auto_delete.action_type ?? 'none',
      active: raw.settings.auto_delete.active,
      timeField: raw.settings.auto_delete.time_field,
      timeFormat: raw.settings.auto_delete.time_format,
    };
  } else {
    obj.settings.autoDelete = {
      actionType: null,
      active: false,
      timeField: 7,
      timeFormat: 'day',
    };
  }
  return obj;
};

export const inboxClientToRaw = (client: IClientInbox): IRawInbox => {
  const obj = {
    id: client.id,
    config: {
      anonymization: client.config.anonymization,
      llm: client.config.llm,
      workflow_version: client.config.workflowVersion,
    },
    settings: {
      auto_advance: client.settings.autoAdvance,
      auto_delete: {
        action_type: client.settings.autoDelete.actionType,
        active: client.settings.autoDelete.active,
        time_field: client.settings.autoDelete.timeField,
        time_format: client.settings.autoDelete.timeFormat,
      },
      bounce: client.settings.bounce,
      document_copy: client.settings.documentCopy,
      document_download: client.settings.documentDownload,
      document_transform: client.settings.documentTransform ?? false,
      document_retention_time: client.settings.documentRetentionTime,
      file_upload: client.settings.fileUpload,
      labeling_mode: client.settings.labelingMode,
      mailroom: client.settings.mailroom ?? false,
      name: client.settings.name,
      inbox_move_whitelist_active: client.settings.inboxMoveWhitelistActive,
      inbox_move_whitelist: client.settings.inboxMoveWhitelist,
      table_columns: {
        name: client.settings.tableColumns.name,
        digitizedDate: client.settings.tableColumns.digitizedDate,
        docTypeId: client.settings.tableColumns.docTypeId,
        tagTypeId: client.settings.tableColumns.tagTypeId,
        confidence: client.settings.tableColumns.confidence,
        lastUserUpdate: client.settings.tableColumns.lastUserUpdate,
      },
    },
  };
  if (obj.settings.auto_delete.action_type === 'none') {
    obj.settings.auto_delete.action_type = null;
  }
  return obj;
};
