import ConfirmationDialog from '@components/shared/confirmation-dialog/ConfirmationDialog.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip';
import {useModal} from '@shared/hooks/useModal.tsx';
import {UrlParams} from '@shared/models/generic.ts';
import {isMutationSelector} from '@shared/store/documentSlice.ts';
import {patchTopology} from '@shared/store/inboxSlice.ts';
import {labelerSlice} from '@shared/store/labelerSlice.ts';
import {useDispatch, useSelector} from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-tools.module.scss';
import {ReactComponent as SearchIcon} from '@svg/search-icon.svg';
import {ReactComponent as ToolRectangleIcon} from '@svg/tool-rectangle-icon.svg';
import {ReactComponent as UndoIcon} from '@svg/undo.svg';
import clsx from 'clsx';
import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useParams} from 'react-router-dom';
import snakecaseKeys from 'snakecase-keys';
import DocumentLabelerTool from './DocumentLabelerTool.tsx';

interface Props {
  isLoading: boolean;
}

const DocumentLabelerToolbar: React.FC<Props> = ({ isLoading }) => {
  const isMutation = useSelector(isMutationSelector);
  const activeMode = useSelector((state) => state.labeler.mode);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const { documentTransform } = useSelector((state) => state.inbox.currentInbox.settings);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const { inboxId }: UrlParams = useParams();

  const { t } = useTranslation();
  const { showDialog } = useModal();
  const dispatch = useDispatch();
  const tooltipText = {
    search: t('document:toolbar.search'),
    label: t('document:toolbar.label'),
  };
  const changeMode = (mode: 'label' | 'search') => {
    dispatch(labelerSlice.actions.setActiveMode(mode));
    if (mode === 'search') dispatch(labelerSlice.actions.setActiveTool('default'));
  };
  const hasExternalTable = useMemo(() => {
    if (masterDataMappings) return Object.values(masterDataMappings)?.some((el) => el.type === 'external');
    else return false;
  }, [masterDataMappings]);

  // Check if document has any archived parts or pages
  const hasArchivedContent = useMemo(() => {
    if (!activeDocument) return false;

    return activeDocument.topology.parts.some(
      (part) => part.archived || part.pages.some((page) => page.archived)
    );
  }, [activeDocument]);

  const handleUndoTransform = () => {
    if (!activeDocument?.topology.parts) return;

    const unarchivePart = (part) => ({
      ...part,
      archived: false,
      pages: part.pages.map((page) => ({ ...page, archived: false })),
    });
    const unarchived = activeDocument.topology.parts.map(unarchivePart);
    const mapped = {};
    unarchived
      .filter((part, index) => unarchived.findIndex((p) => p.contentId === part.contentId) === index)
      .forEach((part) => {
        const { id, ...partWithoutId } = part;
        mapped[id] = partWithoutId;
      });

    dispatch(
      patchTopology(
        activeDocument.id,
        inboxId,
        {
          topology: snakecaseKeys({
            ...activeDocument.topology,
            parts: mapped,
          }),
        },
        isMutation
      )
    );
  };
  return (
    <div className={s.bar} style={{ opacity: isLoading ? 0 : 1 }} data-tour="toolbar">
      <span>{t('document:toolbar.tool')} :</span>
      <div className={s.tools} data-tour="tools">
        {!labelingMode && masterDataMappings && !hasExternalTable && (
          <div className={s.tool_wrapper} data-testid={'labeler-tool'}>
            <Tooltip tooltipStyle={{ maxWidth: 300 }} content={tooltipText.search} position={'bottom'}>
              <button
                onClick={() => changeMode('search')}
                className={clsx(s.tool_selector, { [s.tool_selector__active]: activeMode === 'search' })}
              >
                <SearchIcon />
              </button>
            </Tooltip>
          </div>
        )}

        <div className={s.tool_wrapper} data-testid={'labeler-tool'}>
          <Tooltip tooltipStyle={{ maxWidth: 300 }} content={tooltipText.label} position={'bottom'}>
            <button
              onClick={() => changeMode('label')}
              className={clsx(s.tool_selector, { [s.tool_selector__active]: activeMode === 'label' })}
            >
              <ToolRectangleIcon />
            </button>
          </Tooltip>
        </div>
      </div>

      {!isLoading && activeMode !== 'complex' && <DocumentLabelerTool />}

      {documentTransform && hasArchivedContent && (
        <div className={s.undo}>
          <button
            onClick={() =>
              showDialog(
                <ConfirmationDialog
                  title={t('document:toolbar.transformations.undoTitle')}
                  dialogType={'confirmation'}
                  confirmAction={handleUndoTransform}
                  cancelAction={() => {}}
                  text={t('document:toolbar.transformations.undoDescription')}
                />
              )
            }
          >
            <UndoIcon /> <span>{t('document:toolbar.transformations.undo')}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default DocumentLabelerToolbar;
