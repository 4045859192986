import { mapObjToRow } from '@components/admin/helpers/helpers';
import { useModal } from '@shared/hooks/useModal';
import { DocumentDetails, DocumentEntity, EntityComplex, EntityTable } from '@shared/models/document';
import { firebaseApp } from '@shared/store/setup/firebase-setup';
import i18n from '@shared/store/setup/i18n.js';
import { getAuth } from 'firebase/auth';
import Fuse from 'fuse.js';
import { cloneDeep, each, isEqual, omit } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import snakecaseKeys from 'snakecase-keys';
import { v4 as uuidv4 } from 'uuid';

export const formatDocType = (type: string) => {
  return type.replace('_', ' ');
};
export const createWebhookField = (data, field, serverSideValues, unFlatten = true) =>
  mapObjToRow(data[field] ?? {}, serverSideValues, unFlatten) ?? [];
export const getActiveState = (date: Date) => {
  const timeSpan = 300000;
  const diff = new Date().getTime() - date.getTime();
  return diff < timeSpan;
};

export const getCurrentEnvCode = () => {
  let envCode = 'prd';
  switch (import.meta.env.VITE_PAPERBOX_ENVIRONMENT) {
    case 'develop':
      envCode = 'dev';
      break;
    case 'test':
      envCode = 'tst';
      break;
    case 'acceptance':
      envCode = 'acc';
      break;
    case 'production':
      envCode = 'prd';
      break;
  }
  return envCode;
};

export const isJWTValid = (time: number) => {
  const now = Date.now();
  return now <= time;
};

export const convertToId = (id: string) => {
  return id.replace(/[^a-zA-Z 0-9_@./-]+/g, '').replace(' ', '_');
};
export const omitDeep = (obj, paths: string[]): Pick<any, never> => {
  const r = omit(obj, ...paths);
  each(r, function (val, key) {
    if (typeof val === 'object') {
      r[key] = omitDeep(val, paths);
    }
  });

  return r;
};

export const globalFuseOptions = {
  ignoreFieldNorm: true,
  threshold: 0.4,
  ignoreLocation: true,
};
export const styleBool = (value: boolean) => {
  return value ? '✔️' : '❌';
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
export const errorMap = {
  401: i18n.t('errors.401'),
};
export const isPbxEmail = (email: string) => {
  if (!email) return false;
  return email.endsWith('@paperbox.ai') || email.endsWith('@intern.paperbox.ai');
};

export const uuid4hex = () => {
  return uuidv4().replaceAll('-', '');
};

export const getUserToken = async () => {
  const user = await getAuth(firebaseApp).currentUser;
  return user ? user.getIdToken() : null;
};

export const extendedSearch = (input: string, fuse: Fuse<any>, key = 'name') => {
  const regex = new RegExp(`\\b${input}\\b`);
  return [...(fuse.search(input).map((e) => e.item) || [])].sort((a) =>
    a[key].toLowerCase().match(regex) ? -1 : 1
  );
};

export const useDebouncedEffect = (effect, deps, delay) => {
  useEffect(() => {
    const handler = setTimeout(() => effect(), delay);

    return () => clearTimeout(handler);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...(deps || []), delay]);
};

export const useUnload = (fn) => {
  const cb = useRef(fn);

  useEffect(() => {
    cb.current = fn;
  }, [fn]);

  useEffect(() => {
    const onUnload = (...args) => cb.current?.(...args);

    window.addEventListener('beforeunload', onUnload);

    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);
};

export const useKeyPress = (
  keyCode: string,
  func: (e?: any) => void,
  condition = true,
  isInModal?: boolean
) => {
  const { isModalOpen } = useModal();

  const handleEnter = useCallback(
    (e: KeyboardEvent) => {
      if (isModalOpen && !isInModal) return;
      if (e.key === keyCode && condition) {
        func(e);
      }
    },
    [condition, func, isInModal, isModalOpen, keyCode]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEnter);
    return () => {
      document.removeEventListener('keydown', handleEnter);
    };
  }, [handleEnter]);
};
export const useKey = (
  keyCode: string,
  downFunc: (e?: any) => void,
  upFunc: (e?: any) => void,
  condition = true
) => {
  const handleKeyDown = useCallback(
    (e) => {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;
      if (e.key === keyCode && condition) {
        e.preventDefault();
        downFunc();
      }
    },
    [condition, downFunc, keyCode]
  );

  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === keyCode && condition) {
        e.preventDefault();

        upFunc();
      }
    },
    [condition, upFunc, keyCode]
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  useEffect(() => {
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [handleKeyUp]);
};

/**
 * Returns a promise which sleeps the current function for x amount of time.
 */
export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * Checks if the two dates are the same
 */
export const isSameDate = (date1: Date, date2: Date) =>
  date1.getDate() === date2.getDate() &&
  date1.getMonth() === date2.getMonth() &&
  date1.getFullYear() === date2.getFullYear();

export const handleIndeterminate = (value, initialValue) => {
  let newValue = value;
  switch (value) {
    case true:
      newValue = false;
      break;
    case false:
      if (initialValue === undefined) {
        newValue = undefined;
      } else {
        newValue = true;
      }
      break;
    case undefined:
      newValue = true;
  }
  return newValue;
};

/**
 * Transforms a field to normalize the location values using the document dimensions
 */
export const normalizeEntity = (entity: DocumentEntity, document: DocumentDetails) => {
  const temp = { ...entity };
  const { valueLocations, value } = temp;

  if (!document || !document.dimensions) return null;
  const docPage = document.dimensions[temp.pageNo - 1];
  if (temp.pageNo === 0) return entity;
  if (!docPage) return null;

  const normalizeLocation = (location: any, page: any) => {
    location.y2 = parseFloat((location.y2 / page.height).toFixed(5));
    location.y1 = parseFloat((location.y1 / page.height).toFixed(5));
    location.x1 = parseFloat((location.x1 / page.width).toFixed(5));
    location.x2 = parseFloat((location.x2 / page.width).toFixed(5));
    return location;
  };

  const normalizeCellLocations = (cells: any) => {
    return cells.map((cellLoc: any) => normalizeLocation({ ...cellLoc }, docPage));
  };

  if (typeof value === 'object' && value && value['columns']) {
    const workValue = cloneDeep(value) as EntityTable;
    const workObject = Object.entries(workValue['columns']);
    workObject.forEach(([k, v]) => {
      const col = cloneDeep(v);
      if (col.valueLocations[0]) {
        normalizeLocation(col.valueLocations[0], docPage);
      }
      if (col.cells && Object.entries(col.cells).length > 0) {
        normalizeCellLocations(Object.values(col.cells));
      }
      workValue.columns[k] = col;
    });
    temp.value = workValue;
  }
  if (typeof value === 'object' && value && value['complex']) {
    const workValue = cloneDeep(value) as EntityComplex;
    const workObject = Object.entries(workValue.complex);
    workObject.forEach(([k, v]) => {
      const item = cloneDeep(v);
      if (item.valueLocations[0]) {
        normalizeLocation(item.valueLocations[0], docPage);
      }
      workValue.complex[k] = item;
    });
    temp.value = workValue;
  }

  if (valueLocations && valueLocations.length > 0) {
    temp.valueLocations = valueLocations.map((valueLoc: any) => normalizeLocation({ ...valueLoc }, docPage));
  }

  return temp;
};

/**
 * Transforms an field to `unnormalize` the location values
 */
export const unNormalizeEntity = (entity: DocumentEntity, document: DocumentDetails) => {
  const temp = { ...entity };
  const { valueLocations, value } = temp;
  const docPage = document.dimensions[temp.pageNo - 1];

  const adjustLocation = (location: any, page: any) => {
    location.y2 = parseFloat((location.y2 * page.height).toFixed(5));
    location.y1 = parseFloat((location.y1 * page.height).toFixed(5));
    location.x1 = parseFloat((location.x1 * page.width).toFixed(5));
    location.x2 = parseFloat((location.x2 * page.width).toFixed(5));
    return location;
  };

  const adjustCellLocations = (cells: any) => {
    return cells.map((cellLoc: any) => adjustLocation({ ...cellLoc }, docPage));
  };

  // Check if field value is an object (table)
  if (typeof value === 'object' && value['columns']) {
    const workValue = cloneDeep(value) as EntityTable;
    const workObject = Object.entries(workValue.columns);
    workObject.forEach(([k, v]) => {
      const col = cloneDeep(v);
      if (col.valueLocations[0]) {
        adjustLocation(col.valueLocations[0], docPage);
      }
      if (col.cells && Object.entries(col.cells).length > 0) {
        adjustCellLocations(Object.values(col.cells));
      }
      workValue.columns[k] = col;
    });
    temp.value = workValue;
  }
  if (typeof value === 'object' && value['complex']) {
    const workValue = cloneDeep(value) as EntityComplex;
    const workObject = Object.entries(workValue.complex);
    workObject.forEach(([k, v]) => {
      const item = cloneDeep(v);
      if (item.valueLocations && item.valueLocations[0]) {
        adjustLocation(item.valueLocations[0], docPage);
      }
      workValue.complex[k] = item;
    });
    temp.value = workValue;
  }

  if (valueLocations && valueLocations.length > 0) {
    temp.valueLocations = valueLocations.map((valueLoc: any) => adjustLocation({ ...valueLoc }, docPage));
  }
  return temp;
};

/**
 * Transforms a string from camel- to snakecase
 *
 */
export const camelToSnakeCase = (str) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

/**
 * Transforms a string from snake- to camelcase
 *
 */
export const snakeToCamelCase = (str) =>
  str.replace(/([-_][a-z])/g, (group) => group.toUpperCase().replace('-', '').replace('_', ''));

/**
 * useDebounce Hook
 *
 * @remarks
 * Hook that debounces a certain value by x delay.
 */
export function useDebounce(value: any, delay: number) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
}

/**
 * Handles loading of a lazy loaded component
 *
 * @remarks
 * This function makes sure a lazy loaded component gets handled correctly when a new build is served to a user.

 */
export const retryLoad = (fn, retriesLeft = 5, interval = 1000): any => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryLoad(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const hexToRgb = (hex) =>
  hex
    .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (r, g, b) => '#' + r + r + g + g + b + b)
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16));

export const compareObjects = (obj1, obj2): Record<string, any> => {
  const result = {};
  for (const key in obj1) {
    if (obj2.hasOwnProperty(key) && !isEqual(obj1[key], obj2[key])) {
      result[key] = obj1[key];
    }
  }
  return result;
};

export const recordToListWithId = (record: Record<string, any>) => {
  return Object.entries(record).map(([key, value]) => ({
    id: key,
    ...value,
  }));
};

export const listWithIdToRecord = (list: any[]) => {
  return list.reduce((acc, curr) => {
    acc[curr.id] = snakecaseKeys(curr);
    return acc;
  }, {});
};

// Client-side pseudocode

export function generateCodeVerifier() {
  // Generate a random string
  const array = new Uint32Array(56); // for a resultant 75-char base64 string
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join('');
}

export const generateCodeChallenge = async (verifier) => {
  return crypto.subtle.digest('SHA-256', new TextEncoder().encode(verifier)).then((hash) =>
    btoa(String.fromCharCode(...new Uint8Array(hash)))
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '')
  );
};

export const demoDocTypesEN = [
  'Policy Schedule',
  'Insurance Proposal Form',
  'Certificate of Insurance',
  'Policy Wording',
  'Endorsement Certificates',
  'Renewal Notice',
  'Claim Form',
  'Claim Settlement Letter',
  'Notice of Cancellation',
  'Proof of No Claims Discount (NCD)',
  'Terms and Conditions',
  'Product Disclosure Statement',
  'Coverage Summary',
  'Premium Notice',
  'Adjustment Notice',
  'Exclusion Notice',
  'Risk Assessment Report',
  'Insurance Quotation',
  'Binder of Insurance',
  'Insurance Application',
  'Loss Run Report',
  'Underwriting Guidelines',
  'Reinsurance Treaty',
  'Actuarial Report',
  'Insurance Survey Report',
];

export const demoDocTypesNL = [
  'Schade',
  'Vrije brief',
  'Expertise',
  'Aangifte',
  'Bevestiging betaling',
  'Ontvangstmelding',
  'Productie scanning',
  'Borderel + Kwijting',
  'Polis + Bijvoegsel',
  'Afrekening',
  'Attest',
  'Productie',
  'Inschrijving',
  'Offerte en voorstel',
  'Schade scanning',
  'Medische documenten',
  'Juridische documenten',
  'Kwijting',
  'Mandaat Nadeel',
  'BTW Attest',
  'Opzeg',
];

export const demoUserNames = [
  'Bert',
  'Niels',
  'Lucas',
  'Stijn',
  'Maarten',
  'Frederic',
  'Myey',
  'Victor',
  'Jonathan',
];
