import { useModal } from '@shared/hooks/useModal';
import FocusTrap from 'focus-trap-react';
import React from 'react';
import s from './modal.module.scss';

interface Props {
  isDialog?: boolean;
  dismissible?: boolean;
  children: any;
}
const Modal: React.FC<Props> = ({ isDialog, children, dismissible = true }) => {
  const { closeModal, closeDialog, isModalOpen } = useModal();
  const handleClose = (event) => {
    if (event.target === event.currentTarget && dismissible) {
      event.preventDefault();
      if (document.activeElement?.nodeName === 'INPUT' || document.activeElement?.nodeName === 'TEXTAREA')
        return;
      if (isDialog) {
        closeDialog();
      } else {
        closeModal();
      }
    }
  };

  return (
    <FocusTrap
      focusTrapOptions={{
        fallbackFocus: () => document.activeElement as any,
      }}
    >
      <div onClick={handleClose} className={s.container}>
        <div className={s.content} id={isModalOpen ? 'modal-content' : null}>
          {children}
        </div>
      </div>
    </FocusTrap>
  );
};

export default Modal;
