import {WebhookRow} from '@shared/helpers/converters/endpoint.ts';
import {useSelector} from '@shared/store/store';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import {ReactComponent as ShieldIcon} from '@svg/shield.svg';
import clsx from 'clsx';
import React from 'react';
import AdminDataDropdown from './AdminDataDropdown';

interface Props {
  itemIndex: number;
  item: WebhookRow;
  onBlur?: VoidFunction;
  deleteRow: (index: number) => void;
  editRow: (index: number, target: 'key' | 'value' | 'valueType' | 'lock', value: any) => void;
}

const AdminWebhookRow: React.FC<Props> = ({ itemIndex, item, onBlur, editRow, deleteRow }) => {
  const webhookServerSideValues = useSelector((state) => state.admin.webhookServerSideValues);

  return (
    <div data-testid={'webhook-row'} className={se.webhook_row}>
      <div className={se.webhook_row_inner}>
        <input
          data-testid={'webhook-row-key'}
          onBlur={onBlur}
          className={clsx(se.webhook_row_key, se.input, {
            [se.input__error]: item.error?.key === 'key',
          })}
          onChange={(e) => editRow(itemIndex, 'key', e.target.value)}
          value={item.key ?? ''}
        />
        <AdminDataDropdown
          options={webhookServerSideValues}
          disabled={item.locked || item.tempLocked}
          onChange={(e) => editRow(itemIndex, 'value', e)}
          onChangeType={(e) => editRow(itemIndex, 'valueType', e)}
          item={item}
        />

        <button
          data-testid={'webhook-row-hide'}
          type={'button'}
          disabled={item.locked || (item.value.type !== 'string' && item.value.type !== '@PB_SECRET')}
          onClick={() => {
            if (item.locked) return;
            if (item.tempLocked) {
              editRow(itemIndex, 'valueType', 'string');
              editRow(itemIndex, 'lock', false);
            } else {
              editRow(itemIndex, 'valueType', '@PB_SECRET');
              editRow(itemIndex, 'lock', true);
            }
          }}
          className={clsx(se.webhook_row_button, {
            [se.webhook_row_button__active]: item.locked || item.tempLocked,
          })}
        >
          <ShieldIcon />
        </button>
        <button
          data-testid={'webhook-row-remove'}
          type={'button'}
          onClick={() => deleteRow(itemIndex)}
          className={clsx(se.webhook_row_button)}
        >
          <CrossIcon />
        </button>
      </div>
      {item.error?.msg && <div className={se.webhook_row_error}>{item.error.msg}</div>}
    </div>
  );
};

export default AdminWebhookRow;
