import Checkbox from '@components/shared/checkbox/Checkbox.tsx';
import ColorPicker from '@components/shared/color-picker/ColorPicker.tsx';
import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import { isPbxEmail, sleep } from '@shared/helpers/helpers.ts';
import { useModal } from '@shared/hooks/useModal.tsx';
import { useSelector } from '@shared/store/store.ts';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import p from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as DownloadIcon } from '@svg/download.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as ShieldIcon } from '@svg/shield.svg';
import clsx from 'clsx';
import React, { useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AdminCopyButton from '../AdminCopyButton.tsx';
import AdminInputDialog from '../AdminInputDialog.tsx';
import AdminMultiSelectDialog from '../AdminMultiSelectDialog.tsx';
import AdminSortableList from '../AdminSortableList.tsx';

type InputType =
  | 'text'
  | 'toggle'
  | 'dropdown'
  | 'url'
  | 'secret'
  | 'fetch-secret'
  | 'number'
  | 'ip'
  | 'upload'
  | 'button'
  | 'colorpicker'
  | 'list';

interface UploadOptions {
  handleUpload: (file: File) => Promise<any>;
  errorMessage: string;
  accept: string;
}
interface ButtonOptions {
  onClick: () => void;
  type: 'error' | 'normal' | 'blue';
  text: string | JSX.Element;
}
interface NumberInputOptions {
  step?: number;
  min?: number;
  max?: number;
  label: string;
}
interface ListInputOptions {
  selectedOptions?: string[];
  options?: { id: string; name: string }[];
  onChangeList: (newList: string[]) => void;
  isMultiEdit?: boolean;
}
interface FetchSecretInputOptions {
  fetchSecret: () => Promise<any>;
}
interface InputFieldProps {
  testId?: string;
  layout?: 'horizontal' | 'vertical' | 'horizontal-large' | 'vertical-large';
  label: string;
  required?: boolean;
  hidden?: boolean;
  disabled?: boolean;
  description: string | JSX.Element;
  value?: any;
  placeholder?: string;
  type: InputType;
  onChange?: (value: any) => void;
  uploadOptions?: UploadOptions;
  isPaperboxOnly?: boolean;
  isCopyField?: boolean;
  defaultDropdownOption?: DropdownOption;
  dropdownOptions?: DropdownOption[];
  buttonOptions?: ButtonOptions;
  numberInputOptions?: NumberInputOptions;
  listInputOptions?: ListInputOptions;
  fetchSecretInputOptions?: FetchSecretInputOptions;
  indeterminate?: boolean;
  children?: React.ReactNode;
}

const FormInputField: React.FC<InputFieldProps> = ({
  testId,
  label,
  required,
  description,
  value,
  placeholder,
  type,
  onChange,
  layout = 'vertical',
  hidden,
  disabled,
  isPaperboxOnly,
  isCopyField,
  defaultDropdownOption,
  dropdownOptions,
  uploadOptions,
  buttonOptions,
  numberInputOptions,
  listInputOptions,
  fetchSecretInputOptions,
  indeterminate,
  children,
}) => {
  const { t } = useTranslation();
  const userAccount = useSelector((state) => state.user.userAccount);
  const inputRef = useRef<any>();
  const [uploadErrorMessage, setUploadErrorMessage] = useState(null);
  const [uploadStep, setUploadStep] = useState(0);
  const [newFile, setNewFile] = useState<File>();

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setNewFile(e.target.files[0]);
      setUploadStep(1);
    }
  };
  const { showDialog } = useModal();

  const uploadText = useMemo(() => {
    if (uploadStep === 1 && newFile) {
      return t('admin:masterdata.fileUpload') + newFile.name;
    }
    if (uploadStep === 2) {
      return t('admin:masterdata.fileUploaded');
    }
    if (uploadStep === 3) {
      return t('admin:masterdata.fileUploading');
    }
    return t('admin:masterdata.fileSelect');
  }, [newFile, t, uploadStep]);

  const handleUploadButton = () => {
    setUploadErrorMessage(null);
    if (newFile) {
      setUploadStep(2);
      uploadOptions
        .handleUpload(newFile)
        .then(() => {
          setUploadStep(3);
          inputRef.current.value = null;
          setNewFile(null);
          sleep(3000).then(() => {
            setNewFile(null);
            setUploadStep(0);
          });
        })
        .catch((err) => {
          console.log(err);
          if (err.response?.data?.message) {
            setUploadErrorMessage(err.response.data.message);
          } else {
            setUploadErrorMessage(uploadOptions.errorMessage);
          }
          setUploadStep(0);
          setNewFile(null);
        });
    } else {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  };

  if (hidden || !userAccount || (!isPbxEmail(userAccount.email) && isPaperboxOnly)) return null;

  return (
    <>
      <div
        className={clsx(
          s.item,
          { [s.item__horizontal]: layout === 'horizontal' },
          { [s.item__vertical]: listInputOptions },
          { [s.item__parent]: children }
        )}
      >
        <div className={s.item_head}>
          <div className={s.item_text}>
            <h4>
              {label} {required && '*'}
              {isPaperboxOnly && <ShieldIcon />}
            </h4>
            <p>{description}</p>
          </div>
          {listInputOptions && (
            <button
              disabled={disabled}
              type="button"
              className={sr.action}
              onClick={() => {
                if (listInputOptions.options) {
                  showDialog(
                    <AdminMultiSelectDialog
                      handleCheckTypes={(updatedList) => {
                        listInputOptions.onChangeList(updatedList.map((e) => e.id));
                      }}
                      selectedTypes={listInputOptions.selectedOptions?.map((e) => ({ id: e })) ?? []}
                      title={'test'}
                      description={'test'}
                      detailedList={listInputOptions.options}
                    />
                  );
                } else {
                  showDialog(
                    <AdminInputDialog
                      currentList={value}
                      handleAddItem={(item: string) => {
                        listInputOptions.onChangeList([...value, item]);
                      }}
                    />
                  );
                }
              }}
            >
              <PlusIcon style={{ width: 10 }} />
            </button>
          )}
        </div>
        {type !== 'upload' && type !== 'list' && (
          <div className={s.item_action}>
            <div className={clsx({ [s.input_wrapper]: layout !== 'horizontal' })}>
              {type === 'toggle' && (
                <Checkbox
                  testId={testId}
                  disabled={disabled}
                  indeterminate={indeterminate}
                  checked={value}
                  onClick={() => onChange(!value)}
                />
              )}
              {type === 'button' && buttonOptions && (
                <button
                  data-testid={testId}
                  disabled={disabled}
                  type={'button'}
                  onClick={buttonOptions.onClick}
                  className={clsx(
                    s.top_button,
                    { [s.top_button__delete]: buttonOptions?.type === 'error' },
                    { [s.top_button__blue]: buttonOptions?.type === 'blue' }
                  )}
                >
                  {buttonOptions?.text}
                </button>
              )}
              {type == 'colorpicker' && <ColorPicker activeColor={value} onChange={(e) => onChange(e)} />}
              {(type === 'text' || type === 'url' || type === 'secret' || type === 'fetch-secret') && (
                <>
                  {layout.includes('large') && (
                    <textarea
                      data-testid={testId}
                      data-form-type={'other'}
                      disabled={disabled || isCopyField}
                      required={required}
                      className={clsx(s.input, s.input__large, { [s.input__copy]: isCopyField })}
                      placeholder={placeholder}
                      value={value ?? ''}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}
                  {!layout.includes('large') && (
                    <input
                      data-testid={testId}
                      data-form-type={'other'}
                      disabled={disabled || isCopyField}
                      required={required}
                      className={clsx(s.input, { [s.input__copy]: isCopyField })}
                      placeholder={placeholder}
                      type={type === 'secret' && value === 'existingValue' ? 'password' : type}
                      value={value ?? ''}
                      onChange={(e) => onChange(e.target.value)}
                    />
                  )}

                  {isCopyField && <AdminCopyButton valueToCopy={value} />}
                  {type === 'secret' && (
                    <div className={s.input__secret_wrapper}>
                      <ShieldIcon style={value === 'existingValue' ? { color: '#0085FF' } : {}} />
                      <button
                        type={'button'}
                        onClick={() => onChange('')}
                        className={clsx(s.webhook_row_button)}
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  )}
                  {type === 'fetch-secret' && !isCopyField && (
                    <div className={s.input__secret_wrapper}>
                      <button
                        type={'button'}
                        onClick={fetchSecretInputOptions?.fetchSecret}
                        className={clsx(s.webhook_row_button)}
                      >
                        <DownloadIcon style={{ width: 16 }} />
                      </button>
                    </div>
                  )}
                </>
              )}
              {type === 'number' && (
                <>
                  <input
                    data-testid={testId}
                    data-form-type={'other'}
                    disabled={disabled}
                    required={required}
                    placeholder={placeholder}
                    type={'number'}
                    className={clsx(s.input, s.input__number, {
                      [s.input__percent]: numberInputOptions?.label,
                    })}
                    step={numberInputOptions?.step}
                    min={numberInputOptions?.min}
                    max={numberInputOptions?.max}
                    value={value ?? ''}
                    onChange={(e) => onChange(e.target.value)}
                  />
                  {numberInputOptions && <label className={s.input_label}>{numberInputOptions?.label}</label>}
                </>
              )}
              {type === 'ip' && (
                <input
                  data-testid={testId}
                  data-form-type={'other'}
                  disabled={disabled}
                  required={required}
                  placeholder={placeholder}
                  type={'text'}
                  className={s.input}
                  pattern="^(?:[0-9]{1,3}\.){3}[0-9]{1,3}$"
                  title="IP address"
                  value={value ?? ''}
                  onChange={(e) => onChange(e.target.value)}
                />
              )}

              {type === 'dropdown' && dropdownOptions?.length > 0 && (
                <StyledSelect
                  required={required}
                  isDisabled={disabled}
                  testId={testId}
                  onChange={(val) => {
                    if (val !== dropdownOptions?.find((e) => e.value === value?.value)) {
                      onChange(val);
                    }
                  }}
                  defaultValue={defaultDropdownOption}
                  options={dropdownOptions}
                  value={dropdownOptions?.find((e) => e.value === value?.value)}
                />
              )}
            </div>
          </div>
        )}
        {type === 'list' && (
          <div className={s.item_action}>
            <div className={s.item_fields}>
              {/*<button*/}
              {/*  disabled={disabled}*/}
              {/*  type="button"*/}
              {/*  className={sr.action}*/}
              {/*  onClick={() => {*/}
              {/*    if (listInputOptions.options) {*/}
              {/*      showDialog(*/}
              {/*        <AdminMultiSelectDialog*/}
              {/*          handleCheckTypes={(updatedList) => {*/}
              {/*            listInputOptions.onChangeList(updatedList.map((e) => e.id));*/}
              {/*          }}*/}
              {/*          selectedTypes={listInputOptions.selectedOptions?.map((e) => ({ id: e })) ?? []}*/}
              {/*          title={'test'}*/}
              {/*          description={'test'}*/}
              {/*          detailedList={listInputOptions.options}*/}
              {/*        />*/}
              {/*      );*/}
              {/*    } else {*/}
              {/*      showDialog(*/}
              {/*        <AdminInputDialog*/}
              {/*          currentList={value}*/}
              {/*          handleAddItem={(item: string) => {*/}
              {/*            listInputOptions.onChangeList([...value, item]);*/}
              {/*          }}*/}
              {/*        />*/}
              {/*      );*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <PlusIcon style={{ width: 10 }} />*/}
              {/*</button>*/}
              {listInputOptions.isMultiEdit ? (
                <div className={s.item_fields}>
                  <div className={s.item_field} style={{ fontStyle: 'italic', cursor: 'default' }}>
                    Multiple Existing
                  </div>
                </div>
              ) : (
                <AdminSortableList
                  detailedItems={listInputOptions.options ?? value?.map((e) => ({ id: e, name: e })) ?? []}
                  items={value}
                  setItems={listInputOptions.onChangeList}
                  handleRemoveType={(e) => {
                    listInputOptions.onChangeList([...value].filter((al) => al !== e));
                  }}
                />
              )}
            </div>
          </div>
        )}

        {type === 'upload' && (
          <div className={clsx(s.item_action, s.item_action_flex)}>
            <span className={p.body_header_action_error}>{uploadErrorMessage}</span>
            <input
              data-testid={testId + '-input'}
              ref={inputRef}
              onChange={handleFileSelect}
              className={s.item_file_input}
              accept={uploadOptions.accept}
              type="file"
            />
            <button
              onClick={handleUploadButton}
              data-testid={testId}
              type={'button'}
              disabled={uploadStep === 2}
              className={clsx(p.button, s.top_button__blue)}
            >
              {uploadText}
            </button>
            {newFile && uploadStep === 1 && (
              <button
                type={'button'}
                onClick={() => {
                  inputRef.current.value = null;
                  setUploadStep(0);
                  setNewFile(null);
                }}
                className={clsx(p.button, p.button__alt)}
              >
                <CrossIcon />
              </button>
            )}
          </div>
        )}
      </div>
      {children && <div className={s.item}>{children}</div>}
    </>
  );
};

export default FormInputField;
