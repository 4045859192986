import { useNotification } from '@shared/hooks/useNotificationBar.tsx';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import se from '@shared/styles/component/admin/admin-section.module.scss';
import { ReactComponent as CopyIcon } from '@svg/copy-icon.svg';
import { ReactComponent as DividerVertical } from '@svg/divider-vertical.svg';
import { ReactComponent as EditIcon } from '@svg/edit-icon.svg';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import { ReactComponent as TrashIcon } from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

interface SectionSearchProps {
  onChange: (value: string) => void;
  placeholder: string;
}
interface SectionAddProps {
  onClick: () => void;
  label: string;
  icon?: React.ReactNode;
  testId?: string;
}
interface SectionSelectProps {
  setSelectedValues: (values: string[]) => void;
  selectedValues: string[];
  allValues: string[];
  handleDelete?: () => void;
  handleEdit?: () => void;
}
interface SectionCopyProps {
  copyValues: string[];
}

interface Props {
  title?: string;
  children: React.ReactNode;
  hidden?: boolean;
  scroll?: boolean;
  search?: SectionSearchProps;
  select?: SectionSelectProps;
  add?: SectionAddProps;
  copy?: SectionCopyProps;
  noStyle?: boolean;
}

const FormSection: React.FC<Props> = ({
  title,
  noStyle,
  hidden,
  scroll,
  select,
  children,
  search,
  add,
  copy,
}) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const sectionSelectedValues = useMemo(() => {
    if (select) {
      return select.allValues.filter((e) => select.selectedValues.includes(e));
    }
    return [];
  }, [select]);

  if (hidden) return null;
  return (
    <>
      {title && (
        <div className={se.section_title}>
          <h3>{title}</h3>
          {(search || add || select) && (
            <div style={{ display: 'flex', gap: 8 }}>
              {select && select.allValues?.length > 0 && (
                <>
                  {(select.handleEdit || select.handleDelete) && sectionSelectedValues.length > 0 && (
                    <div className={clsx(s.top_button, s.top_button__alt)}>
                      <span>{select.selectedValues.length} Selected</span>
                      {select.handleEdit && (
                        <>
                          <DividerVertical />

                          <button type={'button'} className={s.action} onClick={select.handleEdit}>
                            <EditIcon style={{ width: 13, height: 'auto' }} />
                          </button>
                        </>
                      )}
                      {select.handleDelete && (
                        <>
                          <DividerVertical />
                          <button
                            type={'button'}
                            className={clsx(s.action, s.action__delete)}
                            onClick={select.handleDelete}
                          >
                            <TrashIcon style={{ width: 14, height: 'auto' }} />
                          </button>
                        </>
                      )}
                      {copy && (
                        <>
                          <DividerVertical />
                          <button
                            type={'button'}
                            className={s.action}
                            onClick={() => {
                              navigator.clipboard.writeText(select.selectedValues.join('\n'));
                              showNotification("Copied list of selected ID's to clipboard", 'success');
                            }}
                          >
                            <CopyIcon style={{ width: 14, height: 'auto' }} />
                          </button>
                        </>
                      )}
                    </div>
                  )}

                  <button
                    onClick={() => {
                      if (sectionSelectedValues?.length === select.allValues?.length) {
                        select.setSelectedValues([]);
                      } else {
                        select.setSelectedValues(select.allValues);
                      }
                    }}
                    className={clsx(s.top_button)}
                  >
                    {sectionSelectedValues?.length === select.allValues?.length
                      ? t('admin:multiSelect.deselect')
                      : t('admin:multiSelect.select')}
                  </button>
                </>
              )}
              {copy && (!select || select.selectedValues.length === 0) && (
                <button
                  data-testid={add.testId}
                  type={'button'}
                  onClick={() => {
                    navigator.clipboard.writeText(copy.copyValues.join('\n'));
                    showNotification(t('home:notifications.copied'), 'success');
                  }}
                  className={se.top_button}
                >
                  <CopyIcon style={{ width: 14, height: 'auto' }} />
                </button>
              )}
              {search && (
                <div className={se.search_wrapper}>
                  <input
                    onChange={(e) => search.onChange(e.target.value)}
                    placeholder={search.placeholder}
                    className={se.search}
                    type="text"
                  />
                  <SearchIcon className={se.search_icon} />
                </div>
              )}

              {add && (
                <button
                  data-testid={add.testId}
                  type={'button'}
                  onClick={add.onClick}
                  className={se.top_button}
                >
                  {add.icon || <PlusIcon />}
                  {add.label}
                </button>
              )}
            </div>
          )}
        </div>
      )}
      <div
        className={clsx(se.section, { [se.section__scroll_small]: scroll, [se.section__no_style]: noStyle })}
      >
        {children}
      </div>
    </>
  );
};

export default FormSection;
