import { extendedSearch, globalFuseOptions, usePrevious } from '@shared/helpers/helpers';
import { useModal } from '@shared/hooks/useModal';
import { ReactComponent as ChevronDownIcon } from '@svg/chevron-down.svg';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as StarIcon } from '@svg/star-icon.svg';
import Fuse from 'fuse.js';
import { isEqual } from 'lodash';
import React, { CSSProperties, useEffect, useMemo, useState } from 'react';
import Select, {
  components,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  IndicatorSeparatorProps,
  InputProps,
  MenuListProps,
  OptionProps,
  Props,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import s from './dropdown.module.scss';

const fuseOptions = {
  ...globalFuseOptions,
  keys: ['label'],
};

export type DropdownOption = {
  label: string;
  value: any;
  tag?: DropdownTag;
  color?: string;
  score?: number;
  isHidden?: boolean;
  metadata?: any;
};

export type DropdownTag = {
  name: string;
  value: any;
  isMinimal?: boolean;
};

const CustomCross = (props) => {
  const { getStyles } = props;
  return (
    <div {...props.innerProps} style={getStyles('clearIndicator', props) as CSSProperties}>
      <CrossIcon />
    </div>
  );
};
const CustomMenuList: React.ComponentType<MenuListProps<any>> = (props) => {
  return (
    <div>
      <components.MenuList {...props} className={'MenuList'}>
        {props.children}
      </components.MenuList>
    </div>
  );
};
const CustomControl: React.ComponentType<ControlProps<any>> = ({ children, ...props }) => {
  return (
    <components.Control
      {...props}
      className={props.selectProps.className ?? ''}
      data-testid={'dropdown-control'}
    >
      {children}
    </components.Control>
  );
};
const CustomChevron: React.ComponentType<DropdownIndicatorProps<any>> = (props) => {
  const { getStyles } = props;
  return (
    <div {...props.innerProps} style={getStyles('dropdownIndicator', props) as CSSProperties}>
      <ChevronDownIcon />
    </div>
  );
};
const CustomSeparator: React.ComponentType<IndicatorSeparatorProps<any>> = () => {
  return <div style={{ height: 35 }} />;
};
const CustomInput: React.ComponentType<InputProps<any>> = ({ children, ...props }) => {
  return (
    <components.Input type={'search'} autoComplete={'new-password'} {...props} className={s.input_override} />
  );
};
const CustomSingleValue: React.ComponentType<SingleValueProps<any>> = (props) => {
  const { getStyles, innerProps, getValue } = props;
  const val = getValue() as any;
  let tag;
  let color;
  if (val.length !== 0) {
    tag = val[0].tag;
    color = val[0].color;
  }
  return (
    <div {...innerProps} style={getStyles('singleValue', props) as CSSProperties}>
      {props.children}
      {tag && color && (
        <div className={s.label} style={{ backgroundColor: color ?? null }}>
          {tag.name}
        </div>
      )}
    </div>
  );
};
const CustomOption: React.ComponentType<OptionProps<any>> = (props) => {
  const { children, data } = props;
  const tag = data['tag'];
  const color = data['color'];
  const score = data['score'];

  return (
    <components.Option className={s.option} {...props}>
      <span data-testid={'dropdown-option'} className={s.text}>
        {children}
      </span>

      {tag && (
        <div style={{ backgroundColor: color ? color : '#0085FF' }} className={s.label}>
          {/*{tag.name}*/}
          {!tag.isMinimal ? tag.name : ''}
        </div>
      )}
      {score && score > 0.3 ? (
        <StarIcon
          style={{
            marginLeft: 'auto',
            minWidth: 12,
            opacity: score * 2,
          }}
        />
      ) : null}
    </components.Option>
  );
};

interface MyProps {
  testId?: string;
  options: DropdownOption[];
  style?: CSSProperties;
}
const StyledSelect: React.FC<Props & MyProps> = ({
  options,
  style,
  defaultValue,
  onChange,
  testId,
  ...rest
}) => {
  const unGroupedOptions = useMemo(() => {
    return options.reduce(
      (arr: any, item) => (item['options'] ? [...arr, ...item['options']] : [...arr, item]),
      []
    ) as DropdownOption[];
  }, [options]);

  const fuse = new Fuse(unGroupedOptions ?? [], fuseOptions);
  const { isModalOpen } = useModal();
  const [searchOutputOptions, setSearchOutputOptions] = useState(null);

  useEffect(() => {
    setSearchOutputOptions(null);
  }, [options]);

  const customFilterOption = (option) => {
    return !option.data.isHidden;
  };

  const flattenedOpts = useMemo(() => {
    const o = options as any[];
    const opts = [];
    o.forEach((op) => {
      if (op.options) {
        op.options.forEach((subOp) => opts.push(subOp));
      } else {
        opts.push(op);
      }
    });
    return opts;
  }, [options]);

  const prevOptions = usePrevious(flattenedOpts);

  useEffect(() => {
    //This code handles going to the next available dropdown option when the current one become unavailable/hidden
    const val = rest.value as DropdownOption;
    const opts = flattenedOpts;
    if (opts === prevOptions) return;
    const visibleOptions = opts.filter((e) => !e.isHidden);
    let newValue = null;
    if (val == null && visibleOptions.length > 0 && !rest.isMulti) {
      newValue = opts[0];
    } else if (val && opts.length > 0) {
      const index = opts.findIndex(
        (it) =>
          isEqual(it.value, val.value) &&
          it.label === val.label &&
          (it.tag || val.tag ? isEqual(it.tag, val.tag) : true)
      );
      if (index === -1) {
        if (!opts[0].isHidden) newValue = opts[0];
        if (visibleOptions[0]) newValue = visibleOptions[0];
      } else if (opts[index]?.isHidden) {
        if (index + 1 >= opts.length) {
          newValue = visibleOptions[0];
        } else if (visibleOptions.length !== 0) {
          const visibleSlice = opts.slice(index).filter((e) => !e.isHidden);
          newValue = visibleSlice.length > 0 ? visibleSlice[0] : visibleOptions[0];
        }
      } else return;
    } else return;
    onChange(newValue, { option: newValue, action: 'select-option' });
  }, [rest.value, onChange, flattenedOpts, prevOptions, rest.isMulti]);

  useEffect(() => {
    if (!rest.value && defaultValue) {
      const val = defaultValue as any;
      const visibleOptions = flattenedOpts.filter((e) => !e.isHidden);
      const index = visibleOptions.findIndex(
        (it) => it.value === val.value && it.label === val.label && isEqual(it.tag, val.tag)
      );
      if (index !== -1) {
        onChange(defaultValue, { option: defaultValue, action: 'select-option' });
      }
    }
  }, [defaultValue, flattenedOpts, onChange, rest.value]);

  const customStyles: StylesConfig<unknown, boolean, GroupBase<unknown>> = {
    container: (provided) => {
      return { ...provided, width: '100%', flexGrow: 1, flexBasis: 350, display: 'flex' };
    },
    control: (provided, state) => {
      return {
        ...provided,
        minHeight: 35,
        maxHeight: 35,
        width: '100%',
        border: '1px solid rgba(68, 79, 107, 0.2)',
        boxShadow: state.isFocused ? '0 0 1px 2px rgba(0, 133, 255, 0.3)' : null,
        borderRadius: 7,
        '&:hover': {
          borderColor: 'rgba(68, 79, 107, 0.2)',
        },
        ...style,
      };
    },
    input: (provided) => {
      return {
        ...provided,
        cursor: 'text',
        padding: 0,
        margin: 0,
        background: 'none !important',
      };
    },
    valueContainer: (provided) => {
      return {
        ...provided,
        cursor: 'text',
        marginTop: 1,
        gap: rest.isMulti ? null : 100,
        fontSize: 14,
        fontWeight: 500,
        marginLeft: 5,
        div: {
          overflow: 'visible !important',
        },
      };
    },
    multiValueLabel: (provided) => {
      return {
        ...provided,
        color: '#0085FF',
        outline: 'none',
        backgroundColor: '#E9EFFF',
        paddingLeft: 10,
      };
    },
    clearIndicator: (provided) => {
      return {
        ...provided,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        color: 'black',
      };
    },
    dropdownIndicator: (provided, state) => {
      return {
        ...provided,
        padding: 0,
        marginRight: 5,
        cursor: 'pointer',
        transform: !state.isFocused ? 'rotate(180deg)' : null,
      };
    },

    multiValueRemove: (provided) => {
      return {
        ...provided,
        cursor: 'pointer',
        color: '#0085FF',
        backgroundColor: '#E9EFFF',
        paddingRight: 5,
      };
    },
    menuPortal: (provided) => {
      return { ...provided, zIndex: 9999 };
    },

    menu: (provided) => {
      return {
        ...provided,
        borderRadius: 7,
        padding: 0,
        zIndex: 1000000,
      };
    },
    menuList: (provided) => {
      return { ...provided, padding: 0 };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        gap: 10,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      };
    },
    option: (provided, state) => {
      const { isFocused, isSelected } = state;
      return {
        ...provided,
        display: 'flex',
        margin: 5,
        paddingInline: 10,
        paddingBlock: 7,
        width: 'calc(100% - 10px)',
        borderRadius: 7,
        cursor: 'pointer',
        backgroundColor: isFocused ? '#E9EFFF' : isSelected ? 'white' : provided.backgroundColor,
        color: isFocused || isSelected ? '#0085FF' : provided.color,
        ':hover': {
          color: '#0085FF',
          background: '#E9EFFF',
        },
      };
    },
  };

  return (
    <div
      id={'scrollContainer'}
      style={{ display: 'flex', flexGrow: 1, flexBasis: 250 }}
      data-testid={testId ?? 'dropdown-container'}
    >
      <Select
        placeholder={'Select a value'}
        data-lpignore="true"
        isOptionSelected={(option, selectValue) => selectValue.some((i) => isEqual(i, option))}
        theme={(theme) => {
          return {
            ...theme,
            colors: {
              ...theme.colors,
              primary: '#0085FF',
              primary75: 'rgba(0,133,255,0.75)',
              primary50: 'rgba(0,133,255,0.50)',
              primary25: 'rgba(0,133,255,0.25)',
            },
          };
        }}
        menuPosition={'fixed'}
        menuPortalTarget={isModalOpen ? document.getElementById('modal-content') : document.body}
        filterOption={customFilterOption}
        components={{
          Option: CustomOption,
          Control: CustomControl,
          SingleValue: CustomSingleValue,
          IndicatorSeparator: CustomSeparator,
          MenuList: CustomMenuList,
          ClearIndicator: CustomCross,
          DropdownIndicator: CustomChevron,
          Input: CustomInput,
        }}
        onInputChange={(newValue) => {
          let val = null;
          if (newValue !== '') {
            val = extendedSearch(newValue, fuse, 'label');
          }
          setSearchOutputOptions(val);
        }}
        styles={customStyles}
        blurInputOnSelect
        closeMenuOnSelect
        closeMenuOnScroll={(e) => !(e.target as HTMLElement).className?.includes('MenuList')}
        onChange={onChange}
        options={searchOutputOptions ?? options}
        {...rest}
      />
    </div>
  );
};

export default StyledSelect;
