import AuthButton from '@components/auth/methods/AuthButton';
import si from '@components/shared/input/input.module.scss';
import '@components/shared/input/input-phone.scss';
import { TwoFactorCodeInput } from '@components/two-factor/TwoFactorCodeInput';
import Recaptcha from '@components/auth/helpers/Recaptcha';
import { useModal } from '@shared/hooks/useModal';
import { auth } from '@shared/store/setup/firebase-setup';
import s from '@shared/styles/component/auth/auth.module.scss';
import { ReactComponent as PaperboxLogo } from '@svg/paperbox-logo.svg';
import clsx from 'clsx';
import { multiFactor, PhoneAuthProvider, PhoneMultiFactorGenerator } from 'firebase/auth';
import React, { useCallback, useState } from 'react';
import PhoneInput from 'react-phone-input-2';

const AuthMFA: React.FC = () => {
  const { closeModal } = useModal();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [smsCode, setSmsCode] = useState('');
  const [verificationId, setVerificationId] = useState('');
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const enrollUser = useCallback(
    async (e) => {
      e.preventDefault();
      setIsLoading(true);
      const cred = PhoneAuthProvider.credential(verificationId, smsCode);
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
      const multiFactorUser = multiFactor(auth.currentUser);
      multiFactorUser
        .enroll(multiFactorAssertion, 'PhoneNumber')
        .then(async () => {
          closeModal();
        })
        .catch((err) => {
          setError(err.code);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [closeModal, smsCode, verificationId]
  );

  const sendCode = async (e) => {
    e.preventDefault();
    const multiFactorUser = multiFactor(auth.currentUser);
    const multiFactorSession = await multiFactorUser.getSession();
    const phoneInfoOptions = {
      phoneNumber: `+${phoneNumber}`,
      session: multiFactorSession,
    };
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    phoneAuthProvider
      .verifyPhoneNumber(phoneInfoOptions, window['recaptchaVerifier'])
      .then(function (verificationId) {
        setVerificationId(verificationId);
      });
  };

  return (
    <div className={clsx(s.card, s.card__small)}>
      <PaperboxLogo className={s.logo} />
      <Recaptcha />
      {!verificationId && (
        <>
          <h2 className={s.title}>Please enter your phone number.</h2>

          <PhoneInput
            containerStyle={{ marginBottom: 40, marginTop: -10, height: 50 }}
            inputClass={si.input}
            specialLabel={''}
            country={'be'}
            inputProps={{ required: true, autoFocus: true }}
            value={phoneNumber}
            onChange={(phoneNumber) => setPhoneNumber(phoneNumber)}
          />
          <button
            className={clsx(s.button, s.button_main, s.button_continue, s.button_continue_active)}
            onClick={sendCode}
          >
            Send SMS
          </button>
        </>
      )}
      {verificationId && (
        <>
          <h2 className={s.title}>Please enter your 2FA Code</h2>
          <div className={s.mfa}>
            <TwoFactorCodeInput setValue={setSmsCode} error={error} />
          </div>
          <AuthButton onClick={enrollUser} isActive={smsCode.length === 6} isLoading={isLoading}>
            Confirm
          </AuthButton>
        </>
      )}
    </div>
  );
};

export default AuthMFA;
