import s from '@shared/styles/component/admin/admin-pages/admin-page.module.scss';
import { DotPulse } from '@uiball/loaders';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  title: string;
  saving: boolean;
  hasChanges: boolean;
  errorMessage?: string;
  buttonText?: string;
}

const FormBodyHeader: React.FC<Props> = ({ title, saving, hasChanges, errorMessage, buttonText }) => {
  const { t } = useTranslation();
  return (
    <div className={s.body_header}>
      <h2>{title}</h2>
      <div className={s.body_header_action}>
        {errorMessage && <span className={s.body_header_action_error}>{errorMessage}</span>}
        <button
          data-testid={'header-save'}
          type={'submit'}
          className={s.button}
          disabled={!hasChanges && !saving}
        >
          {!saving ? (
            <span>{buttonText ?? t('admin:connectors.save')}</span>
          ) : (
            <>
              <DotPulse color={'white'} size={14} />
              <span>{t('admin:connectors.saving')}</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default FormBodyHeader;
