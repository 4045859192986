import { MasterDataMapping } from '@shared/models/document.ts';
import camelcaseKeys from 'camelcase-keys';
import snakecaseKeys from 'snakecase-keys';

export interface IRawMasterdataTable {
  id?: string;
  name: string;
  provider_id: string;
  lookup: boolean;
  boost: number;
  mapping: any;
}
export interface IRawMasterdataVersion {
  id?: string;
  status: string;
  table_id: string;
  mapping: any;
  upload_time: any; // Firestore.timestamp
  total_count?: number;
}

export interface IClientMasterdata {
  id?: string;
  name: string;
  providerId?: string;
  lookup: boolean;
  boost?: number;
  uploadTime?: Date;
  mapping?: MasterDataMapping[];
  totalCount?: number;
}

export const masterdataRawToClient = (
  rawTable: IRawMasterdataTable,
  rawVersions: IRawMasterdataVersion[]
): IClientMasterdata => {
  const latestVersion = [...rawVersions]
    .sort((a, b) => b.upload_time.toDate() - a.upload_time.toDate())
    .find((e) => e.table_id === rawTable.id);
  const latestMapping = latestVersion?.mapping;
  let mapping = [];
  if (latestMapping) {
    mapping = Object.entries(latestMapping)
      .map(([k, v]) => {
        return camelcaseKeys({
          id: k,
          ...(v as any),
        });
      })
      .sort((a, b) => {
        return a.id.localeCompare(b.id);
      });
  }

  return {
    id: rawTable.id,
    name: rawTable.name,
    providerId: rawTable.provider_id,
    lookup: rawTable.lookup ?? true,
    boost: rawTable.boost,
    mapping: mapping,
    uploadTime: latestVersion?.upload_time.toDate(),
    totalCount: latestVersion?.total_count,
  };
};

export const masterdataClientToRaw = (client: IClientMasterdata): IRawMasterdataTable => {
  const mapping = snakecaseKeys(client.mapping, { deep: true }).reduce((obj, item) => {
    const it = { ...obj, [item.id]: { ...item } };
    delete it[item.id].id;
    return it;
  }, {});
  return {
    name: client.name,
    provider_id: client.providerId,
    lookup: client.lookup,
    boost: client.boost,
    mapping: mapping,
  };
};
