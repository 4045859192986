import cloneDeep from 'lodash/cloneDeep';
import React, { CSSProperties, useEffect, useMemo, useRef, useState } from 'react';
import { useDraggable } from '@dnd-kit/core';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import useAsyncEffect from 'use-async-effect';
import { ReactComponent as PlusIcon } from '@svg/plus-icon.svg';
import { ReactComponent as ChevronDownIcon } from '@svg/chevron-down.svg';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as DragHandleDots } from '@svg/drag-handle-dots.svg';
import { ReactComponent as ComplexIcon } from '@svg/complex-icon.svg';
import { ReactComponent as ErrorIcon } from '@svg/error-icon.svg';
import { ReactComponent as ImagePlaceholder } from '@svg/image-placeholder.svg';
import { ReactComponent as ImportIcon } from '@svg/import.svg';
import { ReactComponent as TableIcon } from '@svg/tool-grid-icon.svg';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import { formatDocType, isPbxEmail, useKeyPress } from '@shared/helpers/helpers';
import useOutsideClick from '@shared/hooks/useOutsideClick';
import {
  ActiveEntityPair,
  DocumentDetails,
  DocumentEntity,
  EntityComplex,
  EntityTable,
} from '@shared/models/document';
import { UrlParams } from '@shared/models/generic';
import { getEntityCropThumb } from '@shared/store/documentSlice';
import { labelerSlice } from '@shared/store/labelerSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-sidebar.module.scss';
import Checkbox from '@components/shared/checkbox/Checkbox';
import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect.tsx';
import Tooltip from '@components/shared/tooltip/Tooltip';
import DocumentLabelerSidebarComplexInput from './DocumentLabelerSidebarComplexInput.tsx';

interface Props {
  entity: DocumentEntity;
  handleFieldSelect: (entityPair: ActiveEntityPair) => void;
  documentDetails: DocumentDetails;
  handleFieldDelete: (entityPair: ActiveEntityPair, event?: any) => void;
  handleFieldApprove?: (entity: DocumentEntity, parentId?: string) => void;
  handleFieldEdit: (entity: DocumentEntity, changes: string | boolean | EntityTable | EntityComplex) => void;
  isSuggestion?: boolean;
  thumb?: string;
  complexParent?: DocumentEntity;
}

const DocumentLabelerSidebarRow: React.FC<Props> = ({
  entity,
  handleFieldSelect,
  handleFieldDelete,
  handleFieldApprove,
  handleFieldEdit,
  documentDetails,
  isSuggestion = false,
  thumb,
  complexParent,
}) => {
  const { inboxId, docId }: UrlParams = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();

  const userAccount = useSelector((state) => state.user.userAccount);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);
  const activeEntityPair = useSelector((state) => state.labeler.activeEntityPair);
  const tempEntity = useSelector((state) => state.labeler.tempEntity);
  const activeComplexEditId = useSelector((state) => state.labeler.activeComplexEditId);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const masterDataAvailableTypes = useSelector((state) => state.document.masterDataAvailableTypes);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const { labelingMode } = useSelector((state) => state.inbox.currentInbox.settings);

  const [thumbImage, setThumbImage] = useState(thumb);
  const [isThumbImageLoaded, setIsThumbImageLoaded] = useState(false);
  const [entityType, setEntityType] = useState<IClientFieldType>(null);
  const [value, setValue] = useState<string>();
  const [isEditing, setIsEditing] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const activeRowRef = useRef(null);
  const ref = useRef(null);

  const isMulti = useMemo(() => {
    return entity.valueLocations.length > 1;
  }, [entity]);

  const isComplex = useMemo(() => {
    return entityType?.type === 'complex';
  }, [entityType]);

  const hasExternalTable = useMemo(() => {
    if (masterDataMappings) return Object.values(masterDataMappings)?.some((el) => el.type === 'external');
    else return false;
  }, [masterDataMappings]);

  useAsyncEffect(
    async (isMounted) => {
      if (entity?.dataURL && documentDetails && isThumbImageLoaded !== true) {
        const res = (await getEntityCropThumb(docId, inboxId, entity, documentDetails)) as string;
        if (!isMounted()) return;
        setThumbImage(res);
      }
    },
    [documentDetails, inboxId, entity, isThumbImageLoaded, docId]
  );

  useEffect(() => {
    if (thumbImage) {
      setIsThumbImageLoaded(true);
    }
  }, [thumbImage]);

  const isActive = useMemo(() => {
    if (complexParent) {
      return complexParent.uuid === activeEntityPair?.entityId && activeEntityPair?.childId === entity.uuid;
    }
    return entity.uuid === activeEntityPair?.entityId;
  }, [activeEntityPair, entity.uuid, complexParent]);

  const complexParentEntityType = useMemo(() => {
    if (complexParent) {
      return entityTypes.find((et) => {
        return et.id === complexParent.type;
      });
    }
  }, [complexParent, entityTypes]);

  const hasUnmetMandatoryFields = useMemo(() => {
    if (isComplex) {
      return Object.values(entity.value['complex']).some((v: any) => {
        const childEntityType = entityType.complexDefinition.entityTypes.find((et) => et.id === v.type);
        if (childEntityType?.mandatory) {
          return !v.value; // Returns true if a mandatory field is unmet
        }
        return false;
      });
    }
    return false; // Return false if not complex or no unmet mandatory fields
  }, [isComplex, entity, entityType]);

  const isMandatory = useMemo(() => {
    if (complexParentEntityType) {
      return complexParentEntityType.complexDefinition.entityTypes.find((et) => et.id === entity.type)
        ?.mandatory;
    }
  }, [complexParentEntityType, entity]);

  const entityThreshold = useMemo(() => {
    if (docTypeSettings && documentDetails) {
      const doctype = docTypeSettings.find((dt) => dt.docTypeId === documentDetails?.docTypeId);
      if (doctype) return doctype.settings.entityThreshold;
    }
  }, [docTypeSettings, documentDetails]);

  const ocrThreshold = useMemo(() => {
    if (docTypeSettings && documentDetails) {
      const doctype = docTypeSettings.find((dt) => dt.docTypeId === documentDetails?.docTypeId);
      if (doctype) return doctype.settings.ocrThreshold;
    }
  }, [docTypeSettings, documentDetails]);

  const isUserLabeling = useMemo(() => {
    return labelingMode && !isPbxEmail(userAccount.email);
  }, [labelingMode, userAccount?.email]);

  const isLowConfidence = useMemo(() => {
    const entityConf = (entity.confidence && entity.confidence < entityThreshold) ?? false;
    const ocrConf = (entity.ocrConfidence && entity.ocrConfidence < ocrThreshold) ?? false;

    if (isUserLabeling) {
      return false;
    }
    return entityConf || ocrConf;
  }, [entity.confidence, entity.ocrConfidence, entityThreshold, isUserLabeling, ocrThreshold]);

  const confirmEdit = (overwriteValue?: string) => {
    if (!isEditing && !overwriteValue) return;
    const val = overwriteValue ? overwriteValue : value;

    setIsEditing(false);

    if (complexParent) {
      const parent = cloneDeep(complexParent);
      const parentValue = parent.value as EntityComplex;
      parentValue.complex[entity.uuid] = { ...entity, value: val };
      handleFieldEdit(parent, parentValue);
    } else {
      handleFieldEdit(entity, val);
    }
  };
  const confirmComplexAdd = (e) => {
    e?.preventDefault();
    e?.stopPropagation();
    const parent = cloneDeep(complexParent);
    const parentValue = parent.value as EntityComplex;
    const complexItem = { ...entity };
    complexItem.value = tempEntity.value;
    complexItem.valueLocations = tempEntity.valueLocations;
    complexItem.pageNo = tempEntity.pageNo ?? parent.pageNo;
    if (tempEntity.dataURL) complexItem.dataURL = tempEntity.dataURL;

    parentValue.complex[entity.uuid] = complexItem as any;
    handleFieldEdit(parent, parentValue);
    dispatch(labelerSlice.actions.setActiveMode('label'));
    dispatch(labelerSlice.actions.setActiveComplexEditId(null));
    const nextEmpty = Object.entries(parentValue.complex).find(([_, v]) => v.value == null && !v.dataURL);
    if (nextEmpty) {
      dispatch(labelerSlice.actions.setActiveComplexEditId(parent.uuid + nextEmpty[0]));
      dispatch(labelerSlice.actions.setTempEntity(null));
      dispatch(labelerSlice.actions.setActiveMode('complex'));
    }
  };

  const handleClick = () => {
    setIsClicked(true);

    if (complexParent) {
      handleFieldSelect({ entityId: complexParent.uuid, locationIndex: 0, childId: entity.uuid });
    } else if (isMulti) {
      setIsOpen((s) => !s);
      handleFieldSelect({ entityId: entity.uuid, locationIndex: 0 });
    } else {
      handleFieldSelect({ entityId: entity.uuid, locationIndex: 0 });
    }
  };

  const handleCheckboxClick = (newValue: boolean) => {
    if (complexParent) {
      const parent = cloneDeep(complexParent);
      const parentValue = parent.value as EntityComplex;
      parentValue.complex[entity.uuid] = { ...entity, value: newValue };
      handleFieldEdit(parent, parentValue);
    } else {
      handleFieldEdit(entity, newValue);
    }
  };

  useEffect(() => {
    if (entity.value && entityType) {
      if (entityType.type === 'text') {
        setValue(entity.value as string);
      }
    }
  }, [entity, entityType]);

  useEffect(() => {
    if (entityTypes) {
      const et = entityTypes.find((t) => t.id === entity.type);
      if (et) setEntityType(et);
    }
  }, [entityTypes, entity.type]);

  useOutsideClick(ref, () => {
    if (isEditing) {
      setIsEditing(false);
      confirmEdit();
    }
  });

  useKeyPress(
    'Delete',
    () => handleFieldDelete(activeEntityPair),
    isActive && !isEditing && entity.source !== 'masterdata'
  );
  // useKeyPress(
  //   'e',
  //   (e) => {
  //     if (entity.source && entity.source === 'masterdata') return;
  //
  //     if (typeof entity.value === 'boolean') {
  //       handleCheckboxClick(!entity.value);
  //     } else if (entity.dataURL === 'image') {
  //       /* empty */
  //     } else {
  //       const target = e.target as HTMLElement;
  //       if (target.nodeName !== 'INPUT') {
  //         e.preventDefault();
  //         setIsEditing(true);
  //       }
  //     }
  //   },
  //   !isEditing && isActive
  // );
  const historical = location.pathname.includes('historical');

  useEffect(() => {
    if (!isActive) setIsClicked(false);
  }, [isActive]);

  useEffect(() => {
    if (activeRowRef.current) {
      const element = activeRowRef.current as Element;
      if (!isClicked) {
        setTimeout(function () {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        }, 300);
      }
    }
  }, [activeEntityPair, isClicked, activeRowRef]);

  const activeValueOptions = useMemo(() => {
    if (entityType?.options) {
      return entityType.options.map((e) => ({ value: e.value, label: e.value }) as DropdownOption);
    } else {
      return [];
    }
  }, [entityType]);

  const { attributes, listeners, setNodeRef, isDragging, setActivatorNodeRef } = useDraggable({
    id: complexParent ? complexParent.uuid + entity.uuid : entity.uuid,
    data: { entity: entity },
    disabled: labelingMode || hasExternalTable,
  });
  const multiStyle = useMemo(() => {
    const height = (Math.floor(entity.valueLocations.length / 6) + 1) * 60;
    return { maxHeight: height + 'px' };
  }, [entity]);

  const style = {
    overflow: 'hidden',
    width: '100%',
    position: 'relative',
    zIndex: null,
    background: null,
    boxShadow: null,
    borderRadius: 0,
  } as CSSProperties;

  const isAvailable = useMemo(() => {
    return !!masterDataAvailableTypes.find((e) => e.value.id === entity.type);
  }, [entity, masterDataAvailableTypes]);

  const isEditingComplexField = useMemo(() => {
    if (complexParent) {
      return activeComplexEditId === complexParent.uuid + entity.uuid;
    } else {
      return false;
    }
  }, [complexParent, activeComplexEditId, entity]);

  const isEmptyComplexField = useMemo(() => {
    return complexParent && entity.value == null;
  }, [complexParent, entity]);

  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (isActive) setIsOpen(isActive);
  }, [isActive]);
  useEffect(() => {
    if (isEditingComplexField) setIsOpen(true);
  }, [isEditingComplexField]);
  useEffect(() => {
    if (!complexParent && activeComplexEditId?.includes(entity.uuid)) setIsOpen(true);
  }, [activeComplexEditId, complexParent, entity]);

  if (isComplex) {
    const complex = entity.value as EntityComplex;
    const complexFields = Object.keys(complex.complex || {}).map((k) => ({
      uuid: k,
      ...complex.complex[k],
    }));
    const emptyFields = complexFields.filter((e) => e.value != null);
    return (
      <div className={clsx(s.complex_wrapper, { [s.complex_wrapper__open]: isOpen })}>
        <div
          className={clsx(s.item, s.item__complex, {
            [s.item__pending]: entity.isPending,
          })}
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className={s.title}>
            {formatDocType(entityTypes?.find((t) => t?.id === entity.type)?.name || entity.type)}
          </div>
          <div className={s.item_right}>
            <div
              data-testid="sidebar-entity-value"
              className={clsx(s.field, s.field__badge, { [s.field__badge__error]: hasUnmetMandatoryFields })}
            >
              <ComplexIcon />
              {entity.value && <div>{emptyFields.length + '/' + complexFields.length}</div>}
            </div>
            <ChevronDownIcon className={s.chevron} />
          </div>
          {isOpen && (
            <CrossIcon
              onClick={(e) => {
                e.stopPropagation();
                handleFieldDelete({ entityId: entity.uuid, locationIndex: 0 });
              }}
              className={clsx(s.icon__right, s.delete, s.icon__labeling)}
            />
          )}
        </div>
        <div className={s.complex_fields}>
          {complexFields.map((f) => (
            <DocumentLabelerSidebarRow
              entity={f as any}
              documentDetails={documentDetails}
              handleFieldApprove={handleFieldApprove}
              handleFieldDelete={handleFieldDelete}
              handleFieldEdit={handleFieldEdit}
              handleFieldSelect={handleFieldSelect}
              isSuggestion={isSuggestion}
              key={entity.uuid + f.uuid}
              complexParent={entity}
              thumb={thumb}
            />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div
      key={complexParent ? complexParent.uuid + entity.uuid : entity.uuid}
      ref={setNodeRef}
      {...attributes}
      style={style}
      className={s.item_wrapper}
    >
      <form
        style={{ background: isDragging ? 'white' : null }}
        tabIndex={0}
        onSubmit={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (isEditing) confirmEdit();
        }}
        ref={isActive ? activeRowRef : null}
        data-testid={entity.isPending ? 'sidebar-entity-pending' : 'sidebar-entity'}
        onClick={handleClick}
        className={clsx(s.item, {
          [s.item__active]: isActive,
          [s.item__lowConfidence]: isLowConfidence,
          [s.item__suggestion]: isPbxEmail(userAccount?.email) && labelingMode && isSuggestion,
          [s.item__source_user]: isPbxEmail(userAccount?.email) && labelingMode && entity.source === 'user',
          [s.item__source_model]:
            isPbxEmail(userAccount?.email) && labelingMode && entity.source === 'paperbox',
          [s.item__pending]: entity.isPending,
          [s.item__multi]: isMulti,
          [s.item__complex]: isComplex,
          [s.item__complex_empty]: isEmptyComplexField,
          [s.item__editing]: isEditing,
        })}
      >
        <div ref={setActivatorNodeRef} {...listeners}>
          {masterDataMappings &&
            !isEditing &&
            isAvailable &&
            !labelingMode &&
            entity.source !== 'masterdata' &&
            !hasExternalTable && <DragHandleDots className={s.drag_handle} />}
        </div>

        {isEditing && typeof value === 'string' && (
          <input
            data-testid="sidebar-row-string-input"
            data-hj-suppress
            autoFocus
            ref={ref}
            style={{ minHeight: 60 }}
            onChange={(e) => {
              if (e.target.value !== value) setValue(e.target.value);
            }}
            className={s.editor}
            value={value}
            placeholder={value}
          />
        )}
        {!isEditing && (
          <div data-testid="sidebar-entity-type" className={s.title}>
            {formatDocType(entityTypes?.find((t) => t?.id === entity.type)?.name || entity.type)}
            {entity.source === 'masterdata' && <ImportIcon className={clsx(s.item_imported)} />}
          </div>
        )}

        {!isEditing && !isEditingComplexField && (
          <>
            {(entity.confidence || entity.ocrConfidence) && !isUserLabeling && (
              <Tooltip
                content={
                  <div className={s.tooltip_wrapper}>
                    {isLowConfidence ? (
                      <>
                        {ocrThreshold / entity.ocrConfidence > entityThreshold / entity.confidence
                          ? t('document:ocrLow')
                          : t('document:confLow')}
                      </>
                    ) : (
                      t('document:confidence')
                    )}

                    {
                      <div className={s.tooltip_bottom}>
                        {entity.ocrConfidence && (
                          <div className={s.tooltip}>
                            <span>OCR: </span>
                            <b
                              style={{
                                color: entity.ocrConfidence >= ocrThreshold ? '#91C500' : '#FF5555',
                              }}
                            >{`${(entity.ocrConfidence * 100).toFixed(2)}%`}</b>
                          </div>
                        )}

                        {entity.confidence && (
                          <div className={s.tooltip}>
                            <span>{t('document:detection')}: </span>
                            <b
                              style={{
                                color: entity.confidence >= entityThreshold ? '#91C500' : '#FF5555',
                              }}
                            >{`${(entity.confidence * 100).toFixed(2)}%`}</b>
                          </div>
                        )}
                      </div>
                    }
                  </div>
                }
              >
                <div>
                  {isLowConfidence ? (
                    <ErrorIcon data-testid={'low-confidence'} className={clsx(s.item_warning)} />
                  ) : (
                    <ErrorIcon className={clsx(s.item_info)} />
                  )}
                </div>
              </Tooltip>
            )}
            {isMulti && <div className={s.counter}>{entity.valueLocations.length}</div>}
          </>
        )}
        {isMandatory && isEmptyComplexField && !isEditing && !isEditingComplexField && (
          <div className={s.item_warning}>
            <ErrorIcon style={{ width: 12, height: 12, transform: 'translateY(0px)' }} />
          </div>
        )}

        {isEditingComplexField && (
          <>
            <DocumentLabelerSidebarComplexInput fieldType={entityType} handleConfirm={confirmComplexAdd} />
            <CrossIcon
              onClick={() => {
                dispatch(labelerSlice.actions.setActiveMode('label'));
                dispatch(labelerSlice.actions.setActiveComplexEditId(null));
              }}
              className={clsx(s.icon__right, s.delete, s.icon__labeling)}
            />
          </>
        )}
        {!isEditing && !isEditingComplexField && (
          <div data-hj-suppress className={s.item_right}>
            {entityType?.type === 'image' && entity.dataURL ? (
              <div className={s.image}>
                <div
                  className={clsx(s.image_wrapper, {
                    [s.image_wrapper__loading]: !isThumbImageLoaded,
                  })}
                >
                  {thumbImage ? <img src={thumbImage} key={'this'} alt="data" /> : <ImagePlaceholder />}
                </div>
              </div>
            ) : (
              <>
                {isEmptyComplexField && (
                  <div
                    className={s.add}
                    onClick={() => {
                      dispatch(labelerSlice.actions.setActiveComplexEditId(complexParent.uuid + entity.uuid));
                      dispatch(labelerSlice.actions.setActiveMode('complex'));
                      dispatch(
                        labelerSlice.actions.setTempEntity({
                          type: entity.type,
                          rawValue: null,
                          uuid: entity.uuid,
                          value: '',
                          pageNo: 0,
                          valueLocations: [],
                        })
                      );
                    }}
                  >
                    Toevoegen <PlusIcon />
                  </div>
                )}
                {(!complexParent || !isEmptyComplexField) && (
                  <>
                    {entityType?.type === 'boolean' && (
                      <div data-testid="sidebar-entity-value" className={clsx(s.field, s.field__boolean)}>
                        <Checkbox
                          checked={entity.value as boolean}
                          onClick={(e) => {
                            console.log(e);
                            handleCheckboxClick(!entity.value);
                          }}
                        />
                      </div>
                    )}
                    {entityType?.type === 'text' && (
                      <div
                        data-testid="sidebar-entity-value"
                        className={clsx(s.field, s.field__text, {
                          [s.field__text_master]: entity?.source === 'masterdata',
                        })}
                        style={documentDetails?.action ? { cursor: 'default' } : {}}
                        onClick={(e) => {
                          e.preventDefault();
                          if (entity?.source === 'masterdata') return;
                          if (entity.dataURL == null && !documentDetails?.action) {
                            setIsEditing(true);
                          }
                        }}
                      >
                        {(value !== '' || value != null) && typeof value == 'string' ? (
                          value.slice(0, 50)
                        ) : (
                          <div style={{ minWidth: 50 }} />
                        )}
                      </div>
                    )}
                    {entityType?.type === 'options' && (
                      <div
                        data-testid="sidebar-entity-value"
                        className={clsx(s.field, s.field__text, {
                          [s.field__text_master]: entity?.source === 'masterdata',
                        })}
                        style={documentDetails?.action ? { cursor: 'default' } : {}}
                      >
                        <StyledSelect
                          value={entity?.value ? { value: entity?.value, label: entity?.value } : null}
                          defaultValue={activeValueOptions[0]}
                          onChange={(e) => {
                            if ((e as any).value == entity?.value) return;
                            setValue((e as any).value);
                            confirmEdit((e as any).value);
                          }}
                          options={activeValueOptions}
                        />
                      </div>
                    )}
                    {entityType?.type === 'table' && (
                      <div
                        style={{ minWidth: 50 }}
                        data-testid="sidebar-entity-value"
                        className={clsx(s.field, s.field__badge)}
                      >
                        <TableIcon />
                        <span>{t('document:table')}</span>
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        )}

        {!documentDetails?.action && !(entity.source === 'masterdata') ? (
          isEditing ? (
            <button className={s.icon_button} type={'submit'} data-testid="sidebar-row-edit-confirm">
              <CheckmarkIcon />
            </button>
          ) : (
            <>
              {isEmptyComplexField ? (
                <div></div>
              ) : (
                <div
                  data-testid={'sidebar-row-delete'}
                  style={{
                    outline: 'none',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    if (complexParent) {
                      handleFieldDelete(
                        { entityId: complexParent.uuid, locationIndex: null, childId: entity.uuid },
                        e
                      );
                    } else {
                      handleFieldDelete({ entityId: entity.uuid, locationIndex: null }, e);
                    }
                  }}
                >
                  <CrossIcon
                    className={clsx(s.icon__right, s.delete, {
                      [s.icon__labeling]: labelingMode && entity.isChecked !== true,
                    })}
                  />
                </div>
              )}

              {labelingMode && entity?.isChecked !== true && (
                <div
                  data-testid={'sidebar-row-approve'}
                  style={{
                    outline: 'none',
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={(e) => {
                    e.preventDefault();
                    if (complexParent) {
                      handleFieldApprove(entity, complexParent.uuid);
                    } else {
                      handleFieldApprove(entity);
                    }
                  }}
                >
                  <CheckmarkIcon className={clsx(s.icon__right, s.check, s.icon__labeling)} />
                </div>
              )}
            </>
          )
        ) : (
          <div className={s.icon} />
        )}
      </form>

      {isMulti && (
        <div
          style={isMulti && isOpen ? multiStyle : null}
          className={clsx(s.multi_wrapper, {
            [s.multi_wrapper__historical]: historical,
            [s.multi_wrapper__visible]: isOpen,
            [s.multi_wrapper__suggestion]: isPbxEmail(userAccount?.email) && labelingMode && isSuggestion,
            [s.multi_wrapper__source_user]:
              isPbxEmail(userAccount?.email) && labelingMode && entity.source === 'user',
            [s.multi_wrapper__source_model]:
              isPbxEmail(userAccount?.email) && labelingMode && entity.source === 'paperbox',
          })}
        >
          {entity.valueLocations.map((_, i) => {
            const active =
              entity.uuid === activeEntityPair?.entityId && i === activeEntityPair?.locationIndex;
            return (
              <button
                key={i}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleFieldSelect({ entityId: entity.uuid, locationIndex: i });
                }}
                className={clsx(s.multi_item, { [s.multi_item__active]: active })}
              >
                <span>{i + 1}</span>
                {!location.pathname.includes('historical') && (
                  <CrossIcon
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFieldDelete({ entityId: entity.uuid, locationIndex: i });
                    }}
                    className={clsx(s.multi_item_cross, s.delete)}
                  />
                )}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default DocumentLabelerSidebarRow;

interface DragProps {
  draggingEntity: DocumentEntity;
  entityTypes: IClientFieldType[];
}
export const DragSidebarRow: React.FC<DragProps> = ({ draggingEntity, entityTypes }) => {
  const entityType = entityTypes.find((e) => e.id === draggingEntity.type);
  return (
    <div className={s.item_wrapper}>
      <div className={clsx(s.item, s.item__drag)}>
        <span>{entityType?.name}</span>
        <div className={s.field} style={{ maxWidth: 190 }}>
          {draggingEntity?.value ? draggingEntity?.value.toString() : ''}
        </div>
      </div>
    </div>
  );
};
