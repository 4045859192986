import Button from '@components/shared/button/Button';
import Checkbox from '@components/shared/checkbox/Checkbox';
import Modal from '@components/shared/modal/Modal';
import {extendedSearch, globalFuseOptions} from '@shared/helpers/helpers';
import {useModal} from '@shared/hooks/useModal';
import s from '@shared/styles/component/admin/admin.module.scss';
import {ReactComponent as ArrowIcon} from '@svg/chevron-down.svg';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import {ReactComponent as SearchIcon} from '@svg/search-icon.svg';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, {useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

// Fuse.js options for searching
const fuseOptions = {
  ...globalFuseOptions,
  keys: ['name'],
};

export interface MultiSelectDetailsType {
  id: string;
  name?: string;
  children?: { id: string; name: string }[];
}

export interface MultiSelectValueType {
  id: string;
  children?: string[];
}

interface Props {
  selectedTypes: MultiSelectValueType[];
  handleCheckTypes: (updatedList: MultiSelectValueType[]) => void;
  detailedList: MultiSelectDetailsType[];
  title?: string;
  description?: string;
}

const AdminMultiSelectDialog: React.FC<Props> = ({
  selectedTypes,
  handleCheckTypes,
  detailedList,
  title = 'Select options',
  description = 'Select the fields you want to include in this document type.',
}) => {
  const { closeDialog } = useModal();
  const { t } = useTranslation();

  const [searchResults, setSearchResults] = useState<MultiSelectDetailsType[]>(detailedList);
  const [collapsedItems, setCollapsedItems] = useState<Set<string>>(new Set());
  const [selectedItems, setSelectedItems] = useState<MultiSelectValueType[]>(selectedTypes);

  // Memoize Fuse instance to avoid recreation on every render
  const fuse = useMemo(() => new Fuse(detailedList, fuseOptions), [detailedList]);

  // Helper function to flatten the option tree
  const flattenOptions = useCallback((options: MultiSelectValueType[]): Set<string> => {
    return new Set(options.flatMap((option) => [option.id, ...(option.children || [])]));
  }, []);

  // Memoize flattened selected items for efficient lookup
  const flattenedSelectedItems = useMemo(() => flattenOptions(selectedItems), [selectedItems]);

  const handleCheck = useCallback((option: MultiSelectDetailsType, parent?: MultiSelectDetailsType) => {
    setSelectedItems((prevSelected) => {
      if (parent) {
        const parentIndex = prevSelected.findIndex((item) => item.id === parent.id);
        if (parentIndex !== -1) {
          const updatedParent = { ...prevSelected[parentIndex] };
          // If children is null, it means all children were selected
          const allChildrenSelected = updatedParent.children == null;

          if (allChildrenSelected) {
            // If all children were selected, create a new array with all children except the current one
            updatedParent.children = parent.children
              ?.filter((child) => child.id !== option.id)
              .map((child) => child.id);
          } else {
            // If not all children were selected, toggle the current child
            updatedParent.children = updatedParent.children
              ? updatedParent.children.includes(option.id)
                ? updatedParent.children.filter((id) => id !== option.id)
                : [...updatedParent.children, option.id]
              : [option.id];
          }

          // If all children are now selected, set children to null
          if (updatedParent.children?.length === parent.children?.length) {
            updatedParent.children = null;
          }

          return [
            ...prevSelected.slice(0, parentIndex),
            updatedParent,
            ...prevSelected.slice(parentIndex + 1),
          ];
        } else {
          // If parent wasn't in the list, add it with the current child selected
          return [...prevSelected, { id: parent.id, children: [option.id] }];
        }
      } else {
        // Toggle the selection of a top-level item
        return prevSelected.some((item) => item.id === option.id)
          ? prevSelected.filter((item) => item.id !== option.id)
          : [...prevSelected, { id: option.id }];
      }
    });
  }, []);

  const isChecked = useCallback(
    (option: MultiSelectDetailsType, parent?: MultiSelectDetailsType): boolean => {
      if (parent) {
        const parentItem = selectedItems.find((item) => item.id === parent.id);
        return parentItem ? parentItem.children == null || parentItem.children.includes(option.id) : false;
      }
      return flattenedSelectedItems.has(option.id);
    },
    [selectedItems, flattenedSelectedItems]
  );

  const isIndeterminate = useCallback(
    (option: MultiSelectDetailsType): boolean => {
      if (!option.children) return false;
      const selected = selectedItems.find((it) => it.id === option.id);
      if (!selected || !selected.children) return false;
      return selected.children.length > 0 && selected.children.length < option.children.length;
    },
    [selectedItems]
  );

  const handleCheckAll = useCallback(() => {
    setSelectedItems((prevSelected) =>
      prevSelected.length === searchResults.length ? [] : searchResults.map((option) => ({ id: option.id }))
    );
  }, [searchResults]);

  const handleCollapse = useCallback((id: string) => {
    setCollapsedItems((prev) => {
      const newSet = new Set(prev);
      if (newSet.has(id)) {
        newSet.delete(id);
      } else {
        newSet.add(id);
      }
      return newSet;
    });
  }, []);

  const handleSubtypeInput = useCallback(
    (value: string) => {
      setSearchResults(value === '' ? detailedList : extendedSearch(value, fuse));
    },
    [detailedList, fuse]
  );

  const renderOption = useCallback(
    (option: MultiSelectDetailsType, depth = 0, parent?: MultiSelectDetailsType) => {
      const isCollapsed = collapsedItems.has(option.id);
      const hasChildren = option.children && option.children.length > 0;

      return (
        <div key={option.id}>
          <div
            className={clsx(s.multi_select_row, s[`depth-${depth}`])}
            onClick={(e) => {
              e.stopPropagation();
              if (hasChildren) handleCollapse(option.id);
              else handleCheck(option, parent);
            }}
          >
            <span onClick={() => handleCheck(option, parent)}>{option.name}</span>
            {hasChildren && <ArrowIcon className={clsx(s.arrow_icon, { [s.collapsed]: isCollapsed })} />}
            <Checkbox
              onClick={() => handleCheck(option, parent)}
              checked={isChecked(option, parent) && !isIndeterminate(option)}
              indeterminate={isIndeterminate(option)}
            />
          </div>

          {hasChildren && !isCollapsed && (
            <div className={s.multi_select_children}>
              {option.children.map((child) => renderOption(child, depth + 1, option))}
            </div>
          )}
        </div>
      );
    },
    [collapsedItems, handleCheck, handleCollapse, isChecked, isIndeterminate]
  );

  // Memoize the calculation of visible items
  const visibleItemsCount = useMemo(() => {
    const calculateVisibleItems = (options: MultiSelectDetailsType[]): number => {
      return options.reduce((count, item) => {
        count++;
        if (item.children && !collapsedItems.has(item.id)) {
          count += calculateVisibleItems(item.children);
        }
        return count;
      }, 0);
    };
    return calculateVisibleItems(searchResults);
  }, [searchResults, collapsedItems]);

  return (
    <Modal isDialog>
      <div className={s.dialog_container}>
        <div className={s.dialog_header}>
          <span className={s.dialog_title}>{title}</span>
          <CrossIcon className={s.dialog_cross} onClick={closeDialog} />
        </div>
        <div className={s.dialog_body}>
          <p className={s.dialog_text}>{description}</p>

          <div className={s.multi_select_wrapper}>
            <div className={clsx(s.multi_select_search)} style={{ marginBottom: 8 }}>
              <input
                onChange={(e) => handleSubtypeInput(e.target.value)}
                placeholder={'Filter'}
                className={s.search}
                type="text"
              />
              <SearchIcon className={s.search_icon} />
            </div>
            <div className={s.multi_select_row} style={{ marginBottom: 8 }} onClick={handleCheckAll}>
              <span>
                {selectedItems.length !== searchResults.length
                  ? t('admin:multiSelect.select')
                  : t('admin:multiSelect.deselect')}
              </span>
              <Checkbox
                onClick={handleCheckAll}
                checked={selectedItems.length === searchResults.length}
                indeterminate={selectedItems.length > 0 && selectedItems.length < searchResults.length}
              />
            </div>
            <div
              className={s.multi_select_container}
              style={visibleItemsCount > 7 ? { marginRight: -8, paddingRight: 4 } : {}}
            >
              {searchResults.map((option) => renderOption(option))}
            </div>
          </div>

          <Button
            className={s.popup_button}
            onClick={() => {
              handleCheckTypes(selectedItems);
              closeDialog();
            }}
            text={t('admin:multiSelect.confirm')}
          />
        </div>
      </div>
    </Modal>
  );
};

export default AdminMultiSelectDialog;
