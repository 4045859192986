import StyledSelect from '@components/shared/dropdown/StyledSelect';
import { extendedSearch, globalFuseOptions } from '@shared/helpers/helpers';
import { UrlParams } from '@shared/models/generic';
import { SidebarData, SidebarType } from '@shared/models/inbox';
import { inboxSlice } from '@shared/store/inboxSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store';
import s from '@shared/styles/component/inbox/inbox-sidebar.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { ReactComponent as DashboardIcon } from '@svg/dashboard-icon.svg';
import { ReactComponent as HistoryIcon } from '@svg/historical.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon-old.svg';
import { ReactComponent as PaperboxLogo } from '@svg/paperbox-logo.svg';
import { ReactComponent as SearchIcon } from '@svg/search-icon.svg';
import clsx from 'clsx';
import Fuse from 'fuse.js';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDeepCompareEffect } from 'use-deep-compare';
import InboxSidebarItem from './InboxSidebarItem';
import InboxSidebarNavItem from './InboxSidebarNavItem';

interface Props {
  activeType: { docTypeId: string; subTypeId?: string };
  sidebarData: SidebarData;
}

const fuseOptions = {
  ...globalFuseOptions,
  keys: [
    'id',
    {
      name: 'subTypes.name',
      weight: 0.1,
    },
  ],
};

const filterData = (types: SidebarType[]) => {
  const data = [...types];
  data.forEach((sidebarType, i, list) => {
    list[i] = {
      ...sidebarType,
      subTypes: sidebarType.subTypes ? sidebarType.subTypes.filter((st) => st.count !== 0) : null,
    };
  });
  return data.filter((sidebarType) => {
    return sidebarType.count !== 0 && !sidebarType.isPrivate;
  });
};

const InboxSidebar: React.FC<Props> = ({ sidebarData, activeType }) => {
  const { inboxId }: UrlParams = useParams();

  const location = useLocation();
  const navigate = useNavigate();

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userInboxes = useSelector((state) => state.user.inboxes);
  const userAccount = useSelector((state) => state.user.userAccount);

  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [fuseData, setFuseData] = useState<SidebarType[]>([]);
  const [inputValue, setInputValue] = useState('');
  const fuse = new Fuse(fuseData, fuseOptions);

  const handleInput = (value) => {
    setInputValue(value);
  };

  useEffect(() => {
    if (inboxId && userInboxes.length > 0) {
      const inbox = userInboxes.find((inbox) => inbox.id === inboxId);
      if (inbox) {
        document.title = `${inbox.settings.name} | Paperbox`;
      }
    }
    return () => {
      document.title = 'Home | Paperbox';
    };
  }, [activeType.docTypeId, inboxId, userInboxes]);

  useDeepCompareEffect(() => {
    if (sidebarData) {
      if (inputValue !== '') {
        setFuseData(filterData(sidebarData.types));
        setSearchResults(extendedSearch(inputValue, fuse, 'id'));
      } else {
        setSearchResults(filterData(sidebarData.types));
      }
    }
  }, [inputValue, sidebarData]);
  const isHistorical = location.pathname.includes('historical');
  const handleClearNavOptions = () => {
    dispatch(
      inboxSlice.actions.setDocumentListOptions({
        searchTerm: '',
        inboxId: null,
        navDirection: null,
        subTypeId: undefined,
        docTypeId: '',
        pageSize: 10,
        sortBy: 'upload_time',
        isSortDescending: false,
      })
    );
  };

  return (
    <div className={s.container} data-testid="inbox-sidebar">
      <div className={s.header}>
        <NavLink aria-label="home" to={`/inbox/${inboxId}`}>
          <PaperboxLogo className={s.logo} />
          {import.meta.env.VITE_PAPERBOX_ENVIRONMENT !== 'production' && (
            <div className={s.tag}>
              <span>{import.meta.env.VITE_PAPERBOX_ENVIRONMENT}</span>
            </div>
          )}
        </NavLink>
      </div>
      <InboxSidebarNavItem
        icon={<DashboardIcon />}
        path={`/inbox/${inboxId}/dashboard`}
        title={t('home:dashboard.title')}
      />
      <InboxSidebarNavItem
        icon={<InboxIcon />}
        path={`/inbox/${inboxId}`}
        title={t('home:inbox')}
        onClick={handleClearNavOptions}
      />
      {userAccount.isAdmin && (
        <InboxSidebarNavItem
          testId={'inbox-sidebar-historical'}
          icon={<HistoryIcon />}
          path={`/inbox/${inboxId}/historical`}
          title={t('home:historical')}
          onClick={handleClearNavOptions}
        />
      )}

      {!location.pathname.includes('dashboard') && (
        <>
          <div style={{ marginTop: 20 }} className={clsx(s.segment, s.segment__top)}>
            <div className={s.segment_head}>
              <div className={s.segment_title}>{t('home:inboxes')}</div>
            </div>

            <div className={s.dropdown}>
              <StyledSelect
                testId={userInboxes.length > 0 ? 'inbox-select-active' : 'inbox-select'}
                value={{
                  value: inboxId,
                  label: userInboxes.find((inbox) => inbox.id === inboxId)?.settings?.name,
                }}
                onChange={(e: any) => {
                  console.log(e);
                  if (e && inboxId !== e.value) {
                    let path = `/inbox/${e.value}`;
                    if (isHistorical) {
                      path += '/historical';
                    }
                    navigate(path, { replace: true });
                  }
                }}
                options={
                  userInboxes?.map((item) => ({
                    value: item.id,
                    label: item.settings.name,
                  })) ?? []
                }
                isLoading={!(userInboxes.length > 0 && inboxId)}
              />
            </div>
          </div>
          <div data-testid={'inboxes-sidebar-segment'} className={clsx(s.segment, s.segment__top)}>
            <div className={s.segment_head}>
              {isSearchActive ? (
                <input
                  onBlur={(e) => {
                    if (e.target.value === '') {
                      setInputValue('');
                      setIsSearchActive(false);
                    }
                  }}
                  value={inputValue}
                  onChange={(e) => handleInput(e.target.value)}
                  autoFocus
                  className={s.search}
                  type="text"
                />
              ) : (
                <div
                  onClick={() => {
                    handleInput('');
                    setIsSearchActive(!isSearchActive);
                  }}
                  className={s.segment_title}
                >
                  {t('home:docTypes')}
                </div>
              )}

              <button
                onClick={() => {
                  handleInput('');
                  setIsSearchActive(!isSearchActive);
                }}
                className={s.segment_icon}
              >
                {isSearchActive ? <CrossIcon /> : <SearchIcon style={{ color: '#5E7AA1' }} />}
              </button>
            </div>
            <NavLink to={isHistorical ? `/inbox/${inboxId}/historical` : `/inbox/${inboxId}`}>
              <InboxSidebarItem
                currentType={{ docTypeId: '' }}
                activeType={activeType}
                sidebarItem={{
                  id: '',
                  count: sidebarData ? sidebarData.totalCount : 0,
                  name: t('home:allDocuments'),
                }}
              />
            </NavLink>
            {sidebarData && (
              <>
                <div className={s.list}>
                  {[...(searchResults as SidebarType[])].map((type) => {
                    return (
                      <InboxSidebarItem
                        currentType={{ docTypeId: type.id }}
                        key={type.id}
                        activeType={activeType}
                        sidebarItem={type}
                      />
                    );
                  })}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default InboxSidebar;
