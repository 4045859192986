import { ReactComponent as ErrorIcon } from '@svg/error-icon.svg';
import clsx from 'clsx';
import React, { InputHTMLAttributes } from 'react';
import s from './input.module.scss';

interface Props {
  setValue?: (value: string) => void;
  value: string;
  hasError?: boolean;
  errorText?: any;
  label?: string;
  placeholder?: InputHTMLAttributes<any>['placeholder'];
  type?: InputHTMLAttributes<any>['type'];
  id: string;
  dataTestIdPrefix?: string;
}

const Input: React.FC<Props & React.InputHTMLAttributes<any>> = ({
  value,
  hasError = false,
  setValue,
  placeholder,
  errorText,
  label,
  id,
  type = 'text',
  dataTestIdPrefix,
  required = true,
  ...rest
}) => {
  return (
    <div className={s.entry}>
      {label && (
        <label className={s.label} htmlFor={id}>
          {label}
        </label>
      )}

      <input
        data-testid={dataTestIdPrefix + '-input'}
        autoComplete="on"
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
        className={clsx(s.input, { [s.input_error]: hasError })}
        id={id}
        name={id}
        type={type}
        {...rest}
      />
      {hasError && (
        <>
          <ErrorIcon className={s.input__icon} />
          <p data-testid={dataTestIdPrefix + '-error'} className={s.error}>
            {errorText || ''}
          </p>
        </>
      )}
    </div>
  );
};

export default Input;
