import Modal from '@components/shared/modal/Modal';
import {uuid4hex} from '@shared/helpers/helpers.ts';
import {useModal} from '@shared/hooks/useModal.tsx';
import {DocumentDetails, DocumentPart} from '@shared/models/document.ts';
import {isMutationSelector} from '@shared/store/documentSlice.ts';
import {patchTopology} from '@shared/store/inboxSlice.ts';
import {useDispatch, useSelector} from '@shared/store/store.ts';
import s from '@shared/styles/component/document/document-split.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import {ReactComponent as ScissorIcon} from '@svg/scissors.svg';
import clsx from 'clsx';
import React, {useState} from 'react';
import snakecaseKeys from 'snakecase-keys';

// import Toggle from '@components/shared/toggle/Toggle';

interface Props {
  part: DocumentPart;
  activeDocument?: DocumentDetails;
  currentThumbs?: Record<string, any>;
  inboxId: string;
}

const DocumentSplitModal: React.FC<Props> = ({ currentThumbs, part, activeDocument, inboxId }) => {
  const { closeModal } = useModal();
  const dispatch = useDispatch();
  const isMutation = useSelector(isMutationSelector);

  const [cuts, setCuts] = useState([]);
  const [hovers, setHovers] = useState([]);
  const [isSplitting, setIsSplitting] = useState(false);
  const handleSplit = async () => {
    if (isSplitting) return;
    if (cuts.length === 0) {
      console.log('No splits to make');
      return;
    }
    setIsSplitting(true);
    console.log(part);
    const sortedCuts = [...cuts].sort((a, b) => a - b);

    const newParts: DocumentPart[] = [];
    let startIndex = 0;

    for (let i = 0; i <= sortedCuts.length; i++) {
      const endIndex = i < sortedCuts.length ? sortedCuts[i] : part.pages.length;
      const pagesToKeep = part.pages.slice(startIndex, endIndex);
      console.log('pagestokeep', pagesToKeep);
      const pages = part.pages.map((p) => ({
        ...p,
        archived: pagesToKeep.includes(p) && !p.archived ? false : true,
      }));
      console.log(pages);

      if (pages.length > 0) {
        const newPart: DocumentPart = { ...part, id: uuid4hex(), pages: pages };
        console.log(newPart);
        newParts.push(newPart);
      }

      startIndex = endIndex;
    }

    const updatedOriginalGroup: DocumentPart = {
      ...part,
      pages: newParts[0].pages,
      name: newParts[0].name,
    };

    newParts.shift();
    const partsMap = {};
    activeDocument.topology.parts.forEach((pt) => (partsMap[pt.id] = { ...pt }));
    partsMap[updatedOriginalGroup.id] = { ...updatedOriginalGroup };

    for (let i = 0; i < newParts.length; i++) partsMap[newParts[i].id] = newParts[i];
    Object.keys(partsMap).forEach((key) => {
      delete partsMap[key].id;
    });
    const newTopology = { topology: { parts: partsMap } };
    const snake = snakecaseKeys(newTopology);
    await dispatch(patchTopology(activeDocument.id, inboxId, snake, isMutation));
    closeModal();
    setIsSplitting(false);
  };

  return (
    <Modal>
      <div className={s.modal} style={{ maxWidth: Math.min(part.pages.length, 5) * 240 }}>
        <div className={s.header}>
          <h2 className={s.title}> Document Splitting</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.content}>
          <div className={s.sub_row}>
            <span className={s.description}>
              Indicate where you want to split this document, each part will become a new attachment which can
              be edited.
            </span>
          </div>
          <div className={s.page_grid}>
            {part.pages.map((page, pi) => {
              const img = currentThumbs[page.bundlePageNo];
              if (page.archived) return null;
              return (
                <>
                  {pi !== 0 && (
                    <div
                      className={clsx(s.cut, {
                        [s.cut__visible]: hovers.includes(pi) || cuts.includes(pi),
                        [s.cut__remove]: hovers.includes(pi) && cuts.includes(pi),
                      })}
                    >
                      {hovers.includes(pi) && (
                        <div className={s.icon}>
                          {!cuts.includes(pi) && <ScissorIcon />}
                          {cuts.includes(pi) && <CrossIcon />}
                        </div>
                      )}
                    </div>
                  )}
                  <div
                    className={clsx(s.page)}
                    style={{ flex: `0 0 calc(${100 / Math.min(part.pages.length, 5)}% - 25px)` }}
                  >
                    <div className={clsx(s.page_wrapper)}>
                      <img
                        className={clsx(s.page_image)}
                        src={img.imageUrl ?? img.thumbUrl}
                        alt="thumbnail"
                      />
                    </div>
                    <div className={s.page_number}>{pi + 1}</div>
                    {pi !== 0 && (
                      <div
                        className={s.left}
                        onClick={() => {
                          if (cuts.includes(pi)) setCuts(cuts.filter((c) => c !== pi));
                          else setCuts([...cuts, pi]);
                        }}
                        onMouseEnter={() => setHovers([...hovers, pi])}
                        onMouseLeave={() => setHovers(hovers.filter((c) => c !== pi))}
                      />
                    )}

                    <div
                      className={s.right}
                      onClick={() => {
                        if (cuts.includes(pi + 1)) setCuts(cuts.filter((c) => c !== pi + 1));
                        else setCuts([...cuts, pi + 1]);
                      }}
                      onMouseEnter={() => setHovers([...hovers, pi + 1])}
                      onMouseLeave={() => setHovers(hovers.filter((c) => c !== pi + 1))}
                    />
                  </div>
                </>
              );
            })}
          </div>
          <div className={s.footer}>
            <button onClick={handleSplit} disabled={isSplitting} className={clsx(s.button, s.button__alt)}>
              {isSplitting ? 'Splitting...' : 'Split'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentSplitModal;
