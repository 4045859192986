import StyledSelect, {DropdownOption} from '@components/shared/dropdown/StyledSelect';
import Modal from '@components/shared/modal/Modal';
import {useModal} from '@shared/hooks/useModal';
import {useSelector} from '@shared/store/store';
import s from '@shared/styles/component/document/document-type-switch.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import clsx from 'clsx';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  handleConfirm: (newInboxId: string) => void;
  currentInboxId: string;
}

const DocumentInboxSwitchModal: React.FC<Props> = ({ handleConfirm, currentInboxId }) => {
  const inboxes = useSelector((state) => state.user.inboxes);

  const { closeModal } = useModal();
  const { t } = useTranslation();

  const [inboxOptions, setInboxOptions] = useState([]);
  const [inboxValue, setInboxValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleConfirmSelection = () => {
    setIsLoading(true);
    handleConfirm(inboxValue.value);
  };

  useEffect(() => {
    if (inboxes) {
      const map: DropdownOption[] = inboxes.map((type) => {
        return { value: type.id, label: type.settings.name };
      });
      const currentInbox = inboxes.find((inbox) => inbox.id === currentInboxId);
      let filtered = map;
      if (currentInbox?.settings.inboxMoveWhitelistActive) {
        const whitelist = currentInbox?.settings.inboxMoveWhitelist;
        filtered = map.filter((inbox) => whitelist.includes(inbox.value));
      } else {
        filtered = map.filter((inbox) => inbox.value !== currentInboxId);
      }

      setInboxOptions(filtered);
    }
  }, [currentInboxId, inboxes]);

  return (
    <Modal>
      <div data-testid="type-selector-modal" className={s.modal}>
        <div className={s.header}>
          <h2 className={s.title}>{t('document:typeSwitch.inboxTitle')}</h2>
          <CrossIcon onClick={closeModal} className={s.close} />
        </div>
        <div className={s.content}>
          <div className={s.description}>{t('document:typeSwitch.inboxDescription')}</div>
          <div className={s.group}>
            <span className={s.label}>{t('document:typeSwitch.inbox')}</span>
            <StyledSelect
              value={inboxValue}
              defaultValue={inboxOptions[0]}
              onChange={setInboxValue}
              options={inboxOptions}
              isLoading={!inboxOptions}
            />
          </div>

          <div className={s.buttons}>
            <button
              autoFocus
              tabIndex={1}
              onClick={() => handleConfirmSelection()}
              className={clsx(s.button, s.button__alt, {
                [s.button__loading]: isLoading,
              })}
            >
              {isLoading
                ? `${t('document:typeSwitch.inboxPending')}...`
                : t('document:typeSwitch.inboxButton')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DocumentInboxSwitchModal;
