import { useSelector } from '@shared/store/store';

import s from '@shared/styles/component/inbox/inbox-content.module.scss';
import { ReactComponent as ChevronLeft } from '@svg/chevron-left.svg';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

interface Props {
  activeTagId: string;
  setActiveTagId: (tagId: string) => void;
  historical?: boolean;
}

const InboxTags: React.FC<Props> = ({ activeTagId, setActiveTagId }) => {
  const tagTypes = useSelector((state) => state.settings.tagTypes);
  const activeTagCounts = useSelector((state) => state.inbox.activeTagCounts);
  const inboxSettings = useSelector((state) => state.inbox.currentInbox.settings);

  const { width: scw, ref: tagsRef } = useResizeDetector({
    refreshRate: 1,
    refreshMode: 'throttle',
  });

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const handleScroll = (isNext: boolean) => {
    const tags = tagsRef?.current as HTMLDivElement;
    if (tags) {
      let newScroll = tags.scrollLeft;
      isNext ? (newScroll += tags.clientWidth / 2) : (newScroll -= tags.clientWidth / 2);
      setCanScrollLeft(newScroll > 0);
      setCanScrollRight(tags.scrollWidth - newScroll > tags.clientWidth);
      tags.scrollLeft = newScroll;
    }
  };

  useEffect(() => {
    const divElement = tagsRef.current as HTMLDivElement;
    if (divElement && scw) {
      setCanScrollLeft(divElement.scrollLeft > 0);
      setCanScrollRight(divElement.scrollWidth - divElement.scrollLeft > divElement.clientWidth);
    }
  }, [scw, tagsRef]);

  const noTagCount = useMemo(() => {
    if (!activeTagCounts) return 0;
    if (Object.entries(activeTagCounts).length <= 1) return 0;
    return activeTagCounts['@NO_TAG'] ?? 0;
  }, [activeTagCounts]);

  return (
    <>
      {tagTypes && tagTypes.length > 0 && activeTagCounts && Object.entries(activeTagCounts).length > 1 ? (
        <div className={s.tags_wrapper}>
          <div ref={tagsRef} className={s.tags}>
            <>
              {canScrollLeft && (
                <button className={s.button_float} onClick={() => handleScroll(false)}>
                  <ChevronLeft />
                </button>
              )}
              {canScrollRight && (
                <button
                  className={clsx(s.button_float, s.button_float__right)}
                  onClick={() => handleScroll(true)}
                >
                  <ChevronLeft />
                </button>
              )}
              <button
                onClick={() => setActiveTagId(null)}
                className={clsx(s.tag, { [s.tag__active]: activeTagId == null })}
              >
                View All
              </button>
              {[...tagTypes]
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((tag) => {
                  if (!activeTagCounts[tag.id] && !inboxSettings?.labelingMode) return null;
                  const tagCount = activeTagCounts[tag.id] ?? 0;
                  return (
                    <button
                      disabled={tagCount === 0}
                      onClick={() => setActiveTagId(tag.id)}
                      key={tag.id}
                      className={clsx(s.tag, { [s.tag__active]: activeTagId === tag.id })}
                    >{`${tag.name} (${tagCount})`}</button>
                  );
                })}
              {noTagCount > 0 && (
                <button
                  onClick={() => setActiveTagId('@NO_TAG')}
                  className={clsx(s.tag, { [s.tag__active]: activeTagId === '@NO_TAG' })}
                >
                  No tag ({noTagCount})
                </button>
              )}
            </>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default InboxTags;
