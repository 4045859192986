import Modal from '@components/shared/modal/Modal';
import React, { createContext, Suspense, useCallback, useState } from 'react';
import CSSTransition from 'react-transition-group/CSSTransition';

export interface ModalContextInterface {
  showModal: (newModal: JSX.Element) => void;
  showDialog: (newDialog: JSX.Element) => void;
  closeModal: VoidFunction;
  closeDialog: VoidFunction;
  isModalOpen: boolean;
  isDialogOpen: boolean;
}

export const ModalContext = createContext<ModalContextInterface | undefined>(undefined);

export const ModalContextProvider: React.FC<any> = ({ children }) => {
  const [modal, setModal] = useState<JSX.Element>(null);
  const [dialog, setDialog] = useState<JSX.Element>(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const showModal = useCallback(
    (newModal: JSX.Element) => {
      setModal(newModal);
      setIsModalOpen(true);
    },
    [setModal]
  );

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, []);

  const showDialog = useCallback(
    (newDialog: JSX.Element) => {
      setDialog(newDialog);
      setIsDialogOpen(true);
    },
    [setDialog]
  );

  const closeDialog = useCallback(() => {
    setIsDialogOpen(false);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        showModal,
        closeModal,
        closeDialog,
        showDialog,
        isModalOpen,
        isDialogOpen,
      }}
    >
      <>
        {children}
        <Suspense
          fallback={
            <Modal>
              <div />
            </Modal>
          }
        >
          {modal && (
            <CSSTransition mountOnEnter unmountOnExit classNames={'modal'} in={isModalOpen} timeout={300}>
              {modal}
            </CSSTransition>
          )}
        </Suspense>
        {dialog && (
          <CSSTransition mountOnEnter unmountOnExit classNames={'modal'} in={isDialogOpen} timeout={300}>
            {dialog}
          </CSSTransition>
        )}
      </>
    </ModalContext.Provider>
  );
};
