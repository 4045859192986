import StyledSelect, { DropdownOption } from '@components/shared/dropdown/StyledSelect';
import { usePrevious } from '@shared/helpers/helpers';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/document/document-labeler-sidebar-search.module.scss';
import { ReactComponent as CrossIcon } from '@svg/cross-icon.svg';
import { Pulsar } from '@uiball/loaders';
import clsx from 'clsx';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDeepCompareEffect } from 'use-deep-compare';
import { Field } from './Search';

interface Props {
  fields: Field[];
  field?: Field;
  handleChange: (value: Field) => void;
  handleSearch: (e: any) => void;
  handleRemove: VoidFunction;
  onFocus: VoidFunction;
  analyticsOptions: Record<string, string[]>;
}

const SearchRow: React.FC<Props> = ({
  fields,
  field,
  handleChange,
  handleRemove,
  onFocus,
  analyticsOptions,
}) => {
  const masterDataStatus = useSelector((state) => state.document.masterDataStatus);
  const entityTypes = useSelector((state) => state.settings.entityTypes);
  const metadataTypes = useSelector((state) => state.settings.metadataTypes);
  const masterDataMappings = useSelector((state) => state.inbox.masterDataMappings);
  const activeDocument = useSelector((state) => state.document.activeDocument);
  const docTypeSettings = useSelector((state) => state.settings.docTypeSettings);

  const [activeDropdownOption, setActiveDropdownOption] = useState<DropdownOption>();
  const [activeDropdownInputOption, setActiveDropdownInputOption] = useState<DropdownOption>();
  const [dropdownOptions, setDropdownOptions] = useState([]);

  const { t } = useTranslation();

  const prevDropdown: any = usePrevious(activeDropdownOption);
  const previnputDropdown: any = usePrevious(activeDropdownInputOption);

  const dropdownInputOptions = useMemo(() => {
    if (activeDropdownOption?.value) {
      const options = analyticsOptions[activeDropdownOption.value.tableId];
      if (options) {
        const mappedOptions = Object.values(options).map((v) => {
          return {
            label: v,
            value: v,
          } as DropdownOption;
        });

        return mappedOptions;
      }
      setActiveDropdownInputOption(undefined);
      return null;
    }
  }, [activeDropdownOption, analyticsOptions]);

  const activeDocType = useMemo(() => {
    if (activeDocument && docTypeSettings) {
      const activeDocType = docTypeSettings.find((dt) => dt.docTypeId === activeDocument?.docTypeId);
      if (activeDocType) return activeDocType;
    }
  }, [activeDocument, docTypeSettings]);

  useEffect(() => {
    let filtered;
    let list: DropdownOption[] = [
      { label: t('document:masterdata.global'), value: { id: 'global', type: 'global' } },
    ];
    if (!masterDataMappings) return;
    const merged = Object.values(masterDataMappings)
      .map((v) => {
        return v.mapping;
      })
      .flat();

    const spread = [...new Map([].concat(...merged).map((v) => [v.reference, v])).values()].filter(
      (e) => e.isSearchable !== false
    );

    if (entityTypes) {
      filtered = entityTypes.filter((e) =>
        spread.find((t) => t.reference === e.id && t.referenceType === 'entity_types' && !e.isArchived)
      );

      list = [
        ...list,
        ...filtered.map((e) => {
          return {
            label: e.name,
            value: {
              id: e.id,
              type: 'entity_types',
              tableId: spread.find((s) => s.reference === e.id)?.id ?? '',
            },
          } as DropdownOption;
        }),
      ];
    }

    if (metadataTypes) {
      filtered = metadataTypes.filter((e) =>
        spread.find(
          (t) => t.reference === e.id && t.referenceType === 'metadata_keys' && !e.isHidden && !e.isArchived
        )
      );
      list = [
        ...list,
        ...filtered.map((e) => {
          return {
            label: e.name,
            value: {
              id: e.id,
              type: 'metadata_keys',
              tableId: spread.find((s) => s.reference === e.id)?.id ?? '',
            },
          } as DropdownOption;
        }),
      ];
    }

    if (fields) {
      list = list.filter((li) => {
        if (fields.find((e) => e.reference === li.value.id)) {
          return li.value.id === field.reference;
        } else {
          if (activeDocType && li.value.type === 'entity_types') {
            return activeDocType.settings.entityTypes.find((e) => e.id === li.value.id);
          }
          if (activeDocType && li.value.type === 'metadata_keys') {
            return activeDocType.settings.metadataKeys.find((e) => e.id === li.value.id);
          }
          return true;
        }
      });
    }
    setDropdownOptions(list);
  }, [t, fields, field, entityTypes, metadataTypes, masterDataMappings, activeDocType]);

  useDeepCompareEffect(() => {
    if (activeDropdownOption !== prevDropdown) {
      const clone: any = { ...field };
      clone.reference = activeDropdownOption.value.id;
      clone.tableId = activeDropdownOption.value.tableId;
      clone.reference_type = activeDropdownOption.value?.type ?? '';
      handleChange(clone);
    }
  }, [field, handleChange, activeDropdownOption]);

  useDeepCompareEffect(() => {
    if (activeDropdownInputOption !== previnputDropdown) {
      const clone = { ...field };
      if (activeDropdownInputOption != null) {
        clone.prompt = activeDropdownInputOption.value;
      } else {
        clone.prompt = '';
      }
      handleChange(clone);
    }
  }, [field, handleChange, activeDropdownInputOption]);

  const hasMasterdataLinkedFields = useMemo(() => {
    if (dropdownOptions) {
      const filtered = [...(dropdownOptions as DropdownOption[])].filter((e) => e.value.id !== 'global');
      if (filtered.length === 0) {
        return fields.length > 1;
      }
      return true;
    }
    return false;
  }, [dropdownOptions, fields]);

  return (
    <div
      onFocus={onFocus}
      onFocusCapture={onFocus}
      className={clsx(s.wrapper, { [s.wrapper__disabled]: !hasMasterdataLinkedFields })}
    >
      <div className={s.dropdown_wrapper}>
        <StyledSelect
          className={s.dropdown}
          defaultValue={
            field.reference ? dropdownOptions.find((e) => e.value.id === field.reference) : dropdownOptions[0]
          }
          value={activeDropdownOption}
          isLoading={!dropdownOptions}
          options={dropdownOptions}
          onChange={(e: any) => {
            setActiveDropdownOption(e);
          }}
        />
      </div>
      <div className={s.search_wrapper}>
        {dropdownInputOptions ? (
          <div className={clsx(s.dropdown_wrapper_right)}>
            <StyledSelect
              className={s.dropdown_right}
              defaultValue={
                dropdownInputOptions.find((e) => e.value === field.prompt) ?? dropdownInputOptions[0]
              }
              value={activeDropdownInputOption}
              isLoading={!dropdownInputOptions}
              options={dropdownInputOptions}
              onChange={(e: any) => {
                const clone = { ...field };
                clone.prompt = e.value;
                handleChange(clone);
                setActiveDropdownInputOption(e);
              }}
            />
          </div>
        ) : (
          <>
            <input
              data-testid={'masterdata-search-input'}
              type={'text'}
              placeholder={
                hasMasterdataLinkedFields
                  ? t('document:masterdata.searchMasterdata')
                  : t('document:masterdata.searchNofields')
              }
              value={field.prompt}
              onChange={(e) => {
                const clone = { ...field };
                clone.prompt = e.target.value;
                handleChange(clone);
              }}
              className={s.search}
            />
          </>
        )}
        <>
          {masterDataStatus === 'searching' && (
            <div className={s.search_icon}>
              <Pulsar color={'#0085FF'} size={17} />
            </div>
          )}
        </>
      </div>
      {fields.length > 1 && (
        <button type={'button'} onClick={handleRemove} className={s.remove}>
          <CrossIcon />
        </button>
      )}
    </div>
  );
};

export default SearchRow;
