import { MsalProvider } from '@azure/msal-react';
import SuspenseLoader from '@components/shared/suspense-loader/SuspenseLoader';
import * as Sentry from '@sentry/react';
import { BrowserTracing, ErrorBoundary } from '@sentry/react';
import { Replay } from '@sentry/replay';
import ErrorContainer from '@src/containers/ErrorContainer';
import { createBrowserHistory } from 'history';
import { Suspense, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom';
import TopBarProgress from 'react-topbar-progress-indicator';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { msalInstance } from './shared/store/setup/msalConfig.ts';
import { store } from './shared/store/store';

import './shared/store/setup/firebase-setup';
import './shared/store/setup/i18n';

TopBarProgress.config({
  barThickness: 2,
  barColors: {
    '0': '#0037ff',
    '1.0': '#00AAFF',
  },
});

const history = createBrowserHistory();

const isDevEnv =
  import.meta.env.VITE_PAPERBOX_ENVIRONMENT === 'develop' ||
  import.meta.env.VITE_PAPERBOX_ENVIRONMENT === 'test';

if (!isDevEnv) {
  console.log(
    `%c
 ______                          __                
|   __ \\.---.-.-----.-----.----.|  |--.-----.--.--.
|    __/|  _  |  _  |  -__|   _||  _  |  _  |_   _|
|___|   |___._|   __|_____|__|  |_____|_____|__.__| ${
      import.meta.env.VITE_PAPERBOX_RELEASE ? import.meta.env.VITE_PAPERBOX_RELEASE : ''
    }
              |__|       
              
  `,
    ' color: #0085FFFF; padding: 2px; border-radius:2px'
  );
  Sentry.init({
    ignoreErrors: ['ResizeObserver loop limit exceeded', 'ChunkLoadError', 'Network Error'],
    dsn: 'https://749cb88ac44644008edc1e37fa8a2e04@o556414.ingest.sentry.io/5687270',
    integrations: [
      new Replay({
        blockAllMedia: false,
        block: ['[data-hj-suppress]'],
      }),
      new BrowserTracing({
        tracePropagationTargets: ['localhost', /.*(.paperbox.ai).*/g],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        ),
      }),
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
    tracesSampleRate: 0.35,
    environment: import.meta.env.VITE_PAPERBOX_ENVIRONMENT,
    release: import.meta.env.VITE_PAPERBOX_RELEASE,
    replaysOnErrorSampleRate: 1,
    replaysSessionSampleRate: 0,
  });
  Sentry.setUser({ ip_address: null });
}

const container = document.getElementById('root') || document.createElement('div');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <MsalProvider instance={msalInstance}>
      <Suspense fallback={<SuspenseLoader name={'MainFallback'} fullPage />}>
        <ErrorBoundary
          fallback={() => {
            if (isDevEnv) {
              return null;
            }
            let errCount = parseInt(localStorage.getItem('e'));
            if (!errCount) {
              errCount = 0;
            }
            if (errCount > 0) {
              localStorage.setItem('e', '0');
              console.log('return errorcontainer');
              return <ErrorContainer />;
            } else {
              localStorage.setItem('e', (errCount + 1).toString());
              console.log('reload');
              window.location.reload();
            }
          }}
        >
          <App history={history} />
        </ErrorBoundary>
      </Suspense>
    </MsalProvider>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorkerRegistration.register();
