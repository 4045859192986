import Checkbox from '@components/shared/checkbox/Checkbox.tsx';
import StyledSelect from '@components/shared/dropdown/StyledSelect.tsx';
import { IClientFieldType } from '@shared/helpers/converters/fieldtype.ts';
import { useKeyPress } from '@shared/helpers/helpers.ts';
import { labelerSlice } from '@shared/store/labelerSlice.ts';
import { useDispatch, useSelector } from '@shared/store/store.ts';
import s from '@shared/styles/component/document/document-labeler-sidebar.module.scss';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';
import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import DocumentLabelerSidebarHover from './DocumentLabelerSidebarHover.tsx';

interface Props {
  fieldType: IClientFieldType;
  handleConfirm: (e: any) => void;
}

const DocumentLabelerSidebarComplexInput: React.FC<Props> = ({ handleConfirm, fieldType }) => {
  const tempEntity = useSelector((state) => state.labeler.tempEntity);
  const activeTool = useSelector((state) => state.labeler.activeTool);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    switch (fieldType?.type) {
      case 'boolean':
        dispatch(labelerSlice.actions.setActiveTool('rectangle'));
        break;
      case 'text':
        dispatch(labelerSlice.actions.setActiveTool('default'));
        break;
      case 'image':
        dispatch(labelerSlice.actions.setActiveTool('rectangle'));
        break;
      case 'table':
        dispatch(labelerSlice.actions.setActiveTool('table'));
        break;
      case 'complex':
        dispatch(labelerSlice.actions.setActiveTool('default'));
        break;
      default:
        dispatch(labelerSlice.actions.setActiveTool('default'));
        break;
    }
  }, [dispatch, fieldType]);

  const handleToggle = (e) => {
    if (fieldType?.type === 'boolean') {
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') return;
      e.preventDefault();
      dispatch(labelerSlice.actions.setTempEntity({ ...tempEntity, value: !tempEntity.value ?? false }));
    }
  };
  const handleChange = (value: string) => {
    if (tempEntity) {
      dispatch(labelerSlice.actions.setTempEntity({ ...tempEntity, value }));
    } else {
      dispatch(
        labelerSlice.actions.setTempEntity({
          pageNo: 1,
          valueLocations: [],
          rawValue: '',
          value,
          type: fieldType.id,
        })
      );
    }
  };
  useEffect(() => {
    if (fieldType?.type === 'options' && (!tempEntity || (tempEntity && tempEntity.value === ''))) {
      const options =
        fieldType?.options.map((option) => {
          return { value: option.value, label: option.value };
        }) ?? [];
      handleChange(options[0].value);
    }
  }, [fieldType, handleChange, tempEntity]);

  useKeyPress('Enter', handleConfirm);

  const renderTool = useMemo(() => {
    switch (activeTool) {
      case 'default':
        if (fieldType?.type === 'options') {
          const options =
            fieldType?.options.map((option) => {
              return { value: option.value, label: option.value };
            }) ?? [];
          return (
            <div className={s.complex_input_wrapper}>
              <StyledSelect
                defaultValue={options[0]}
                value={
                  tempEntity?.value ? options.find((option) => option.value === tempEntity.value) : options[0]
                }
                onChange={(e) => {
                  if (tempEntity?.value !== (e as any).value) {
                    handleChange((e as any).value);
                  }
                }}
                options={options}
              />
              <button onClick={handleConfirm}>
                <CheckmarkIcon />
              </button>
            </div>
          );
        } else {
          return (
            <div className={s.complex_input_wrapper}>
              <input
                autoFocus
                value={typeof tempEntity?.value === 'string' ? tempEntity?.value : ''}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
                className={s.complex_input}
                placeholder={t('document:toolbar.typeOrSelect')}
                type="text"
              />
              <button onClick={handleConfirm}>
                <CheckmarkIcon />
              </button>
            </div>
          );
        }

      case 'rectangle':
        if (fieldType?.type === 'boolean') {
          return (
            <div className={s.complex_check_wrapper}>
              {tempEntity?.dataURL && (
                <>
                  <div className={s.checked}>
                    checked <Checkbox checked={tempEntity.value as boolean} onClick={handleToggle} />
                  </div>
                </>
              )}

              {!tempEntity?.dataURL && <div className={s.placeholder}>Please label a value</div>}
              {tempEntity?.dataURL && (
                <>
                  <div className={s.image} data-hj-suppress>
                    {tempEntity?.dataURL && (
                      <div className={s.image_wrapper}>
                        <img src={tempEntity?.dataURL} alt="data" />
                      </div>
                    )}
                  </div>
                  <button className={s.button} onClick={handleConfirm}>
                    <CheckmarkIcon />
                  </button>
                </>
              )}
            </div>
          );
        } else if (fieldType?.type === 'image') {
          return (
            <div className={s.complex_input_wrapper}>
              {!tempEntity?.dataURL && <div className={s.placeholder}>Please label an image</div>}
              {tempEntity?.dataURL && (
                <DocumentLabelerSidebarHover imageUrl={tempEntity?.dataURL}>
                  <div className={s.image} data-hj-suppress>
                    {tempEntity?.dataURL && (
                      <div className={s.image_wrapper}>
                        <img src={tempEntity?.dataURL} alt="data" />
                      </div>
                    )}
                  </div>
                </DocumentLabelerSidebarHover>
              )}

              <button onClick={handleConfirm}>
                <CheckmarkIcon />
              </button>
            </div>
          );
        }
    }
  }, [activeTool, dispatch, fieldType, handleConfirm, handleToggle, t, tempEntity]);

  return renderTool;
};

export default DocumentLabelerSidebarComplexInput;
