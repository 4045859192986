import { useSelector } from '@shared/store/store.ts';
import clsx from 'clsx';
import React from 'react';
import s from './notification-bar.module.scss';

interface Props {}

const NotificationBar: React.FC<Props> = () => {
  const notification = useSelector((state) => state.notification.currentNotification);
  if (!notification) return null;

  return (
    <div
      className={clsx(
        s.notification,
        { [s.notification__visible]: notification.visible },
        { [s.notification__hidden]: !notification.visible },
        { [s.notification__warning]: notification.type === 'warning' },
        { [s.notification__success]: notification.type === 'success' },
        { [s.notification__error]: notification.type === 'error' }
      )}
    >
      <div className={s.notification__container}>
        <div className={s.notification__content}>{notification.text}</div>
      </div>
    </div>
  );
};

export default NotificationBar;
