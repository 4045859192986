import { labelerSlice } from '@shared/store/labelerSlice';
import { useCallback, useEffect } from 'react';

export const WheelListener = ({ isCtrlDown, handleScale, containerRef }) => {
  const handleWheel = useCallback(
    (e) => {
      if (!isCtrlDown) return;
      e.preventDefault();
      handleScale(e.deltaY < 0, e);
    },
    [handleScale, isCtrlDown]
  );

  useEffect(() => {
    const container = containerRef?.current as HTMLDivElement;

    container?.addEventListener('wheel', handleWheel);

    return () => {
      container?.removeEventListener('wheel', handleWheel);
    };
  }, [containerRef, handleWheel]);

  return null;
};

export const EscListener = ({ itemRef, dispatch, tempEntity }) => {
  const item = itemRef.current;

  useEffect(() => {
    const listener = (event) => {
      if ((tempEntity && event.key === 'Escape') || event.code === 'Escape') {
        dispatch(labelerSlice.actions.setTempEntity(null));
      }
    };
    if (item) item.addEventListener('keydown', listener);
    return () => {
      if (item) item.removeEventListener('keydown', listener);
    };
  }, [dispatch, item, tempEntity]);

  return null;
};

export const BlurListener = ({ setCanDrag, setCanZoom }) => {
  useEffect(() => {
    const listener = () => {
      setCanDrag(false);
      setCanZoom(false);
    };
    window.addEventListener('blur', listener);
    return () => {
      window.removeEventListener('blur', listener);
    };
  }, [setCanZoom, setCanDrag]);

  return null;
};

export const ClipboardListener = ({ itemRef, tempEntity }) => {
  const item = itemRef.current;
  useEffect(() => {
    const listener = () => {
      if (tempEntity) {
        if (tempEntity.valueLocations && tempEntity.valueLocations.length > 0) {
          navigator.clipboard.writeText(tempEntity.value);
        }
      }
    };
    if (item) item.addEventListener('copy', listener);
    return () => {
      if (item) item.removeEventListener('copy', listener);
    };
  }, [item, tempEntity]);

  return null;
};

export const DragListener = ({ setCanDrag, canDrag }) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const element = event.target as HTMLElement;

      if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') return;

      if (!canDrag && (event.key === 'Control' || event.code === 'Control' || event.code === 'Space')) {
        setCanDrag(true);
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [canDrag, setCanDrag]);

  useEffect(() => {
    const listener = (event) => {
      const element = event.target as HTMLElement;
      if (element.tagName === 'INPUT' || element.tagName === 'TEXTAREA') return;

      if (event.key === 'Control' || event.code === 'Control' || event.code === 'Space') {
        setCanDrag(false);
      }
    };
    document.addEventListener('keyup', listener);
    return () => {
      document.removeEventListener('keyup', listener);
    };
  }, [setCanDrag]);

  return null;
};

export const ShiftListener = ({ itemRef, setScrollHorizontal, scrollHorizontal }) => {
  const item = itemRef.current;

  useEffect(() => {
    const listener = (event) => {
      if (!scrollHorizontal && (event.key === 'Shift' || event.code === 'Shift')) {
        setScrollHorizontal(true);
      }
    };
    if (item) item.addEventListener('keydown', listener);
    return () => {
      if (item) item.removeEventListener('keydown', listener);
    };
  }, [setScrollHorizontal, scrollHorizontal, item]);

  useEffect(() => {
    const listener = (event) => {
      if (event.key === 'Shift' || event.code === 'Shift') {
        setScrollHorizontal(false);
      }
    };
    if (item) item.addEventListener('keyup', listener);
    return () => {
      if (item) item.removeEventListener('keyup', listener);
    };
  }, [item, setScrollHorizontal]);

  return null;
};

export const DocumentListener = ({
  isInteractive,
  isModalOpen,
  handleNext,
  handlePrevious,
  handleNextPage,
  handlePrevPage,
}) => {
  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      const element = event.target as HTMLElement;
      if (isInteractive && element.tagName !== 'INPUT' && !isModalOpen) {
        if (event.key === 'ArrowRight') {
          event.preventDefault();

          if (event.ctrlKey && event.shiftKey) {
            handleNext();
          } else {
            handleNextPage();
          }
        } else if (event.key === 'ArrowLeft') {
          if (event.ctrlKey && event.shiftKey) {
            handlePrevious();
          } else {
            handlePrevPage();
          }
        }
      }
    };

    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [handleNext, handleNextPage, handlePrevPage, handlePrevious, isInteractive, isModalOpen]);

  return null;
};
