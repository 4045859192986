import {DndContext, PointerSensor, useSensor, useSensors} from '@dnd-kit/core';
import {arrayMove, rectSortingStrategy, SortableContext, useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import s from '@shared/styles/component/admin/admin-section.module.scss';
import a from '@shared/styles/component/admin/admin.module.scss';
import {ReactComponent as DragHandleDots} from '@svg/drag-handle-dots.svg';
import {ReactComponent as EditIcon} from '@svg/edit-icon.svg';
import {ReactComponent as TrashIcon} from '@svg/trash-icon-alt.svg';
import clsx from 'clsx';
import React, {CSSProperties, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import si from '../../shared/slider/slider.module.scss';

interface Props {
  options: { id: string; value: string }[];
  setItems: (options: { id: string; value: string }[]) => void;
  handleDelete: (id: string) => void;
}

const AdminSortableFieldOptions: React.FC<Props> = ({ options, setItems, handleDelete }) => {
  const [workableItems, setWorkableItems] = useState(options);

  const { t } = useTranslation();

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      if (!over) return;
      const oldIndex = workableItems.findIndex((e) => e.id === active.id);
      const newIndex = workableItems.findIndex((e) => e.id === over.id);
      const sorted = arrayMove(workableItems, oldIndex, newIndex);

      setItems(sorted);
      setWorkableItems(sorted);
    }
  };

  useEffect(() => {
    if (options) setWorkableItems(options);
  }, [options]);

  return (
    <div className={s.row_list}>
      {workableItems?.length > 0 && (
        <div className={s.field_type_header}>
          <span>{t('admin:docType.fieldName')}</span>
          <span
            style={{ width: '100%', maxWidth: 550, marginRight: 0, marginLeft: 'auto', textAlign: 'center' }}
          >
            {t('admin:fieldType.id')}
          </span>
          {/*<span style={{ width: '45%', maxWidth: 375, marginLeft: 0 }}>*/}
          {/*  {t('admin:docType.fieldOccurrences')}*/}
          {/*</span>*/}
        </div>
      )}

      <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
        <SortableContext items={options ?? []} strategy={rectSortingStrategy}>
          {workableItems?.map((et) => {
            return (
              <AdminSortableFieldOptionItem
                key={et.value}
                name={et.value}
                option={et}
                handleDelete={handleDelete}
                handleChangeId={(newId: string) => {
                  const newOptions = options.map((e) => {
                    if (e.value === et.value) {
                      return { ...e, id: newId };
                    }
                    return e;
                  });
                  setItems(newOptions);
                }}
              />
            );
          })}
        </SortableContext>
      </DndContext>
    </div>
  );
};

export default AdminSortableFieldOptions;

const AdminSortableFieldOptionItem: React.FC<any> = ({ name, option, handleChangeId, handleDelete }) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: option.id,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    zIndex: isDragging ? 100 : 10,
    transition: transition,
  } as CSSProperties;

  return (
    <div className={clsx(sr.wrapper, sr.wrapper__no_anim)} style={style} {...attributes}>
      <div
        ref={setNodeRef}
        {...listeners}
        data-testid={'sortable-field'}
        className={clsx(sr.container, sr.container__no_hover)}
      >
        <div
          style={{ cursor: isDragging ? 'grabbing' : 'grab', display: 'flex', alignItems: 'center', gap: 4 }}
        >
          <DragHandleDots className={a.drag_handle} />
          <span className={sr.title}>{name}</span>
        </div>
      </div>

      <div className={sr.actions}>
        <div className={clsx(sr.action, sr.action__long, s.input_wrapper)}>
          <input
            type={'text'}
            placeholder={'ID'}
            value={option.id}
            onChange={(e) => {
              handleChangeId(e.target.value);
            }}
            style={{ border: 'none', width: 250, fontWeight: 400, textAlign: 'start', fontSize: 14 }}
            className={clsx(si.number)}
          />
          <div className={s.input_label}>
            <EditIcon style={{ color: 'rgba(0, 0, 0, 0.3)', marginTop: 2 }} />
          </div>
        </div>

        <button
          data-testid={'admin-item-row-delete'}
          className={clsx(sr.action, sr.trash)}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            handleDelete(option.id);
          }}
        >
          <TrashIcon />
        </button>
      </div>
    </div>
  );
};
