import {DocumentNote} from '@shared/models/document';
import {UrlParams} from '@shared/models/generic';
import {deleteDocumentNote, postDocumentNote} from '@shared/store/documentSlice';
import {useDispatch, useSelector} from '@shared/store/store';
import s from '@shared/styles/component/document/document-notes.module.scss';
import avatar from '@svg/avatar.svg';
import {ReactComponent as NoteIcon} from '@svg/note.svg';
import {ReactComponent as SendIcon} from '@svg/send.svg';
import {ReactComponent as TrashIcon} from '@svg/trash-icon-alt.svg';
import {Ring} from '@uiball/loaders';
import clsx from 'clsx';
import React, {useState} from 'react';
import {useParams} from 'react-router-dom';

interface Props {
  notes: DocumentNote[];
}

const DocumentNotes: React.FC<Props> = ({ notes }) => {
  const { inboxId, docId }: UrlParams = useParams();

  const users = useSelector((state) => state.admin.users);
  const userAccount = useSelector((state) => state.user.userAccount);

  const [isModalActive, setIsModalActive] = useState(false);
  const [input, setInput] = useState('');
  const dispatch = useDispatch();

  const handleSend = () => {
    dispatch(
      postDocumentNote(inboxId, docId, {
        message: input,
      })
    );
  };

  const handleDelete = (noteId: string) => {
    dispatch(deleteDocumentNote(inboxId, docId, noteId));
  };

  return (
    <div className={s.wrapper}>
      <button onClick={() => setIsModalActive((ps) => !ps)} className={s.button}>
        <NoteIcon className={s.button_icon} /> <span>Notes</span>
        {notes.length > 0 && <div className={s.button_dot} />}
      </button>
      <div className={clsx(s.modal, { [s.modal__active]: isModalActive })}>
        <div className={s.notes}>
          {notes?.length === 0 && (
            <div className={s.note}>
              <div className={s.note_empty}>
                <span>No notes, start typing below to add one.</span>
              </div>
            </div>
          )}
          {[...notes]
            .sort((a, b) => b?.timestamp.getTime() - a?.timestamp.getTime())
            .map((note) => {
              return (
                <div key={note.id} className={s.note}>
                  {note.isLoading && (
                    <div className={s.note_loader}>
                      <Ring color={'#0085FF'} size={24} lineWeight={4} />
                    </div>
                  )}
                  <div className={s.note_author}>
                    <img className={s.note_author_avatar} src={avatar} alt="" />
                    <div className={s.note_author_name}>
                      {users.find((e) => e.id === note.userId)?.email ?? ''}
                    </div>
                    <span className={s.note_date}>
                      {note.timestamp.toLocaleString('nl-BE', {
                        month: 'short',
                        day: '2-digit',
                        hour: '2-digit',
                        minute: '2-digit',
                      })}
                    </span>
                    {note.userId === userAccount?.id && (
                      <div className={s.note_actions}>
                        <button
                          onClick={() => handleDelete(note.id)}
                          className={clsx(s.note_action, s.note_action__delete)}
                        >
                          <TrashIcon style={{ transform: 'scale(0.73)' }} />
                        </button>
                      </div>
                    )}
                  </div>

                  <div className={s.note_content}>{note.message}</div>
                </div>
              );
            })}
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            handleSend();
            setInput('');
          }}
          className={s.input_wrapper}
        >
          <input
            required
            value={input}
            onChange={(e) => setInput(e.target.value)}
            className={s.input}
            placeholder={'Start typing...'}
          />
          <button className={s.input_button}>
            <SendIcon />
          </button>
        </form>
      </div>
    </div>
  );
};

export default DocumentNotes;
