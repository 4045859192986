import { usePrevious } from '@shared/helpers/helpers';
import { UrlParams } from '@shared/models/generic';
import { SidebarType } from '@shared/models/inbox';
import { useSelector } from '@shared/store/store';
import s from '@shared/styles/component/inbox/inbox-sidebar.module.scss';
import { ReactComponent as ChevronDownIcon } from '@svg/chevron-down.svg';
import { ReactComponent as InboxIcon } from '@svg/inbox-icon-old.svg';
import clsx from 'clsx';
import { isEqual } from 'lodash';
import queryString from 'query-string';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

interface Props {
  sidebarItem: SidebarType;
  currentType?: { docTypeId: string; subTypeId?: string };
  activeType: { docTypeId: string; subTypeId?: string };
}

let hasScrolled = false;

const InboxSidebarItem: React.FC<Props> = ({ sidebarItem, currentType, activeType }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { inboxId }: UrlParams = useParams();

  const typeMap = useSelector((state) => state.inbox.typeMap);

  const activeItemRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const prevType = usePrevious(activeType);
  const { t } = useTranslation();
  const name = useMemo(() => {
    return typeMap.normal.get(sidebarItem.id);
  }, [sidebarItem.id, typeMap.normal]);

  useEffect(() => {
    if (sidebarItem?.subTypes?.length > 0 && !isEqual(activeType, prevType)) {
      sidebarItem.subTypes.forEach((item) => {
        if (activeType.subTypeId === item.id && activeType.docTypeId === currentType.docTypeId) {
          setIsOpen(true);
        }
      });
    }
  }, [activeType, currentType, prevType, sidebarItem]);

  useEffect(() => {
    if (activeItemRef.current && !hasScrolled) {
      hasScrolled = true;
      const el = activeItemRef.current as HTMLDivElement;
      if (activeType.subTypeId) {
        el.scrollIntoView({ behavior: 'smooth', block: 'start' });
      } else {
        el.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  }, [activeType, activeItemRef]);

  const handleNav = (docType, subType) => {
    const basePath = `/inbox/${inboxId}`;
    const params = queryString.stringify(
      docType && {
        docType: docType,
        subType: subType,
      }
    );
    if (location.pathname.includes('historical')) {
      navigate(basePath + '/historical' + '?' + params);
    } else {
      navigate(basePath + '?' + params);
    }
  };

  if (!name && sidebarItem.id !== '') {
    return <div />;
  }
  return (
    <>
      <div
        data-testid={'sidebar-item'}
        ref={activeType.docTypeId === currentType.docTypeId ? activeItemRef : null}
        className={clsx(s.item_wrapper, {
          [s.item_wrapper__active]:
            activeType.docTypeId === currentType.docTypeId && !location.pathname.includes('dashboard'),
        })}
      >
        <div
          className={clsx(s.item, {
            [s.item__main_active]:
              activeType.docTypeId === currentType.docTypeId && !location.pathname.includes('dashboard'),
            [s.item__active]:
              activeType.subTypeId === currentType.subTypeId &&
              activeType.docTypeId === currentType.docTypeId &&
              !location.pathname.includes('dashboard'),
          })}
          onClick={() => {
            handleNav(currentType.docTypeId, currentType.subTypeId);
          }}
          tabIndex={0}
        >
          <InboxIcon className={s.item_icon} />
          <div className={s.item_text} data-testid="item-text">
            {sidebarItem.id !== '' ? name : t('home:allDocuments')}
          </div>
          <div className={s.item_label} data-testid="item-count">
            {sidebarItem.count}
          </div>
          <div className={s.item_actions}>
            {sidebarItem.subTypes && sidebarItem.subTypes.length > 0 && (
              <div
                data-testid={'sidebar-item-dropdown'}
                className={clsx(s.item_dropdown, { [s.item_dropdown__active]: isOpen })}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(!isOpen);
                }}
              >
                <ChevronDownIcon />
              </div>
            )}
          </div>
        </div>
        {sidebarItem.subTypes && sidebarItem.subTypes.length > 0 && (
          <div className={s.item_child_wrapper}>
            {[...sidebarItem.subTypes].map((subType) => {
              return (
                <CSSTransition
                  unmountOnExit
                  mountOnEnter
                  in={isOpen}
                  timeout={300}
                  classNames={'inbox-sidebar-anim'}
                  key={subType.name + subType.id}
                >
                  <div
                    data-testid="sidebar-item-child"
                    tabIndex={0}
                    className={clsx(s.item_wrapper, s.item_child, {
                      [s.item__active]:
                        activeType.docTypeId === currentType.docTypeId && activeType.subTypeId === subType.id,
                    })}
                    onClick={() => {
                      handleNav(sidebarItem.id, subType.id);
                    }}
                  >
                    <div key={subType.name} className={s.item}>
                      <div className={s.item_icon} />
                      <div className={s.item_text}>
                        {typeMap.normal.get(`${currentType.docTypeId}-${subType.id}`)}
                      </div>
                      <div className={s.item_label}>{subType.count}</div>
                    </div>
                  </div>
                </CSSTransition>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default InboxSidebarItem;
