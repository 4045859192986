import { DotPulse } from '@uiball/loaders';
import React, { useEffect, useState } from 'react';
import useConsole from '@shared/hooks/useConsole.tsx';
import { useModal } from '@shared/hooks/useModal.tsx';
import { DocumentDetails } from '@shared/models/document.ts';
import { useSelector } from '@shared/store/store.ts';
import { ReactComponent as CheckmarkIcon } from '@svg/checkmark-icon.svg';

import s from './download-modal.module.scss';
import Modal from '../modal/Modal.tsx';

interface Props {
  activeDocument: DocumentDetails;
  handleDownload: (documentId: string) => void;
}

const DownloadModal: React.FC<Props> = ({ activeDocument, handleDownload }) => {
  const activeDoc = useSelector((state) => state.document.activeDocument);
  useConsole(activeDoc, 'activeDocModal');
  useConsole(activeDocument, 'activeDocument');
  const { closeModal } = useModal();

  const [hasDownloaded, setHasDownloaded] = useState(false);
  useEffect(() => {
    if (!hasDownloaded && activeDoc?.topology.contentReady) {
      handleDownload(activeDoc?.id);
      setHasDownloaded(true);
      closeModal();
    }
  }, [activeDoc?.id, activeDoc?.topology.contentReady, handleDownload, hasDownloaded]);
  return (
    <Modal dismissible={false}>
      <div className={s.modal} style={{ maxWidth: '500px' }}>
        <div className={s.header}>
          <span>Downloading Document</span>
        </div>
        <div className={s.content}>
          <div className={s.description}>
            <span>
              The document download you have requested is currently being generated, depending on the size
              and/or complexity this could take up to 2 minutes.
            </span>
          </div>
          <div className={s.center}>
            {activeDoc?.topology.contentReady && (
              <CheckmarkIcon style={{ color: '#0085FF', width: '20', height: '20' }} />
            )}
            {!activeDoc?.topology.contentReady && <DotPulse size={25} color={'#0085FF'} />}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DownloadModal;
