import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { ReactComponent as ENFlag } from '../../assets/svg/flags/en.svg';
import { ReactComponent as NLFlag } from '../../assets/svg/flags/nl.svg';
import { ReactComponent as FRFlag } from '../../assets/svg/flags/fr.svg';
import * as enTranslations from './locales/en';
import * as nlTranslations from './locales/nl';
import * as frTranslations from './locales/fr';

export const resources = {
  en: { ...enTranslations },
  nl: { ...nlTranslations },
  fr: { ...frTranslations },
};
export const lngs = {
  en: { nativeName: 'English', flag: <ENFlag />, shortName: 'EN' },
  nl: { nativeName: 'Nederlands', flag: <NLFlag />, shortName: 'NL' },
  fr: { nativeName: 'Francais', flag: <FRFlag />, shortName: 'FR' },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: resources,
    ns: ['admin', 'login', 'document', 'home'],
    defaultNS: 'admin',
  });

export default i18n;
