import { PaginatedToken, Token } from '@shared/models/document';
import { ToolType } from '@shared/models/labeler';
import clsx from 'clsx';
import React, { CSSProperties, useEffect, useMemo, useRef } from 'react';
import s from './labeler-view.module.scss';

interface TokenProps {
  pageScale: number;
  isSelecting: boolean;
  pointerPosition: { x: number; y: number };
  activeSearchToken: PaginatedToken;
  token: Token;
  handleTokenClick: VoidFunction;
  handleTokenMouseDown: React.PointerEventHandler<HTMLDivElement>;
  overlappingTokens?: Token[];
  activeTool: ToolType;
  searchQuery?: string;
}

export const LabelerToken: React.FC<TokenProps> = ({
  activeSearchToken,
  token,
  pageScale,
  pointerPosition,
  isSelecting,
  overlappingTokens,
  handleTokenMouseDown,
  activeTool,
  searchQuery,
}) => {
  const ref = useRef();
  const isSearched = useMemo(() => {
    if (searchQuery) return token.text.toLowerCase().includes(searchQuery.toLowerCase());
  }, [searchQuery, token.text]);

  const isMatched = useMemo(() => {
    return `${token.x}r${token.y}l${token.width}t${token.height}b${token.text}` === activeSearchToken?.id;
  }, [activeSearchToken, token]);

  useEffect(() => {
    if (isMatched) {
      if (ref.current) {
        const element = ref.current as HTMLDivElement;
        element.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: 'smooth',
        });
      }
    }
  }, [isMatched]);

  const isDisabled = useMemo(() => {
    if (activeTool === 'rectangle') return true;
  }, [activeTool]);

  const isWithinSelection = useMemo(() => {
    if (overlappingTokens.length > 0 && !isDisabled) {
      if (overlappingTokens.length > 100) return false;
      const overlappingToken = overlappingTokens.find((t) => {
        const mapped = `${t.x}r${t.y}l${t.width}t${t.height}b${t.text}`;
        const currentMapped = `${token.x}r${token.y}l${token.width}t${token.height}b${token.text}`;
        return mapped === currentMapped;
      });
      return !!overlappingToken;
    }
    return false;
  }, [isDisabled, overlappingTokens, token]);

  const isUnderCursor = useMemo(() => {
    return (
      pointerPosition.x > token.x * pageScale &&
      pointerPosition.x < (token.x + token.width) * pageScale &&
      pointerPosition.y > token.y * pageScale &&
      pointerPosition.y < (token.y + token.height) * pageScale
    );
  }, [pageScale, pointerPosition, token]);

  const style = useMemo(() => {
    return {
      top: token.uriRef ? (token?.y - 3) * pageScale : token?.y * pageScale,
      height: token.uriRef ? (token?.height + 6) * pageScale : token?.height * pageScale,
      left: token.uriRef ? (token?.x - 3) * pageScale : token?.x * pageScale,
      width: token.uriRef ? (token?.width + 6) * pageScale : token?.width * pageScale,
      pointerEvents: isSelecting ? 'none' : 'all',
    } as CSSProperties;
  }, [isSelecting, pageScale, token]);

  if (isDisabled) return null;
  if (isWithinSelection) {
    return <div style={style} className={clsx(s.token, s.token__contained)} />;
  }
  if (token.uriRef)
    return <div onPointerDown={handleTokenMouseDown} className={clsx(s.token, s.token__uri)} style={style} />;

  if (isUnderCursor || isSearched || isMatched)
    return (
      <div
        ref={isMatched ? ref : null}
        onPointerDown={handleTokenMouseDown}
        className={clsx(
          s.token,
          s.token__default,
          { [s.token__searched]: isSearched },
          { [s.token__matched]: isMatched }
        )}
        style={style}
      />
    );

  return null;
};
