import { retryLoad } from '@shared/helpers/helpers';
import { firebaseApp } from '@shared/store/setup/firebase-setup';
import { RootState, useDispatch } from '@shared/store/store';
import { unsubAll } from '@shared/store/subsSlice';
import s from '@shared/styles/component/header/header-profile.module.scss';
import avatar from '@svg/avatar.svg';
import clsx from 'clsx';
import { getAuth } from 'firebase/auth';
import React, { lazy, Suspense, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const HeaderProfileDropdown = lazy(() => retryLoad(() => import('./HeaderProfileDropdown')));

interface Props {
  isLight?: boolean;
  dropdownPosition?: 'top' | 'bottom';
  hasName?: boolean;
}

const HeaderProfile: React.FC<Props & React.HTMLProps<HTMLDivElement>> = ({
  className,
  isLight = false,
  hasName = true,
  dropdownPosition = 'bottom',
}) => {
  const user = useSelector((state: RootState) => state.user.userAccount);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useMemo(() => getAuth(firebaseApp), []);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSignOut = () => {
    dispatch(unsubAll());
    navigate('/login', { state: 'SIGNOUT' });
  };

  if (!user) return <div data-testid="loading-div" />;

  return (
    <div className={clsx(s.container, className)}>
      <img
        data-hj-suppress
        width={32}
        height={32}
        className={s.avatar}
        src={(auth.currentUser ? auth.currentUser.photoURL : avatar) || avatar}
        alt="avatar"
        onError={(e) => {
          const img = e.target as HTMLImageElement;
          img.src = avatar;
        }}
        onClick={() => {
          setIsDropdownOpen(!isDropdownOpen);
        }}
      />
      <Suspense fallback={<div />}>
        <HeaderProfileDropdown
          position={dropdownPosition}
          isLight={isLight}
          user={user}
          hasName={hasName}
          isOpen={isDropdownOpen}
          setIsOpen={setIsDropdownOpen}
          handleSignOut={handleSignOut}
          firebaseUser={auth.currentUser}
        />
      </Suspense>
    </div>
  );
};

export default HeaderProfile;
