import StyledSelect, {DropdownOption} from '@components/shared/dropdown/StyledSelect.tsx';
import sr from '@shared/styles/component/admin/admin-item-row.module.scss';
import clsx from 'clsx';
import React, {useEffect} from 'react';

interface Props {
  metadataTypes: DropdownOption[];
  data: { id?: string; type: string };
  onChange: (data: any) => void;
}

const AdminBrioMetadataRow: React.FC<Props> = ({ metadataTypes, data, onChange }) => {
  useEffect(() => {
    if (!data.id && metadataTypes) {
      onChange(metadataTypes[0].value);
    }
  }, [data, metadataTypes]);

  return (
    <div className={clsx(sr.wrapper, sr.wrapper__no_anim)}>
      <div data-testid={'sortable-field'} className={clsx(sr.container, sr.container__no_hover)}>
        <span className={sr.title} style={{ textTransform: 'capitalize' }}>
          {data?.type}
        </span>
      </div>

      <div className={sr.actions}>
        <div style={{ minWidth: 200 }}>
          {metadataTypes && (
            <StyledSelect
              required
              // defaultValue={metadataTypes[0]}
              onChange={(val: any) => {
                if (val.value === '@NONE') onChange(null);
                else onChange(val.value);
              }}
              value={
                metadataTypes ? metadataTypes.find((o) => o.value === data?.id) ?? metadataTypes[0] : null
              }
              options={metadataTypes ?? []}
              style={{ minHeight: 43, border: '1px solid #EEEEEE', marginTop: 3 }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminBrioMetadataRow;
