import Tooltip from '@components/shared/tooltip/Tooltip.tsx';
import {ReactComponent as ImportIcon} from '@shared/assets/svg/import.svg';
import {DocumentMetadata} from '@shared/models/document';
import s from '@shared/styles/component/document/document-labeler-sidebar.module.scss';
import clsx from 'clsx';
import React from 'react';

interface Props {
  metadata: DocumentMetadata;
}

const DocumentLabelerMetadataRow: React.FC<Props> = ({ metadata }) => {
  return (
    <div className={s.item} data-testid={'sidebar-metadata'}>
      <div className={s.title}>
        {metadata.type.name}{' '}
        {metadata.value.source === 'masterdata' && <ImportIcon className={clsx(s.item_imported)} />}
      </div>
      {metadata.value.value.length > 20 ? (
        <Tooltip tooltipStyle={{ marginLeft: 20, maxWidth: 400 }} content={metadata.value.value}>
          <div className={s.field}>{metadata.value.value}</div>
        </Tooltip>
      ) : (
        <div className={s.field}>
          <div className={s.field}>{metadata.value.value}</div>
        </div>
      )}

      <div className={s.icon__right} />
    </div>
  );
};

export default DocumentLabelerMetadataRow;
