import {listWithIdToRecord} from '@shared/helpers/helpers.ts';
import camelcaseKeys from 'camelcase-keys';
import {cloneDeep} from 'lodash';
import snakecaseKeys from 'snakecase-keys';

interface IRawDocType {
  id?: string;
  provider_id: string;
  age_threshold: number;
  approval_checks: string[];
  approval_threshold: number;
  auto_approve: boolean;
  entity_types: IRawDocTypeField[];
  metadata_keys: string[] | { id: string; value: string | number }[];
  is_archived?: boolean;
  is_fixed?: boolean;
  name: string;
  ocr_threshold: number;
  categories: IRawDocTypeCategory[];
  subtypes?: Record<string, IRawDocTypeSubType>;
  topology_type?: string;
  prompt_config?: {
    description?: string;
  };
}

export interface IRawDocTypeField {
  id: string;
  min_occurrences?: number;
  max_occurrences?: number;
  approval_threshold?: number;
}
export interface IRawDocTypeCategory {
  name: string;
  id: string;
  entity_types: string[];
}

export interface IRawDocTypeSubType {
  id?: string;
  name: string;
  provider_id?: string;
  is_archived?: boolean;
  prompt_config?: {
    description?: string;
  };
}

export interface IClientDocType {
  id?: string;
  entityTypes: IClientDocTypeField[];
  metadataKeys?: { id: string; value: string }[];
  isFixed?: boolean;
  isPrivate?: boolean;
  isArchived?: boolean;
  ageThreshold?: number;
  approvalChecks?: Record<string, boolean>;
  approvalThreshold?: number;
  ocrThreshold?: number;
  autoApprove?: boolean;
  name: string;
  providerId?: string;
  subtypes?: IClientDocTypeSubType[];
  categories?: IClientDocTypeCategory[];
  topologyType?: string;
  promptConfig?: {
    description?: string;
  };
}

export interface IClientDocTypeField {
  id: string;
  minOccurrences?: number;
  maxOccurrences?: number;
  approvalThreshold?: number;
}
export interface IClientDocTypeCategory {
  name: string;
  id: string;
  entityTypes: string[];
}

export interface IClientDocTypeSubType {
  id: string;
  name: string;
  providerId?: string;
  isArchived?: boolean;
  promptConfig?: {
    description?: string;
  };
}

export const docTypeRawToClient = (rawDocType: IRawDocType): IClientDocType => {
  const approvalChecks = { auto_approve: true, classification_confidence: true };
  rawDocType?.approval_checks?.forEach((check) => {
    approvalChecks[check] = true;
  });

  const obj: IClientDocType = {
    name: rawDocType?.name ?? '',
    id: rawDocType?.id ?? '',
    providerId: rawDocType?.provider_id ?? '',
    ageThreshold: rawDocType?.age_threshold ? rawDocType?.age_threshold : 7,
    approvalThreshold: rawDocType?.approval_threshold !== null ? rawDocType?.approval_threshold * 100 : 50,
    approvalChecks: approvalChecks,
    autoApprove: rawDocType?.auto_approve ?? false,
    ocrThreshold: rawDocType?.ocr_threshold !== null ? rawDocType.ocr_threshold * 100 : 80,
    entityTypes: camelcaseKeys(rawDocType?.entity_types) ?? [],
    metadataKeys: [],
    categories: camelcaseKeys(rawDocType.categories) ?? [],
    topologyType: rawDocType?.topology_type ?? 'bundle',
    subtypes: rawDocType.subtypes
      ? Object.entries(rawDocType.subtypes)
          .map(([k, v]) => {
            return subTypeRawToClient({ id: k, ...v });
          })
          .filter((st) => !st.isArchived)
      : [],
    isPrivate: rawDocType?.id.includes('@PB_DELETE') ?? false,
    isArchived: rawDocType?.is_archived ?? false,
    isFixed: rawDocType?.is_fixed ?? false,
    promptConfig: {
      description: rawDocType?.prompt_config?.description ?? '',
    },
  };
  //Check if metadata_keys is legacy string array or not
  if (rawDocType.metadata_keys) {
    if (typeof rawDocType.metadata_keys[0] !== 'object') {
      obj.metadataKeys = rawDocType.metadata_keys.map((mk) => ({ id: mk, value: null }));
    } else {
      obj.metadataKeys = rawDocType.metadata_keys as { id: string; value: string }[];
    }
  }
  //Sort items which have a value first, and then alphabetically
  obj.metadataKeys.sort((a, b) => {
    // Check if 'options' field exists
    const aHasOptions = !!a.value;
    const bHasOptions = !!b.value;

    // Sort first by presence of 'options' field
    if (aHasOptions && !bHasOptions) {
      return -1; // 'a' comes first
    } else if (!aHasOptions && bHasOptions) {
      return 1; // 'b' comes first
    } else {
      // If both have 'options' or both don't have it, sort alphabetically by 'name'
      return a.id.localeCompare(b.id);
    }
  });
  return obj;
};

export const docTypeClientToRaw = (
  docType: Partial<IClientDocType>,
  isMultiEdit?: boolean
): Partial<IRawDocType> => {
  const rawData: Partial<IRawDocType> = {};

  if (docType.entityTypes) {
    const mappedEntityTypes = docType.entityTypes.map((ft) => ({
      ...ft,
      maxOccurrences: ft.maxOccurrences === 20 ? null : ft.maxOccurrences,
    }));
    rawData.entity_types = snakecaseKeys(mappedEntityTypes, { deep: true });
  }

  if (docType.categories) {
    rawData.categories = docType.categories
      .filter((category) => category.id !== '_temp_')
      .map((category) => ({
        id: category.id,
        name: category.name,
        entity_types: category.entityTypes,
      }));
  }

  rawData.approval_checks = Object.entries(docType.approvalChecks).reduce((acc, [k, v]) => {
    const arr = acc;
    if (v === true) arr.push(k);
    return arr;
  }, []);
  if (!isMultiEdit) {
    const approvalChecks = cloneDeep(docType.approvalChecks) ?? {};
    if (docType['entityTypes'] && docType['entityTypes'].length === 0) {
      approvalChecks['entity_occurrences'] = false;
      approvalChecks['ocr_confidence'] = false;
    } else if (docType.entityTypes && docType.entityTypes.length > 0) {
      approvalChecks['entity_occurrences'] = true;
      approvalChecks['ocr_confidence'] = true;
    }
    if (Object.entries(approvalChecks).length > 0) {
      rawData.approval_checks = Object.entries(approvalChecks).reduce((acc, [k, v]) => {
        const arr = acc;
        if (v === true) arr.push(k);
        return arr;
      }, []);
    }
  } else {
    const checks = Object.entries(docType.approvalChecks).reduce((acc, [k, v]) => {
      const arr = acc;
      if (v === true) arr.push(k);
      return arr;
    }, []);
    if (checks.length > 0) rawData.approval_checks = checks;
  }

  if (docType.topologyType) rawData.topology_type = docType.topologyType;
  rawData.provider_id = docType.providerId;
  if (docType.ageThreshold !== undefined) rawData.age_threshold = docType.ageThreshold;
  if (docType.approvalThreshold !== undefined) rawData.approval_threshold = docType.approvalThreshold / 100;
  if (docType.ocrThreshold !== undefined) rawData.ocr_threshold = docType.ocrThreshold / 100;
  if (docType.autoApprove !== undefined) rawData.auto_approve = docType.autoApprove;
  if (docType.metadataKeys !== undefined)
    rawData.metadata_keys = docType.metadataKeys.map((mk) => {
      const val = { id: mk.id, value: mk.value };
      if (val.value === null) delete val.value;
      // if (val.value === '' || val.value === null) delete val.value;
      return val;
    });
  if (docType.name !== undefined) rawData.name = docType.name;
  if (docType.isArchived !== undefined) rawData.is_archived = docType.isArchived;
  if (docType.subtypes) {
    const st = docType.subtypes.map((ft) => subTypeClientToRaw(ft, true));
    rawData.subtypes = listWithIdToRecord(st);
  }
  if (docType.isFixed !== undefined) rawData.is_fixed = docType.isFixed;
  if (docType.promptConfig)
    rawData.prompt_config = {
      description: docType.promptConfig.description,
    };

  return rawData;
};

export const subTypeClientToRaw = (
  subType: IClientDocTypeSubType,
  withId?: boolean
): Partial<IRawDocTypeSubType> => {
  const rawData: Partial<IRawDocTypeSubType> = {
    name: subType.name,
    provider_id: subType.providerId,
    is_archived: subType.isArchived,
  };
  if (subType.id && withId) rawData.id = subType.id;
  if (subType.promptConfig?.description) {
    rawData.prompt_config = {
      description: subType.promptConfig.description,
    };
  }
  return rawData;
};

export const subTypeRawToClient = (subType: IRawDocTypeSubType): IClientDocTypeSubType => {
  const rawData: IClientDocTypeSubType = {
    id: subType.id,
    name: subType.name,
    providerId: subType.provider_id,
    isArchived: subType.is_archived,
  };
  if (subType.prompt_config?.description) {
    rawData.promptConfig = {
      description: subType.prompt_config.description,
    };
  }
  return rawData;
};
