import SuspenseLoader from '@components/shared/suspense-loader/SuspenseLoader';
import { listenUsers } from '@shared/store/adminSlice';
import { auth } from '@shared/store/setup/firebase-setup';
import i18n from '@shared/store/setup/i18n';
import { useSelector } from '@shared/store/store';
import { getTenant } from '@shared/store/tenantSlice';
import { getInboxes, getUserAccount, getUserHash } from '@shared/store/userSlice';
import { useEffect } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, isAdmin = false }) => {
  const [user, loading] = useAuthState(auth);
  const userAccount = useSelector((state) => state.user.userAccount);
  const tenantId = useSelector((state) => state.tenant.tenantId);
  const tenantSettings = useSelector((state) => state.tenant.details.settings);
  const users = useSelector((state) => state.admin.users);

  const dispatch = useDispatch<any>();

  useEffect(() => {
    if (user) {
      dispatch(getUserAccount(user.uid));
      dispatch(getTenant());
      dispatch(getUserHash());
    }
  }, [dispatch, tenantId, user]);

  useEffect(() => {
    if (userAccount?.email && userAccount?.inboxes.length > 0) {
      // i18n.changeLanguage('cimode');
      dispatch(getInboxes(userAccount.inboxes));
      if (userAccount?.preferences?.language) {
        i18n.changeLanguage(userAccount.preferences.language);
      } else if (tenantSettings?.language) {
        i18n.changeLanguage(tenantSettings?.language);
      }
    }
  }, [dispatch, tenantSettings, userAccount]);

  useEffect(() => {
    if (userAccount && userAccount.email && users.length === 0) {
      dispatch(listenUsers());
    }
  }, [users, dispatch, userAccount]);

  if (loading || !tenantId || !userAccount) {
    return <SuspenseLoader name={'private-loader'} fullPage />;
  }

  if (user) {
    if (isAdmin && userAccount?.email) {
      if (userAccount.isAdmin === true) {
        return element;
      } else {
        return (
          <Navigate
            replace
            to={{
              pathname: '/inbox',
            }}
          />
        );
      }
    }

    return element;
  } else {
    return (
      <Navigate
        replace
        to={{
          pathname: '/login',
        }}
      />
    );
  }
};

export default PrivateRoute;
