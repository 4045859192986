import useOutsideClick from '@shared/hooks/useOutsideClick';
import {WebhookServerSideValue} from '@shared/models/admin';
import s from '@shared/styles/component/admin/admin-data-dropdown.module.scss';
import {ReactComponent as CrossIcon} from '@svg/cross-icon.svg';
import {ReactComponent as SelectorIcon} from '@svg/selector-icon.svg';
import clsx from 'clsx';
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
  item: {
    key: string;
    tempLocked: boolean;
    locked: boolean;
    value: { content: string; type: string };
  };
  options: WebhookServerSideValue[];
  onChangeType: (e: string) => void;
  onChange: (e: string) => void;
  disabled?: boolean;
}

const AdminDataDropdown: React.FC<Props> = ({ item, options, onChange, onChangeType, disabled }) => {
  const ref = useRef();
  const dropdownRef = useRef();
  const { t } = useTranslation();
  useOutsideClick(ref, () => setIsFocussed(false));

  const [isFocussed, setIsFocussed] = useState(false);
  const [scrollPos, setScrollPos] = useState(-1000);
  const [isLow, setIsLow] = useState(false);

  const positionStyle = useMemo(() => {
    if (!isFocussed || disabled) return { top: -2000 };

    if (ref.current) {
      const item = ref.current as HTMLDivElement;

      return {
        top: isLow
          ? item.getBoundingClientRect().top - 20 - 280
          : item.getBoundingClientRect().top + 30 ?? scrollPos,
        width: item.clientWidth,
      };
    }
  }, [disabled, isFocussed, isLow, scrollPos]);

  const mappedValue = useCallback(
    (value: string) => {
      if (!options) return '';
      const res = options.find((e) => e.id === value);
      return res?.name ?? value;
    },
    [options]
  );

  const listener = useCallback(
    (event) => {
      if (ref.current && isFocussed) {
        if (event.target !== dropdownRef.current) {
          const item = ref.current as HTMLDivElement;
          const wrapperRect = item.getBoundingClientRect();

          setIsLow(document.body.clientHeight < wrapperRect.top + 30 + 280);
          setScrollPos(item.getBoundingClientRect().top + 30);
        }
      }
    },
    [isFocussed]
  );

  const inputValue = useMemo(() => {
    if (item?.value.content) {
      if (item.value.type === '@PB_SECRET') {
        return 'Secret Value';
      } else {
        return item.value.content;
      }
    }
    return '';
  }, [item]);

  const descriptionValue = useCallback(
    (opt: WebhookServerSideValue) => {
      const val: any = t(`admin:webhookValues.${opt.id}`) ?? '---';
      return val as any;
    },
    [t]
  );
  useEffect(() => {
    document.addEventListener('scroll', listener, { capture: true });
    return () => {
      document.removeEventListener('scroll', listener, { capture: true });
    };
  }, [listener]);

  return (
    <div ref={ref} className={clsx(s.container)}>
      <div
        onClick={() => {
          if (!isFocussed) setIsFocussed(true);
        }}
        tabIndex={0}
        className={clsx(s.input_wrapper, { [s.input_wrapper__focussed]: isFocussed })}
      >
        {(item.value.type === 'string' || item.value.type === '@PB_SECRET') && (
          <input
            data-testid={'admin-data-input'}
            onClick={() => setIsFocussed(true)}
            placeholder={t(`admin:webhookValues.placeholder`)}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
            className={s.input}
            onFocus={() => (!disabled ? setIsFocussed(true) : null)}
            value={inputValue}
            autoComplete="new-password"
            type={item?.value?.type === '@PB_SECRET' ? 'password' : 'text'}
          />
        )}

        {item.value.type.includes('@') &&
          item.value.type !== '@PB_SECRET' &&
          (options?.length > 0 ? (
            <div
              data-testid={'admin-data-special'}
              className={s.special}
              onClick={() => onChangeType('string')}
            >
              {mappedValue(item.value.type)} <CrossIcon />
            </div>
          ) : (
            <div className={s.special}>...</div>
          ))}
        <SelectorIcon className={s.icon} />
        <div
          ref={dropdownRef}
          style={positionStyle}
          className={clsx(s.bottom, { [s.bottom__active]: isFocussed })}
        >
          {options?.map((opt) => {
            return (
              <div
                data-testid={'admin-data-dropdown-item'}
                onClick={() => {
                  onChange('');
                  onChangeType(opt.id);
                  setIsFocussed(false);
                }}
                key={opt.id}
                className={s.bottom_row}
              >
                <div className={s.bottom_row_name}> {opt.name}</div>
                <div className={s.bottom_row_description}>{descriptionValue(opt)}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AdminDataDropdown;
