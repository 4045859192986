import {sleep} from '@shared/helpers/helpers.ts';
import {useSelector} from '@shared/store/store.ts';
import s from '@shared/styles/component/admin/admin.module.scss';
import clsx from 'clsx';
import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {NavLink, Outlet, useNavigate, useParams} from 'react-router-dom';

const AdminWebhooks: React.FC = () => {
  const webhooks = useSelector((state) => state.admin.webhooks);
  const webhookRef = useRef();
  const { webhookId } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    if (webhooks?.length > 0 && webhookId !== 'new') {
      if (webhookRef.current) {
        const activeConnectorItem = webhookRef.current as HTMLDivElement;
        sleep(1).then(() => {
          activeConnectorItem.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'center',
          });
        });
      }
    }
  }, [webhooks, webhookId]);

  useEffect(() => {
    if (!webhookId && webhooks) {
      if (webhooks.length > 0) {
        navigate(`${webhooks[0].id}`);
      } else {
        navigate(`new`);
      }
    }
  }, [webhooks, webhookId]);

  return (
    <div className={s.subcontent}>
      <div className={s.subsidebar}>
        <div className={s.header}>Webhooks</div>
        <NavLink to={'new'} className={s.add}>
          + {t('admin:webhooks.add')}
        </NavLink>
        <nav className={clsx(s.section)}>
          {!webhooks ||
            (webhooks.length === 0 && (
              <Skeleton
                count={5}
                height={20}
                width={'calc(100% - 32px '}
                style={{ marginBottom: 28, marginLeft: 16 }}
              />
            ))}
          {webhooks?.map((connector) => (
            <NavLink
              ref={webhookId === connector.id ? webhookRef : null}
              key={connector.id}
              to={`${connector.id}`}
              className={({ isActive }) => clsx(s.item, { [s.active]: isActive })}
            >
              {connector.name}
            </NavLink>
          ))}
        </nav>
      </div>
      <Outlet />
    </div>
  );
};

export default AdminWebhooks;
